import React from "react";

// Customizable Area Start
import {
    Typography,
    Box,
    Card,
    Button,
    CardContent,
    CardMedia,
    Chip,
    TextField,
    Backdrop, CircularProgress,
    Theme,
    Link,
    FormControl,
    Select,
    MenuItem,
    Grid

} from "@material-ui/core";
import { createStyles, withStyles } from "@material-ui/core/styles";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Slider from "react-slick";
import ReactPlayer from "react-player";
// Customizable Area End

// Customizable Area Start
import PremiumListingController, {
    Props,
} from "./PremiumListingController";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { pdflogo, xlxslogo, FolderIcon, downArrow, searchIcon, logoImage } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import  AppBarPage  from "../../../components/src/AppBar.web";
import Contacts from "../../../components/src/Contacts.web";
import ListingHeader from "../../../components/src/ListingHeader.web";
import Reviews from "../../../components/src/Reviews.web";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { CustomErrorToast } from "../../../components/src/CustomErrorToast.web";

export interface GoodCompany{
    id:number,
    icon:string,
    name:string
}

// Customizable Area End
export class PremiumListing extends PremiumListingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderScreenshots = (classes: any, items: any) => {
        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };
        return (<div>
            <Box>
                <Typography className={classes.accordionHeading}>Screenshots</Typography>

            </Box>
            <Box>
                <Slider className={classes.slickSlider} {...settings} >
                    {items && items.map((item: any) =>
                    (
                        <img alt="sliderImg" key={item.id} className={classes.sliderImg} src={item.url}/>
                    )
                    )}


                </Slider>
            </Box>
        </div>)
    }

    renderChipData = (classes: any, chipData: any) => {
        if (Array.isArray(chipData)) {
            return (<Box className={classes.chipContainer}>
                {chipData && chipData.map((item: any) => (
                    <Box>
                        <Chip className={classes.chipStyle} label={item} />

                    </Box>
                )

                )}

            </Box>)
        }

    }
    renderCategories = (classes: any, data: any) => {
        return <div>
            {data && data.map((category: any, i: number) => (
                <Box className={classes.width550} key={i}>
                    <Typography className={`${classes.heading14} ${classes.marginTop20}`}>{category.category_name}</Typography>

                    <Box className={classes.chipContainer}>
                        {category.sub_categories && category.sub_categories.map((subcategory: any) =>
                        (<Box key={subcategory.name}>
                            <Chip className={classes.chipStyle} label={subcategory.name} />

                        </Box>)

                        )}
                    </Box>

                </Box>
            ))}
        </div>
    }
    getResturantTypes = (classes: any, data: any) => {
        return <div>
            {data && data.map((restaurantTypes: any, i: number) => {
                const { name, restauran_sub_type } = restaurantTypes.attributes
                return (
                    <Box key={i}>
                        <Typography className={`${classes.heading14} ${classes.marginTop20}`}>{name}</Typography>

                        <Box className={classes.chipContainer}>
                            {restauran_sub_type && restauran_sub_type.map((singleType: any) => {
                                const { name,active } = singleType
                                return (<Box key={name}>
                                    {active && <Chip className={classes.chipStyle} label={name} />}
                                   

                                </Box>)
                            }


                            )}
                        </Box>

                    </Box>
                )
            })}
        </div>
    }
    formatEventsDescription=(text:string)=>{
        if(text?.length>=100){
            return text.slice(0,110)+"...."
        }
        return text;
    }
    renderEvents = (classes: any, events: any) => {
        return <div  className={`${classes.cardsContainer}`}>
            {events && events.map((item: any) => {
                const {logo,name,short_description,location_name}=item.attributes
                return (
                    <Link color="inherit" href={`/premiumlistingevents/${item.id}`} underline="none">
                    
                    <Card key={item.id} className={classes.cardItem}>
                        <Box className={classes.cardWrapper}>
                            <CardMedia
                                component="img"
                                alt="Contemplative Reptile"
                                height="180"
                                width="1"
                                image={logo}
                                title="Restaurant"
                                className={classes.eventCardImg}

                            />
                            <Box>
                                <Box>
                                    <Typography className={`${classes.cardTitle500} ${classes.marginTop20} ${classes.eventElipsis}`}>
                                        {name}
                                    </Typography>
                                    <Box style={{ display: 'flex', justifyContent: "center" }}>
                                        <img src={FolderIcon} className={classes.mr5} />
                                        <Typography className={`${classes.lightgray} ${classes.heading400}`}>
                                            General Supplies
                                        </Typography>
                                    </Box>
                                    <Typography className={classes.heading400} >
                                        {location_name}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>

                        <CardContent style={{ padding: 5 }}>
                            <Typography className={classes.cardContent300}>{this.formatEventsDescription(short_description)}</Typography>
                        </CardContent>
                    </Card>
                    </Link>
                )
            })}
        </div>
    }

    renderLanguages=(languages:any)=>{
        if(Array.isArray(languages)){
            let language=""+languages.join(",")
            return language;
        }
       
        return "";
    }

    renderVerified=(verified:boolean,classes:any)=>{
        if(verified)return <div className={classes.verifiedBage}><span>Verified</span> 
                                                        </div>
    }
    renderIntegrationLinkbtn=(integrationslink:boolean,classes:any)=>{
        if(integrationslink) return <Button variant="contained"
        component="a"
        target="_blank"
        href={this.state.integrationlink}
        className={classes.cardBtnStyle}
        style={{
            backgroundColor: '#B300ED',
            color: '#FFF',
            height: 36,
            width: "auto",
            borderRadius: "9px",
        }}>
        Go to Integrations Page
    </Button>
    }
    getGoodCompanies=(goodComapny:GoodCompany[])=>{
        return goodComapny && goodComapny.map((item:GoodCompany)=>item.name)
    }





    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        const { tabValue, isLoading,
            files,
            logo,
            name,
            description,
            ratingsData,
            contactData,
            target_user,
            feature_platform,
            feature_ownership,
            features_screenshots,
            service_screenshots,

        }: any = this.state

        const integrationLength=this.state.integrations_count
        const tabsArray = ["Features", "About", "Support", "Integrations", "Media", "Events", "Reviews", "Contact"]
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <>
                <Backdrop className={classes.backdrop} open={isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <AppBarPage
                    logoImg={logoImage}
                    downarrow={downArrow}
                    handleClick={this.goToLoginPage}
                    serachIcon={searchIcon}
                    username={this.state.userdetails}
                    dummyicon={""}
                    goToHomePage={()=>this.handleNavigation("Home")}
                    handleHeaderNavigation={()=>this.navigateToAccount(this.state.userdetails?.user_type)}
                    handleUserSignout={this.handleUserSignout}
                    data-testId={"signInBtn"}
                    searchText={this.state.searchTermProp}
                    showDirectoryPopup={this.state.showDirectoryPopup}
                    searchDirectoryData={this.state.searchDirectoryData}
                    handleAdvancedSearch={this.handleAdvancedSearch}
                    handleSubmit={this.handleSubmit}
                    handleSearchInput={this.handleSearchInput}
                    handleDirectoryPopupClose={this.handleDirectoryPopupClose}
                   
                />
                <Box className={classes.root}>

                    <Box className={`${classes.container} ${classes.customScrollbar}`}>
                        <Box className={classes.headerContainer}>
                            <ListingHeader toggleText={this.toggleText} goodCompanies={this.state.aboutData.good_companies} isExpanded={this.state.isExpanded}  userdetails={this.state.userdetails} handleBookmarkDelete={this.handleBookmarkDelete} domain_url={this.state.domain_url}   bookmarked={this.state.bookmarked} markets={this.state.markets} contactData={contactData} data-testid="bookmark" handleBookmarkCall={this.handleBookmarkCall} description={description} showClaimed={false} showComapnyRating={true} showContact={false} showFeatured={this.state.featured} name={name} logo={logo} />


                            <Box className={classes.navBar}>
                                <Tabs
                                    value={tabValue}
                                    onChange={this.handleChange}
                                    centered
                                    classes={{ centered: classes.tabContainer }}
                                    TabIndicatorProps={{ style: { height: '3px', backgroundColor: "#B300ED" } }}
                                >
                                    {tabsArray.map((item: any, index: any) => (
                                        <Tab key={index} value={index} data-testid={item} label={item} style={{ color: this.themeColorDetect(index) }} />

                                    ))}

                                </Tabs>
                            </Box>
                        </Box>
                        <Box id={`0-tab`} className={classes.accordionContainer}>
                            <Accordion data-testid="featuresOpen" style={{borderRadius:"8px"}} onChange={() => this.handleAccordion('featureOpen')} expanded={this.state.featureOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Features</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{display:"block"}}>
                                <Box style={{width:"100%"}} className={classes.boxLayout}>
                                                <Typography className={classes.heading16}>Categories</Typography>
                                                <Box>
                                                    {this.renderCategories(classes, this.state.categories?.slice(0, 4))}

                                                    {this.state.moreCategories ? this.renderCategories(classes, this.state.categories?.slice(4)) : ""}
                                                </Box>
                                                <Box className={`${classes.marginTop30}`}>

                                                 {this.state.categories?.length>4 &&   <Button data-testid="seemore" onClick={()=>this.setState({moreCategories:!this.state.moreCategories})} variant="text" className={classes.categoryBtn}>
                                                        {this.state.moreCategories?<Typography variant="body1">See less</Typography>:<Typography variant="body1">See more</Typography>}
                                                    </Button>}  
                                                </Box>

                                            </Box>
                                    <Box className={classes.detailsContainer}>
                                        <Box className={`${classes.leftSection}`}>
                                            <Box className={`${classes.boxLayout} ${classes.marginTop10}`}>
                                                <Grid container spacing={2}>
                                                    <Grid item md={5} sm={6} xs={6}>
                                                        <Typography className={`${classes.accordionHeading}`}>Target Users</Typography>
                                                        <Box>
                                                            {target_user && target_user.map((target: any, id: number) =>
                                                            (<div>
                                                                <Typography className={`${classes.marginTop10} ${classes.neutralGray} ${classes.heading400}`}>{target.target_user_type}</Typography>
                                                            </div>)
                                                            )}
                                                        </Box>

                                                    </Grid>
                                                    <Grid item md={5} sm={6} xs={6}>
                                                        <Typography className={`${classes.accordionHeading}`}>Platform</Typography>
                                                        <Box>

                                                            {typeof feature_platform != undefined ? feature_platform.map((list: any, id: number) =>
                                                                <Typography className={`${classes.marginTop10}`} style={{ fontSize: "14px", fontWeight: 400, fontFamily: "Poppins" }}>{list.platform_type}</Typography>
                                                            ) : ""}
                                                        </Box>

                                                    </Grid>
                                                </Grid>
                                                 </Box>
                                             <Box className={classes.boxLayout}>
                                                <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Files</Typography>
                                                <Box>
                                                    {files?.map((item: any, id: number) => (
                                                        <Box key={`file-${id}`} className={`${classes.fileContainer} ${classes.marginTop10}`}>
                                                            <Box className={classes.fileItem}>
                                                                <img src={this.getFileType(item.url) == "pdf" ? pdflogo : xlxslogo} alt="filelogo" />
                                                                <Typography className={`${classes.ml10} ${classes.neutralGray} ${classes.heading400}`}>{getFileName(item.url)}</Typography>

                                                            </Box>
                                                            <a download href={item.url} className={`${classes.marginTop10} ${classes.downloadtxt}`}>Download</a>

                                                        </Box>
                                                    ))}
                                                </Box>
                                             </Box>
                                              </Box>
                                        <Box className={classes.rightSection}>
                                             <Box className={`${classes.boxLayout} ${classes.marginTop10}`}>
                                                <Typography className={classes.accordionHeading}>Restaurant Type</Typography>
                                                {this.getResturantTypes(classes, this.state.restaurantType)}

                                            </Box>

                                            <Box className={classes.boxLayout}>
                                                <Box>
                                                    <Typography className={classes.accordionHeading}>Data Ownership</Typography>
                                                    {feature_ownership?.map((list: any) => {
                                                        const { data_ownership_type } = list
                                                        return <Typography key={data_ownership_type} className={`${classes.marginTop10} ${classes.neutralGray} ${classes.heading400}`}>{data_ownership_type}</Typography>

                                                    }
                                                    )}

                                                </Box>
                                            </Box>

                                            <Box className={`${classes.boxLayout} ${classes.sliderContainer}`} style={{display:'flex',justifyContent:"center",alignItems:"center"}}>
                                                {this.renderScreenshots(classes, features_screenshots)}

                                            </Box>

                                        </Box>

                                    </Box>

                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Box id={`1-tab`} className={classes.accordionContainer}>
                            <Accordion style={{borderRadius:"8px"}} data-testid="aboutAccordion" onChange={() => this.handleAccordion('aboutOpen')} expanded={this.state.aboutOpen} >
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>About</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={classes.detailsContainer}>
                                        <Box className={`${classes.leftSection}`}>
                                            <Box className={classes.boxLayout}>
                                                <Box className={classes.aboutItems}>
                                                    <Box className={classes.aboutItem}>
                                                        <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Vendor</Typography>
                                                        <Typography className={`${classes.marginTop10} ${classes.heading400}`}>{this.state.aboutData.name}</Typography>
                                                    </Box>
                                                    <Box className={classes.aboutItem}>
                                                        <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Vendor URL</Typography>
                                                        <Typography className={`${classes.marginTop10} ${classes.heading400}`}>{this.state.aboutData.vendorURL}</Typography>
                                                    </Box>

                                                </Box>
                                                <Box className={`${classes.aboutItems}`}>
                                                    <Box className={classes.aboutItem}>
                                                        <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Vendor Country</Typography>
                                                        <Typography className={`${classes.marginTop10} ${classes.heading400}`}>{this.state.aboutData.country}</Typography>
                                                    </Box>
                                                    <Box className={classes.aboutItem}>
                                                        <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Vendor Founded</Typography>
                                                        <Typography className={`${classes.marginTop10} ${classes.heading400}`}>{this.state.aboutData.founded}</Typography>
                                                    </Box>

                                                </Box>
                                            </Box>


                                        </Box>
                                        <Box className={classes.rightSection} style={{maxHeight:"222px",height:"auto"}}>

                                            <Box className={classes.boxLayout}>

                                                <Box>
                                                    <Typography className={`${classes.heading16} ${classes.marginTop10}`}>Good Company</Typography>

                                                    {this.renderChipData(classes, this.getGoodCompanies(this.state.aboutData.good_companies))}


                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Box id={`2-tab`} className={classes.accordionContainer}>

                            <Accordion style={{borderRadius:"8px"}} data-testid="supportAccordion" onChange={() => this.handleAccordion('supportOpen')} expanded={this.state.supportOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Support</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={classes.detailsContainer}>
                                        <Box className={`${classes.leftSection}`}>
                                            <Box className={classes.boxLayout}>
                                                <Box className={classes.aboutItems}>
                                                    <Box className={classes.aboutItem}>
                                                        <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Support URL</Typography>
                                                        <Typography className={`${classes.marginTop10} ${classes.heading400}`}>{this.state.serviceData.supporturl}</Typography>
                                                    </Box>

                                                </Box>
                                                <Box className={`${classes.aboutItems}`}>
                                                    <Box className={classes.aboutItem}>
                                                        <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Training URL</Typography>
                                                        <Typography className={`${classes.marginTop10} ${classes.heading400}`}>{this.state.serviceData.trainingurl}</Typography>
                                                    </Box>
                                                    <Box className={classes.aboutItem}>
                                                        <Typography className={`${classes.accordionHeading} ${classes.marginTop10}`}>Languages</Typography>
                                                        <Typography className={`${classes.marginTop10} ${classes.heading400}`}>{this.renderLanguages(this.state.serviceData.language)}</Typography>
                                                    </Box>

                                                </Box>
                                            </Box>

                                            <Box className={classes.boxLayout}>
                                                <Box>
                                                    <Typography className={`${classes.heading16} ${classes.marginTop10}`}>Service</Typography>
                                                    {this.renderChipData(classes, this.state.serviceData.support)}
                                                </Box>
                                                <Box>
                                                    <Typography className={`${classes.heading16} ${classes.marginTop10}`}>Training</Typography>
                                                    {this.renderChipData(classes, this.state.serviceData.training)}


                                                </Box>
                                            </Box>
                                         </Box>
                                        <Box className={classes.rightSection}>

                                        <Box className={`${classes.boxLayout} ${classes.sliderContainer}`}>
                                                {this.renderScreenshots(classes, service_screenshots)}

                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Box id={`3-tab`} className={classes.accordionContainer}>

                            <Accordion  style={{borderRadius:"8px"}} data-testid="integration" onChange={() => this.handleAccordion('integrationOpen')} expanded={this.state.integrationOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Integrations ({integrationLength})</Typography>
                                </AccordionSummary>
                                <AccordionDetails className={classes.paddLeft56} style={{marginTop:'1rem'}}>
                                    <Box style={{ width: "100%" }} className={classes.integrationContainer}>

                                        <Box className={`${classes.inputContainer} `}>

                                            <Box style={{width:"100%",background:"none",borderRadius:"8px",height:"50px"}}>
                                                    <Box style={{borderRadius:"8px"}}>
                                                        <FormControl variant="outlined" className={classes.formcontrol}>
                                                            <Select
                                        labelId="demo-simple-select-outlined-label"
                                        id="demo-simple-select-outlined"
                                        fullWidth
                                        displayEmpty
                                        data-testid={"select"}
                                        value={this.state.categoryId}
                                        placeholder={"Select"}
                                        onChange={this.handleCategoryFilter}
                                        
                                    >
                                        <MenuItem key={"defaultValue"} value={-1}>Select</MenuItem>
                                        {this.state.categoryListData?.map((category: any) => 
                                            <MenuItem key={category.category_id} value={category.category_id}>
                                                {category.category_name}
                                            </MenuItem>
                                        )

                                        }
                                                            </Select>
                                                        </FormControl>
                                                    </Box>
                                               


                                            </Box>
                                            <Box style={{ width: "100%" }} className={` ${classes.ml10} `}>

                                                <TextField
                                                    variant="outlined"
                                                    placeholder={'Search'}
                                                    fullWidth
                                                    data-testid={"searchIntegration"}
                                                    name="name"
                                                    value={this.state.searchText}
                                                    InputProps={{
                                                        startAdornment: (
                                                            <InputAdornment position="start">
                                                                <SearchIcon style={{ color: "#94A3B8" }} />
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                    onChange={this.handleSearchText}
                                                    className={`${classes.textfieldName}`}
                                                    style={{ height: '50px' }} 
                                                />

                                            </Box>

                                        </Box>

                                        <Box className={`${classes.marginTop30} ${classes.cardsContainer}`}>
                                            {this.hanldefilteredData()?.map((item: any) => {
                                                const { name, logo,verified } = item.attributes
                                                return (
                                                    <Link color="inherit" href={this.getListingUrl(item.id, item.payment_status)} underline="none">

                                                    <Card key={item.id} className={classes.cardItem}>
                                                        
                                                       

                                                        <CardMedia
                                                            component="img"
                                                            alt="Contemplative Reptile"
                                                            height="180"
                                                            width="1"
                                                            image={logo}
                                                            title="Restaurant"
                                                            style={{ borderColor: "#D9D9D9" }}
                                                        />
                                                        {this.renderVerified(verified,classes) }


                                                        <CardContent>
                                                            <Typography className={classes.cardTitle}>{name}</Typography>
                                                        </CardContent>
                                                    </Card>
                                                    </Link>
                                                )
                                            })}

                                        </Box>

                                        <Box className={`${classes.marginTop30} ${classes.recentContainer}`}>
                                         
                                            {this.state.integrations.length>10? <Button className={`${classes.categoryBtn}`} onClick={()=>this.setState({showMoreIntegrations:!this.state.showMoreIntegrations})} data-testid="loadmore" variant="text" >
                                               {!this.state.showMoreIntegrations? <Typography variant="body1">Load more</Typography>:<Typography variant="body1">Load less</Typography>}
                                            </Button>:<div></div>}
                                            
                
                                            {this.renderIntegrationLinkbtn(this.state.integrationlink,classes)}
                                           
                                        </Box>
                                    </Box>


                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Box id={`4-tab`} className={classes.accordionContainer}>
                            <Accordion style={{borderRadius:"8px"}}data-testid="mediaAccordion" onChange={() => this.handleAccordion('mediaOpen')} expanded={this.state.mediaOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Media</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={classes.mediaContainer}>
                                        {Array.isArray(this.state.videos) && this.state.videos.map((video: any, id: number) =>
                                        (<Box key={id} className={`${classes.marginTop30} ${classes.mr65}`} >
                                            <ReactPlayer 
                                                className={classes.mediaItem}
                                                url={video.url}
                                               style={{borderRadius:"16px"}}
                                                controls={true}
                                            />
                                        </Box>)
                                        )}



                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>
                        <Box id={`5-tab`} className={classes.accordionContainer}>
                            <Accordion style={{borderRadius:"8px"}} data-testid="eventsAccordion" onChange={() => this.handleAccordion('eventsOpen')} expanded={this.state.eventsOpen}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.accordionHeading}>Events</Typography>
                                </AccordionSummary>
                                <AccordionDetails style={{padding: "39px 56px 9px 37px"}}>
                                    <Box style={{ width: "100%" }} className={classes.integrationContainer} >
                                        <Box style={{ display: this.state.physicalEvents.length || this.state.digitalEvents.length? "flex":"none", justifyContent: "end", width: "100%" }}>
                                            <StyledToggleButtonGroup className={classes.toggleContainer} size="small" value={this.state.eventType} data-testid="eventToggle"
                                                onChange={this.handleEventType} exclusive >
                                                <ToggleButton className={`${classes.toggleBtn} ${classes.heading14}`} value="Physical" style={{padding: "6px 29px 4px 30px"}}>
                                                    <span  style={{fontWeight:400}}>Physical events</span>
                                                </ToggleButton>
                                                <ToggleButton className={`${classes.toggleBtn} ${classes.heading14}`} value="Digital" style={{padding: "6px 29px 4px 30px"}}>
                                                    <span style={{fontWeight:400}}>Digital events</span>
                                                </ToggleButton>
                                            </StyledToggleButtonGroup>
                                        </Box>
                                        <Box >
                                            {this.state.eventType == "Physical" ? this.renderEvents(classes, this.state.physicalEvents) : this.renderEvents(classes, this.state.digitalEvents)}



                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Box>

                        <Reviews id={`6-tab`} hideRatingForm={this.state.hideRatingForm}
                            handleEditBtn={this.handleEditBtn}
                            isEdit={this.state.editReview}
                            userdetails={this.state.userdetails}
                            handleLoadMore={this.handleLoadMore}
                            accordionState="reviewOpen" anchorEl={this.state.anchorEl}
                            openFilter={Boolean(this.state.anchorEl)}
                            handleRating={this.handleRating}
                            handleClose={this.handleClose}
                            ratingVal={this.state.ratingVal}
                            reviewComment={this.state.reviewComment}
                            handleReviewSubmit={this.handleReviewSubmit}
                            handleReviewComment={this.handleReviewComment}
                            data-testid="reviewAccordion"
                            reviewData={ratingsData}
                            handleAccordionToggle={this.handleAccordion}
                            loadmore={this.state.loadmore}
                            handleReviewUpdate={this.handleReviewUpdate}
                            handleClick={this.handleClick}
                            handleFilterClick={this.handleFilterClick}
                            filterValue={this.state.filterValue}
                            expanded={this.state.reviewOpen}
                             />

                        <Contacts id={`7-tab`} expanded={this.state.contactOpen} data-testid="contactAccordion" contactData={contactData} handleAccordionToggle={this.handleAccordion} accordionState="contactOpen" />


                    </Box >

                </Box >

                <CustomErrorToast data-testId= "errorToast" open={this.state.showErrorToast} onClose={this.handleErrorClose}/>

            </>
        );
        // Customizable Area End
    }
}
// Customizable Area Start
export const getFileName = (url: any) => {
    const fileName = url && url.split("/").pop()
    return fileName
}
export const daysHelperText = (days: any) => {
    if (days == 0) {
        return "Today"
    }
    else if (days == 1) {
        return "1 day ago"
    }
    else if (days > 1) {
        return `${days} days ago`
    }

}

export const unclaimedLabelStyle = {
    backgroundColor: '#343A40',
    color: '#FFFDFD'
}
export const featureLabelStyle = {
    backgroundColor: '#F1C52E',
    color: '#161616'
}
export const favouriteIconStyle = { height: 19, color: "#868E96", marginRight: 5 }

export const websiteBtnStyle = {
    backgroundColor: '#F62B8D',
    color: '#FFF',
    height: 36,
    width: 130,
    borderRadius: "9px",
    marginRight: 10
}

export const bookmarBtnStyle = {
    backgroundColor: '#0000',
    borderRadius: "9px",
    border: "1px solid #868E96",
    color: "#868E96",
    height: 36,
    width: 130,
    marginRight: 10
}
export const Accordion = withStyles({
    root: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },
    expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
    root: {
        paddingLeft: 56,
        paddingRight:24,
        borderRadius: 6,
        border: "1px solid #D9D9D9",
        backgroundColor: '#F1E8FF',
        borderBottom: '1px solid rgba(0, 0, 0, .125)',
        marginBottom: -1,
        minHeight: 56,
        '&$expanded': {
            minHeight: 56,
        },

    },
    content: {
        '&$expanded': {
            margin: '12px 0',
        },
    },
    expanded: {},
})(MuiAccordionSummary);



export const AccordionDetails = withStyles((theme) => ({
    root: {
        padding: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            padding:10
         },
    },
}))(MuiAccordionDetails);
const StyledToggleButtonGroup = withStyles((theme) => ({
    grouped: {
        margin: theme.spacing(0.5),
        border: 'none',
        '&:not(:first-child)': {
            borderRadius: 27,
            margin: "2px 0px"
        },
        '&:first-child': {
            borderRadius: 27,
            margin: "2px 0px"
        },
    },
    ".MuiToggleButton-root.Mui-selected": {
        color: "red"
    }
}))(ToggleButtonGroup);
const slickNext = {
    "& .slick-next": {
        backgroundImage: `url(${require('../assets/rightarrow.png')})`,
        backgroundPosition: "top",
        backgroundSize: "cover",
        height: "45px",
        width: "45px"
    }
}

const slickPrev = {
    "& .slick-prev": {
        backgroundImage: `url(${require('../assets/LeftArrow.png')})`,
        backgroundPosition: "top",
        backgroundSize: "cover",
        height: "45px",
        width: "45px"
    }
}
const slickPrevBefore = {
    "& .slick-prev::before": {
        content: "unset"
    },
}
const slickNextBefore = {
    "& .slick-next::before": {
        content: "unset"
    },
}

const tabCommon = {
    '& button': {
        minWidth: '135px'
    },
    '& button span:first-child': {
        fontSize: '18px',
        fontWeight: 400,
        textTransform: 'capitalize',
        fontFamily: 'Poppins, sans-serif'
    },
    '& button.Mui-selected span:first-child': {
        fontWeight: '600'
    }
}
export const userStyles: any = (theme: Theme) => createStyles({
    textFieldRoot: {
        height: '48px',
        '& .MuiOutlinedInput-root': {
          height: '100%',
          borderRadius: '8px',
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#CBD5E1', // Change border color on hover
          },
          '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#CBD5E1', // Change border color when focused
          },
        },
        '& .MuiInputBase-input': {
          height: '100%',
        },
        '& .MuiInputAdornment-root': {
          height: '100%',
          display: 'flex',
          alignItems: 'center',
        },
        ".MuiOutlinedInput-adornedStart": {
            paddingLeft: "14px",
            paddingTop: "5px"
      },
    },
    root: {
        flexGrow: 1,
        backgroundColor: 'rgb(248, 242, 255)',
        padding: "10px 20px",
        marginTop:"8%",

    },
    container: {
        padding: 30
    },
    tabContainer: {
        padding: '5px 0px',
        justifyContent: "space-between",
        ...tabCommon
    },
    supplierTabContainer: {
        padding: '5px 0px',
        justifyContent: "start",
        ...tabCommon,
        [theme.breakpoints.down('sm')]: { 
            width: "100%",
            '&  > div':{
              display:'flex',
              overflowX:'scroll !important',
            },
            '&  > div::-webkit-scrollbar': {
              display: "none",
          }
          
        }
    },
    navBar: {
        margin: '0px 30px',
        marginTop:"-2.6rem"
    },
    headerContainer: {
        borderRadius: "6px",
        border: "1px solid #D9D9D9",
        background: "#FFF",
    },
    cards: {
        backgroundColor: "#fff",
        padding: '25px',
        boxShadow: "none"
    },

    cardHeader: {
        display: 'flex',
        "@media(max-width:600px)": {
            flexDirection: 'column',
        },
    },
    imgContainer: {
        marginRight: '15px',
    },
    restroImg: {
        width: '110px',
        '& img': {
            width: '110px',
            height: '110px',
            padding: '10px',
            border: '4px solid',
            borderRadius: '100%',
            objectFit: 'fill'
        }
    },
    cardBtnStyle: {
        padding: '1px 20px',
        boxShadow: 'none',
        '& span:nth-child(1)': {
            fontSize: '14px',
            fontWeight: '40end0',
            textTransform: 'initial',
            fontFamily: 'Poppins, sans-serif',
        }
    },
    cardBtnContainer: {
        padding: '0px',
        paddingRight: 20
    },
    cardDescriptionContainer: {
        display: 'flex',
        alignItems: "center",
        marginBottom: '15px',
        justifyContent: "end",
        [theme.breakpoints.down('sm')]: {
            display:"block"
         },
    },
    cardHeading: {
        fontFamily: 'Poppins, sans-serif',
        color: "#000",
        fontSize: 24,
        fontStyle: "normal",
        fontWeight: 700,

    },
    footerDescription: {
        fontSize: 14,
        fontWeight: 400,
        color: "#666",
        fontFamily: 'Poppins, sans-serif',
        marginTop: 30
    },
    aboutText: {
        fontSize: 14,
        fontWeight: 400,
        color: "#666",
        fontFamily: 'Poppins, sans-serif',
    },
    accordionContainer: {
        margin: "10px 0px"

    },
    accordionHeading: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 700,
        whiteSpace:"nowrap",
    },
    boxLayout: {
        borderRadius: 6,
        border: "1px solid #C8C9CA",
        padding: "20 32",
        marginBottom: 10,
        width: "auto"
    },
    heading16: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 700
    },
    heading14: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 600
    },
    heading400: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 400,
        overflowWrap:"anywhere"
    },
    detailsContainer: {
        display: "flex",
        width: "100%",
        gap:25,
        [theme.breakpoints.down('sm')]: {
            display: "block",
         },

    },
    leftSection: {
        flex: 1,
        display:"flex",
        flexDirection:"column" as "column",
        gap:"10px"
    },
    marginTop10: {
        marginTop: 10
    },
    marginTop5: {
        marginTop: 5
    },
    marginTop20: {
        marginTop: 20
    },
    marginTop30: {
        marginTop: 30
    },
    ml5: {
        marginLeft: 5
    },
    ml10: {
        marginLeft: 10
    },
    mr5: {
        marginRight: 5
    },
    mr10: {
        marginRight: 10
    },
    mr20: {
        marginRight: 20
    },
    mr65: {
        marginRight: 65
    },
    mr100: {
        marginRight: 100
    },
    chipContainer: {
        display: "flex",
        flexWrap: 'wrap',
        margin: "10 0",
        [theme.breakpoints.down('sm')]: {
            display:"block"
         },
    },
    chipStyle: {
        borderRadius: 29,
        background: "#2EA3F2",
        margin: "5px 25px 5px 0px",
        '& span': {
            fontSize: '14px',
            fontWeight: '500',
            color: '#FFF',
            fontFamily: 'Poppins, sans-serif',
            "@media(max-width: 430px)": {
               width:"50vw"
              }
        },
        
    },
    rightSection: {
        flex: 1,
        display:"flex",
        flexDirection:"column" as "column",
        gap:"10px"
    },
    categoryBtn: {
        padding: '0px',
        margin: '0px',
        justifyContent: 'flex-start',
        marginTop: '10px',
        display: 'flex',
        '& span p': {
            fontSize: '14px',
            fontWeight: 400,
            color: '#6200EA',
            fontFamily: 'Poppins, sans-serif',
            textTransform: 'initial',
        },
    },
    neutralGray: {
        color: "#0F172A"
    },
    targetUsers: {
        display: "flex",
       
    },
    userType: {
        marginLeft: "180px"
    },
    fileItem: {
        display: "flex",
        alignItems: "center"

    },
    fileContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        [theme.breakpoints.down('sm')]: {
            justifyContent: "start",
         },
    },
    downloadtxt: {
        color: "#B300ED",
        fontFamily: 'Inter, sans-serif',
        fontSize: 18,
        fontStyle: "normal",
        fontWeight: 400,
        cursor: "pointer",
        textDecoration:"none",
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20,
         },

    },
    sliderContainer: {
        backgroundColor: "#E8DEF4"
    },
    slickSlider: {
        marginLeft: "auto",
        marginRight: "auto",
        maxWidth: "464px",
        height: "287px",
        "& .slick-list": {
            width: "70%",
            margin: "40 auto",
            [theme.breakpoints.down('sm')]: {
                margin: "40 70",
             },
             [theme.breakpoints.down('md')]: {
                maxWidth: "400px",
             },
           
            "& .slick-track": {
                "& .slick-slide": {
                    height: "inherit",
                }
            }
        },
        ...slickPrev,
        ...slickNext,
        ...slickPrevBefore,
        ...slickNextBefore

    },
    basicSlider: {
        width: "92%",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "61.78px",

        "& .slick-list": {
            width: "90%",
            margin: "0 auto",
            "& .slick-track": {
                "& .slick-slide": {
                    height: "inherit",
                }
            }
        },

        ...slickPrev,
        ...slickPrevBefore,
        ...slickNext,
        ...slickNextBefore
    },
    sliderImg: {
        height: "269px",
        width: "387px",
        objectFit:"fill",
        [theme.breakpoints.down('sm')]: {
            height: "30vw !important",
            width: "42vw !important",
         },
      
    },
    aboutItems: {
        display: "flex",
        [theme.breakpoints.down('sm')]: {
            display:"block"
         },
        
    },
    aboutItem: {
        flex: 1
    },
    form: {
        width: "100%",
        borderRadius: '3px',
        backgroundColor: '#FAF5FF',
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
        '& .MuiOutlinedInput-root': {
            borderRadius: "8px",
            fontFamily: 'Poppins',
        }
    },
    integrationContainer: {
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            padding:"20 0px"
         },
    },
    inputContainer: {
        width: "100%",
        display:"flex",
        justifyContent:"space-between",
        alignItems:"center",
        gap:10

    },
    paddLeft56:{paddingLeft:"56px",paddingRight:"56px"},
    textfieldName: {
        width: "100%",
        '& .MuiOutlinedInput-root': {
            borderRadius: "8px",
            fontFamily: 'Poppins, sans-serif',
            border:"1px solid #CBD5E1",
        },
        "&.MuiOutlinedInput-notchedOutline":{
            border:"1px solid #CBD5E1",
        },  
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            borderColor:"#CBD5E1"
        }

    },
    cardItem: {
        background: "#FAF5FF",
        padding: 10,
        width: 200,
        borderRadius: 6,
        border: "1px solid #D9D9D9",
        boxShadow: "none",
        marginRight: 10,
        marginTop: 20,
        position:"relative"
    },
    eventCardImg: {
        border: "2px solid #B300ED",
        borderRadius: "50%",
        height: "100px",
        margin: "5 auto",
        width: "100px",
        objectFit: "fill"
    },
    cardWrapper: {
        background: "#fff",
        padding: 10
    },
    cardTitle: {
        color: "#444",
        textAlign: "center",
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 500,
        textWrap:"nowrap"
    },
    cardTitle500: {
        textAlign: "center",
        fontFamily: 'Poppins, sans-serif',
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 500
    },
    cardContent300: {
        fontFamily: 'Poppins, sans-serif',
        fontSize: 12,
        fontStyle: "normal",
        fontWeight: 300
    },
    cardsContainer: {
        flexWrap: "wrap",
        display: "flex"
    },
    mediaContainer: {
        flexWrap: "wrap",
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    verifiedBage: {
   
        position: "absolute",
        padding: "0px 10px",
        top:10,
        left: 93,
        color: "#fff",
        textAlign: "center",
        width: 93,
        borderRadius: 6,
        background: "#059669",
        height: 22,
        '& h3':{
            fontSize:"13px"
        }

    },
    marginRight35: {
        marginRight: 0
    },
    lightgray: {
        color: "#737373"
    },
    contactItem: {
        display: "flex",
        marginBottom: 20
    },
    contactItemtext: {
        color: "#151515",
        fontFamily: "Open Sans, sans-serif",
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 500,
    },
    socialContainer: {
        marginTop: 80
    },
    videoItem: {
        borderRadius: 16
    },
    backdrop: {
        zIndex:1200,
        color: '#fff',
    },
    icon: {
        fontSize: 32,
        marginRight: 30
    },
    textArea: {
        width: '100%',
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        padding: "8px 9px 10px 8px",
        color: "#000",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        minHeight: "144px",
        fontFamily: "Poppins",
        '&:hover': {
            borderColor: '#CBD5E1',
        },
        '&:focus': {
            borderColor: '#CBD5E1',
        },
    },
    submitBtn: {
        display: "flex",
        width: '89px',
        height: "32px",
        padding: "6px 10px",
        justifyContent: "center",
        alignItems: "center",
        gap: "6px",
        flexShrink: 0,
        borderRadius: "8px",
        backgroundColor: "#B300ED",
        color: "white",
        textTransform: "initial",
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    btnContainer: {
        display: "flex",
        justifyContent: "end"
    },
    iconFilled: {
        color: "#6200EA"
    },
    reviewTitle2: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 18,
        fontWeight: 500,
    },
    recentContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 24
    },
    reviewTitle3: {
        color: "#334155",
        textTransform: "initial"
    },
    recentText: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 14,
        fontWeight: 700,
    },
    ratingScore: {
        fontFamily: 'Inter, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 700,
    },
    ratingItem: {
        display: "flex",
        alignItems: "center",
        gap: 4
    },
    customScrollbar: {
        height: "auto",
        overflowY: 'scroll' as const,
        overflowX: 'hidden' as const,
        padding: '40px 50px',
        scrollbarColor: "#D6D6D6",
        [theme.breakpoints.down('sm')]: {
            padding:20
            // height:400,
         },
        "&::-webkit-scrollbar": {
            width: 4,
            height: 6,
            backgroundColor: "#D6D6D6",
            padding: '30px',
        },

        "&::-webkit-scrollbar-thumb": {
            background: "#B300ED",
            borderRadius: 6,
            minWidth: 14,
            minHeight: 24
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
    },
    ratingHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center"
    },
    ratingDaysColor: {
        color: "#64748B"
    },
    similarHeading: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 18,
        fontWeight: 600,
    },
    basicImg: {
        height: 100,
        width: 200,
        marginTop: 50,
        objectFit:"contain"
    },
    heading12: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 12,
        fontWeight: 400,
        overflowWrap:"anywhere",
       
    },
    color666: {
        color: "#666"
    },
    cardDescriptionHead: {
        color: "#A7A7A7",
        fontFamily: 'Poppins, sans-serif',
        fontSize: 14,
        fontStyle: "normal",
        fontWeight: 500
    },
    heading500: {
        fontFamily: "Poppins, sans-serif",
        fontSize: 14,
        fontWeight: 500,
    },
    textCenter: {
        textAlign: "center"
    },
    marketUl: {
        display: "flex",
        alignItems: "baseline",
        paddingLeft: 0,
        marginTop: "5px",
        "& li": {
            width: 153,
            whiteSpace:"nowrap"
        },
        
        
        
    },
    toggleContainer: {
        padding: "5px 6px",
        borderRadius: 40,
        background: "#B300ED"


    },
    toggleBtn: {
        padding: "5px 10px",
        textTransform: "none",

        '&.MuiToggleButton-root.Mui-selected': {
            backgroundColor: "white",
            color: "black",
        },

    },
    mediaItem:{
        width:"417px !important",
        height:"211px !important",
        [theme.breakpoints.down('sm')]: {
            width:"50vw !important",
            height:"50vw !important"
         },
    },
    ellipsisMultiline: {
        width: "300px",
        whiteSpace: "nowrap",
        overflow:"hidden",
        textOverflow: "ellipsis"
      },
    eventElipsis:{
        width: "170px",
        textOverflow: "ellipsis",
        textWrap: "nowrap",
        overflow: "hidden",
        textAlign:"center"
    },
    formcontrol: {
        width: "100%",
        borderRadius: '3px',
        backgroundColor: '#FAF5FF',
        // border:"1px solid #CBD5E1",
        
      
        '& .Mui-focused': {
            border: '1px solid #FAF5FF',
            outline:'none',
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
            border: '1px solid #FAF5FF',
        },
        '& .MuiOutlinedInput-root': {
            border:"1px solid #FAF5FF",
            fontFamily: 'Poppins',
            color:"#737373",
            borderRadius:"8px",


        },
        "& .MuiOutlinedInput-root:hover":{
            border: "1px solid #CBD5E1 !important",
            borderRadius:"8px !important"
        }, 
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":{
            borderColor:"#CBD5E1",
            border: '1px solid #FAF5FF',
        }
    }

})

export default withStyles(userStyles)(PremiumListing)
// Customizable Area End
