import React from "react";

// Customizable Area Start
import {
    Typography,
    Grid,
    Box,
    Theme
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';
// Customizable Area End

// Customizable Area Start
import ServicesFilePageController, {
    Props,
    configJSON,
} from "./ServicesFileController";
import { AddIConWhite, deleteIcon } from "./assets";
import FeedbackForm from "./FeedbackForm.web";
import CustomToast from "../../../components/src/CustomToast.web";
// Customizable Area End

export class ServicesFilePage extends ServicesFilePageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (

            <Grid container className={classes.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.font_1}>{configJSON.files}</Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <Typography className={classes.fontStyle_9}>{configJSON.addFiles}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container className={classes.screenshotheader}>
                        <Grid item xs={6} md={6}><Typography className={classes.font_2}>{configJSON.fileName}</Typography></Grid>
                        <Grid item xs={5} md={5}><Typography className={classes.font_2}>{configJSON.size}</Typography></Grid>
                    </Grid>
                    <Grid container className={classes.screenshotBody} >
                        {this.state.uploadFiles.map((item) => {
                            return (
                                <>
                                    <Grid key={item} item xs={6} md={6}><Typography className={classes.fontStyle_2}>{decodeURIComponent(item.name)}</Typography></Grid>
                                    <Grid item xs={3} md={3}><Typography className={classes.fontStyle_2}>{item?.id ? item.size : this.formatFileSize(item.size)}</Typography></Grid>
                                    <Grid xs={2} md={2} item>{item?.url && <a download href={item.url} className={`${classes.marginTop10} ${classes.downloadtxt}`}>Download</a>}</Grid>
                                    <Grid item xs={1} md={1} className={classes.cancelIcon} data-testid={"remove_file"} onClick={() => this.removeDocument(item)}><img src={deleteIcon} alt="deleteicon" /></Grid>
                                </>
                            )
                        })}

                    </Grid>
                    <Grid container spacing={3} className={classes.ButtonBox} >
                        <Grid item md={4}><Typography className={classes.font_3}>{configJSON.maxSize}</Typography></Grid>
                        <Grid item md={5}>
                            <Box className={classes.imagefileBtnWrapper}>
                                <label htmlFor="upload-document"
                                    className={classes.addimagebtnScreenshot}>
                                    <img src={AddIConWhite} alt="AddIConWhite" />
                                    <Typography className={classes.add}>{configJSON.addFile}</Typography>
                                </label>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    accept=".pdf"
                                    onChange={this.onFileUpload}
                                    data-testid={"inputrefdocument"}
                                    id="upload-document"
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container >
                        <Grid item xs={12} sm={12} md={7} lg={7} className={classes.feedBack_Text}>
                            <Typography className={classes.fontStyle_9}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.font_5}>{configJSON.no}</Typography>
                            <Typography className={classes.font_5} data-testid={"openFeedback"} onClick={this.handleOpenFeedbackFrom}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.feedBack_Text}>
                            <CustomBackButton data-testid="gotoVendorAccount" label={configJSON.backBtn} handleClick={this.goToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} data-testid={"save_button"} handleClick={this.uploadFilesOnSubmit} />
                        </Grid>
                    </Grid>
                </Grid>

                <CustomToast data-testid="gotoaccount" open={this.state.isSucessModalOpen} onClose={this.handleCloseSuccess} />


                <FeedbackForm data-testid={"feedback_modal"} openFeedback={this.state.isFeedbackFormOpen} closeFeedback={this.handleCloseFeedbackForm} navigation={this.props.navigation} id={""} />
            </Grid>

        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles = (theme: Theme) => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },
    imagefileBtnWrapper: {
        display: "flex",
        justifyContent: "center",
    },
    downloadtxt: {
        color: "#B300ED",
        fontFamily: 'Inter, sans-serif',
        fontSize: 18,
        cursor: "pointer",
        textDecoration: "none",
        fontStyle: "normal",
        fontWeight: 400,
        [theme.breakpoints.down('sm')]: {
            marginLeft: 20,

        },
    },
    addimagebtnScreenshot: {
        height: "44px",
        padding: "0px 16px",
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        alignItems: "center",
        borderRadius: "8px",
        background: "#B300ED",
        color: "white",
        textTransform: "initial" as const,
        cursor: "pointer",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
    },

    font_1: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    fontStyle_2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins',
        marginBottom: '5px'
    },

    fontStyle_9: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },


    screenshotheader: {
        padding: "10px",
        borderBottom: "1px solid #CBD5E1",
    },

    screenshotBody: {
        padding: "10px 7px 10px 10px",
    },

    ButtonBox: {
        marginTop: '5%',
        alignItems: 'center',
    },

    cancelIcon: {
        cursor: 'pointer',
        textAlign: "end" as const,
        marginBottom: '5px'
    },

    font_2: {
        color: "#475569",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
    },

    font_3: {
        fontSize: '14px',
        fontWeight: 300,
        color: '#2D2E2F',
        fontFamily: 'Poppins',
        marginLeft: '5px',
    },
    addMoreBtn: {
        backgroundColor: "#B300ED",
        borderRadius: '10px',
        width: '33%',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    BtnTextStyle: {
        textTransform: 'initial' as const,
        fontSize: "14px",
        fontFamily: "Poppins",
        color: "#FFFFFF",
        fontWeight: 400,
    },

    font_5: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor:"pointer"
    },

    feedBack_Text: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '20%',
        marginBottom: '20%'
    },
});

export default withStyles(userStyles)(ServicesFilePage)
// Customizable Area End