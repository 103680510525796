import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Checkbox,
    Button,
    OutlinedInput,
    Theme,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import VendorsPageController, {
    Props,
    configJSON,
} from "./VedorsPageController";
import { CloseICon } from "./assets";

import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";

// Customizable Area End

export class VendorsPage extends VendorsPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props

        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.boxWrapperStyle}>
                <Backdrop className={classes.backdrop} open={this.state.isLoad}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography className={classes.vendorTextStyle}>{configJSON.vendorText}</Typography>
                <Box className={classes.textFeildBoxStyle}>

                    <Box className={classes.boxWidth}>
                        <Box className={classes.contentBoxStyle}>
                            <Typography className={classes.fontStyle1}>{configJSON.vendorText}</Typography>
                            <Typography className={classes.fontStyle2} data-testid={'vendor_name'}>{configJSON.enterVendorName}</Typography>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedVendorData?.name}
                                onChange={this.handleChangeName}
                                data-testid={"change_name"}
                                inputProps={{
                                    'aria-label': 'address',

                                    className: classes.placeholderStyle,
                                }}
                                classes={{
                                    root: classes.outlinedInputStyle,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutlineStyle,
                                    input: classes.inputTextStyle
                                }}
                            />
                        </Box>

                        <Box className={classes.contentBoxStyle}>
                            <Typography className={classes.fontStyle1}>{configJSON.vendorurl}</Typography>
                            <Typography className={classes.fontStyle2}>{configJSON.enterVendorUrl}</Typography>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedVendorData?.vendorURL}
                                data-testid={"change_vendorurl"}
                                onChange={this.handleChangevendorURL}
                                inputProps={{
                                    'aria-label': 'address',

                                    className: classes.placeholderStyle,
                                }}
                                classes={{
                                    root: classes.outlinedInputStyle,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutlineStyle,
                                    input: classes.inputTextStyle
                                }}
                            />
                        </Box>
                    </Box>

                    <Box className={classes.boxWidth}>
                        <Box className={classes.contentBoxStyle}>
                            <Typography className={classes.fontStyle1}>{configJSON.vendorCountry}</Typography>
                            <Typography className={classes.fontStyle2}>{configJSON.enterVendorCountry}</Typography>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedVendorData?.country}
                                data-testid={"change_countryname"}
                                onChange={this.handleChangeCountry}
                                inputProps={{
                                    'aria-label': 'address',
                                    className: classes.placeholderStyle,
                                }}
                                classes={{
                                    root: classes.outlinedInputStyle,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutlineStyle,
                                    input: classes.inputTextStyle
                                }}
                            />
                        </Box>

                        <Box className={classes.contentBoxStyle}>
                            <Typography className={classes.fontStyle1}>{configJSON.vendorFounded}</Typography>
                            <Typography className={classes.fontStyle2}>{configJSON.enterYearFounded}</Typography>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedVendorData?.founded}
                                data-testid={"change_founded"}
                                onChange={this.handleChangeFoundedDate}
                                inputProps={{
                                    'aria-label': 'address',

                                    className: classes.placeholderStyle,
                                }}
                                classes={{
                                    root: classes.outlinedInputStyle,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutlineStyle,
                                    input: classes.inputTextStyle
                                }}
                            />
                        </Box>
                    </Box>
                </Box>


                <Box>

                    <Typography className={classes.platformText}>In Good Company</Typography>
                    {this.state.isChecked.checkboxes.map((checkbox) => (
                        <Box key={checkbox.id} style={{ display: "flex", alignItems: "center" }}>
                            <Checkbox
                                checked={checkbox.active}
                                onChange={this.handleCheckboxChange(checkbox.id)}
                                checkedIcon={<span className={clsx(classes.iconStyle_1, classes.checkedIcon_1)} />}
                                icon={<span className={classes.iconStyle_1} />}
                                inputProps={{ 'aria-label': 'decorative checkbox' }}
                                data-testid={"checkbox"}
                            />
                            <label><span className={classes.lebelTextStyle} style={{ display: "flex", alignItems: "center" }}><img src={checkbox.icon} alt={checkbox.name} style={{ borderRadius: "50%", width: "25px", height: "25px", marginRight: "10px" }} /> {checkbox.name} </span></label>
                        </Box>
                    ))}
                </Box>


                <Box className={classes.saveBtnWrapper_1}>
                    <Box className={classes.buttonBox}>
                        <Typography className={classes.fontStyle5}>{configJSON.needHelp}</Typography>
                        <Typography className={classes.fontStyle4}>{configJSON.no}</Typography>
                        <Typography className={classes.fontStyle4} onClick={this.handleOpenFeedbackModalVendor} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                    </Box>

                    <Box className={classes.buttonBoxStyle_1}>
                        <Button
                            variant="outlined"
                            className={classes.decline_Btn_1}
                            onClick={this.goToVendorAccount}
                            data-testid={"navigateToAccount"}
                        >
                            {configJSON.backBtn}
                        </Button>

                        <Button
                            variant="contained"
                            className={classes.accept_Btn_1}
                            onClick={this.updateVendorListingData}
                            data-testid={"save_button"}
                        >
                            {configJSON.saveBtn}
                        </Button>
                    </Box>
                </Box>

                <CustomToast open={this.state.isSucess} onClose={this.handleCloseSuccessDialog} />

                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModalVendor} handleSubmitFeedback={this.handleSubmitFeedbackVendors} handleFeedbackEmailChange={this.handleFeedbackEmailChange} handleFeedbackMessageChange={this.handleFeedbackMessageChange} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    boxWrapperStyle: {
        border: '2px solid #C8C9CA',
        padding: '40px 5% 40px 5%',
        borderRadius: '6px',
        margin: '33px 46px 50px 45px',

        [theme.breakpoints.down('md')]: {
            margin: "0",
        },
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },
    textFeildBoxStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: '3%',

        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: 'unset',
            gap: "30px",
        },
    },
    outlinedInputStyle: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    focused: {},
    notchedOutlineStyle: {
        borderWidth: '1px',
    },
    placeholderStyle: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputTextStyle: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },
    vendorTextStyle: {
        fontSize: '20px',
        color: '#2D2E2F',
        fontWeight: 700,
        fontFamily: 'Poppins'
    },
    contentBoxStyle: {
        display: 'flex',
        gap: '7px',
        justifyContent: 'center',
        flexDirection: 'column' as const,
    },

    boxWidth: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '30px',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    boxWidth_2: {
        width: '65%',
        marginTop: '30px'
    },
    fontStyle1: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    fontStyle2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },
    formcontrol: {
        width: "100%",
        backgroundColor: '#FAF5FF',
        borderRadius: '3px',
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
    },

    addMoreBoxStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px',
        gap: '5px',
        cursor: 'pointer'
    },

    platformText: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        marginTop: "205px",
        marginBottom: "24px",
    },

    saveBtnWrapper_1: {
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        marginTop: "59px",

        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "unset",
            gap: "20px",

        },
    },

    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer",
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    fontStyle5: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },

    iconStyle: {
        height: '15px',
        width: '15px'
    },
    fontStyle3: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins'
    },
    iconStyle_1: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #64748B',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon_1: {
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        backgroundColor: '#B300ED',
        '&:before': {
            width: 16,
            height: 16,
            display: 'block',
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },

    lebelTextStyle: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F172A',
        fontFamily: 'Poppins',
        lineHeight: '20px'
    },
    accept_Btn_1: {
        color: "#FFF",
        height: "55px",
        width: "241px",
        padding: "16px 71px",
        fontWeight: 400,
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        fontFamily: "Poppins",
        fontSize: "16px",
        borderRadius: "8px",
        lineHeight: "24px",
        textTransform: "initial",
        background: "#B300ED",
        textWrap: "nowrap",

        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    decline_Btn_1: {
        lineHeight: "50.956px",
        color: "#B300ED",
        textAlign: "center",
        padding: "2px 81px",
        fontSize: "18px",
        fontWeight: 400,
        textTransform: "initial",
        fontFamily: "Poppins",
        border: "1px solid #B300ED",
        background: "#FFF",
        borderRadius: "6px",
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    buttonBoxStyle_1: {
        display: 'flex',
        gap: '20px',
        // justifyContent: 'flex-end'
    },

    dialogStyles_1: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    dialogBoxStyle_1: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        paddingTop: '30px'
    },

    rightTickIcon_1: {
        height: '65px',
        width: '65px',
    },

    buttonStyle_1: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },

    welcomeFontStyle_1: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },
});


export default withStyles(userStyles)(VendorsPage)
// Customizable Area End