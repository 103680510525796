// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
export interface ListDataResponse {
   categories:any[];
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    // Customizable Area Start
    navigation: any;
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabValue: number,
    files: any,
    searchvalue: any,
    integrations: any,
    catalogueId: any,
    restaurantType: any,
    categories: any,
    platform: any,
    logo: any,
    name: any,
    description: any,
    isLoading: boolean,
    videos: any,
    ratingVal: any,
    anchorEl: any,
    service_screenshots: any,
    features_screenshots: any,
    featureOpen: boolean,
    aboutOpen: boolean,
    supportOpen: boolean,
    integrationOpen: boolean,
    mediaOpen: boolean,
    eventsOpen: boolean,
    reviewOpen: boolean,
    contactOpen: boolean,
    featureData: any,
    contactData: any,
    aboutData: any,
    serviceData: any,
    ratingsData: any
    loadmore: any,
    currentDate: any
    reviewComment: any;
    target_user: any,
    feature_platform: any,
    feature_ownership: any,
    sub_category: any,
    categoryData: any,
    moreCategories: boolean,
    token: any,
    userdetails: any,
    hideRatingForm: boolean,
    editReview: boolean,
    editReviewId: any,
    events: any,
    viemoVideo: any,
    youtubeVideo: any,
    filterValue: string,
    searchText: string,
    bookmarked:boolean,
    eventType: string,
    physicalEvents: any,
    digitalEvents: any,
    isExpanded:boolean,
    integrationlink: any,
    markets: any,
    bookmark_id:any,
    directory_id:any,
    domain_url:string,
    showErrorToast:boolean,
    good_company:any,
    support:any,
    featured:boolean,
    showDirectoryPopup:boolean,
    searchDirectoryData:any,
    searchTermProp:string,
    showMoreIntegrations:boolean,
    categoryId:number,
    categoryListData:ListDataResponse[]
    integrations_count:number
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class PremiumListingController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    getCatalogueDataApiId: any;
    premiumbookmarkApiId: any;
    premiumpostReviewApiId: any;
    getCategoriesApiId: any;
    reviewUpdateApiId: any;
    deleteBookmarkApiId:any;
    getSearchResultDataReqId:string="";
    getAllCategoryDataId:string="";


    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabValue: 0,
            files: [],
            markets: [],
            searchvalue: "",
            integrations: [],
            catalogueId: 0,
            restaurantType: [],
            categories: [],
            platform: "",
            logo: "",
            name: "",
            description: "",
            isLoading: false,
            videos: [],
            ratingVal: 0,
            anchorEl: null,
            service_screenshots: [],
            features_screenshots: [],
            featureOpen: true,
            aboutOpen: true,
            supportOpen: true,
            integrationOpen: true,
            mediaOpen: false,
            eventsOpen: true,
            reviewOpen: true,
            contactOpen: true,
            featureData: {},
            contactData: {},
            aboutData: {

            },
            serviceData: {
                markets: "",
                support: [],
                training: [],
                languages: [],
                supporturl: "",
                trainingURL: ""
            },
            ratingsData: [],
            loadmore: false,
            isExpanded:false,
            currentDate: moment(),
            reviewComment: "",
            target_user: [],
            bookmarked:false,
            feature_platform: [],
            feature_ownership: [],
            sub_category: [],
            categoryData: [],
            moreCategories: false,
            token: "",
            userdetails: {},
            bookmark_id:null,
            hideRatingForm: false,
            editReview: false,
            editReviewId: null,
            events: [],
            youtubeVideo: [],
            viemoVideo: [],
            filterValue: "recent",
            searchText: "",
            eventType: "Physical",
            physicalEvents: [],
            digitalEvents: [],
            integrationlink: "",
            directory_id:null,
            domain_url:"",
            showErrorToast:false,
            good_company:[],
            support:[],
            featured:false,
            showDirectoryPopup:false,
            searchDirectoryData:[],
            searchTermProp:"",
            showMoreIntegrations:false,
            categoryId:-1,
            categoryListData:[],
            integrations_count:0
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
            let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));
        }

        // Customizable Area Start
        const messageid = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

        if (this.getCatalogueDataApiId === messageid) {


            this.handleCatalogueResponse(responseJson, errorReponse)

        }
        else if (this.premiumpostReviewApiId === messageid) {
            if(responseJson.data && responseJson.data.type=="review"){
                this.setState({ hideRatingForm: true })
                this.getCatalogueData(this.state.catalogueId)
            }
            else if(responseJson.data && responseJson.data.type=="error"){
                this.setState({showErrorToast: true,isLoading: false })
               
            }
           
        }
        else if (this.premiumbookmarkApiId === messageid) {
            this.setState({ isLoading: false })
            this.getCatalogueData(this.state.catalogueId)
        }
        else if (this.reviewUpdateApiId === messageid) {
            this.setState({ editReview: false, hideRatingForm: true })
            this.getCatalogueData(this.state.catalogueId)
        }
        else if(this.deleteBookmarkApiId===messageid){
            this.setState({ isLoading:false })
            this.handleBookmarkDeleteResponse(responseJson, errorReponse)
        }
        else if(messageid==this.getSearchResultDataReqId){
            this.handleDirectorySearch(responseJson)
            this.setState({isLoading:false})
          }

        else if(messageid==this.getAllCategoryDataId){
            this.handleCategoryListData(responseJson)
        }  
        


        // Customizable Area End
    }
    themeColorDetect = (val: number) => {
        if (this.state.tabValue === val) {
            return "#B300ED"
        } else {
            return '#000'
        }
    };
    handleChange = (event: any, newValue: number) => {

        const element:any =window.document.getElementById(`${newValue}-tab`);
        element?.scrollIntoView();
        this.setState({ tabValue: newValue });
    };
    async componentDidMount() {
        const id = this.getCatalogueId()
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);
        this.setState({ catalogueId: id, token, userdetails: accountDetails })
        this.getCatalogueData(id, this.state.filterValue);
        this.getCategoryListData();
    }

    getCatalogueData = async (id: any, filter?: any) => {
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken") || ""
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token

        };

        let  endPoints = `${configJSON.catalogueAPiEndPoint}/${id}?review_order=${filter ?? this.state.filterValue}`;
        if( this.state.categoryId!=-1) endPoints+=`&category_id=${this.state.categoryId}`;
       
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getCatalogueDataApiId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endPoints
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    };
   

    handleNavigation = (page:any) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), page);
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }  
    navigateToAccount= (usertype:any) => {
      let accountType=usertype.toLowerCase()
        if (accountType == "vendor") {
            this.handleNavigation("VendorAccountDetails")
        }else if(accountType == "buyer" ){
            this.handleNavigation("BuyerAccountDetails")      
          }
    }

    handleRating = (event: any, val: any) => {
        this.setState({ ratingVal: val })
    }
    handleUserSignout = async () => {
        await removeStorageData("loginToken")
        this.handleNavigation("Home")
    }

    handleClose = () => {
        this.setState({ anchorEl: null });
    };
    handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget })
    };
    handleFilterClick = (event: any, filter: any) => {
        this.setState({ filterValue: filter, anchorEl: null })
        this.getCatalogueData(this.state.catalogueId, filter)
    }
   
    hanldefilteredData = ()=>{
        const data=this.state.integrations && this.state.integrations.filter((item:any) =>
        item.attributes.name.toLowerCase().includes(this.state.searchText.toLowerCase())
      );
    
      return data &&  data.slice(0, this.state.showMoreIntegrations ? data.length : 10)
    }

    getFileName = (url: any) => {
        const fileName = url && url.split("/").pop()
        return fileName
    }
    getFileType = (url: any) => {
        const fileType = url && url.includes('.pdf') ? "pdf" : "xlxs"
        return fileType
    }

    handleAccordion = (accordion: any) => {
        if (accordion == "featureOpen") {
            this.setState({ featureOpen: !this.state.featureOpen })
        }
        else if (accordion == "aboutOpen") {
            this.setState({ aboutOpen: !this.state.aboutOpen })
        }
        else if (accordion == "supportOpen") {
            this.setState({ supportOpen: !this.state.supportOpen })
        }
        else if (accordion == "integrationOpen") {
            this.setState({ integrationOpen: !this.state.integrationOpen })
        }
        else if (accordion == "mediaOpen") {
            this.setState({ mediaOpen: !this.state.mediaOpen })
        }
        else if (accordion == "eventsOpen") {
            this.setState({ eventsOpen: !this.state.eventsOpen })
        }
        else if (accordion == "reviewOpen") {
            this.setState({ reviewOpen: !this.state.reviewOpen })
        }
        else if (accordion == "contactOpen") {
            this.setState({ contactOpen: !this.state.contactOpen })
        }

    }


    handleLoadMore = () => {
        this.setState({ loadmore: true })
    }


    handleCatalogueResponse = (responseJson: any, errorReponse: any) => {
        if (responseJson && !responseJson.error) {
            const { reviews } = responseJson.data;
            const {categories,domain_url,integration_link,featured,integrations_count, description,directory_id,bookmarked,bookmark_id, restaurant_type, event, logo, name, markets, feature, service, contact, target_user, platform, data_ownership, features_documents, features_pdf, vendor, service_screenshots, integrations, sub_category,review_posted,videos } = responseJson.data.catalogue.data.attributes
            const physicalEvents = event && event.data.filter((eventItem: any) => eventItem.attributes.event_type == "Physical")
            const digitalEvents = event && event.data.filter((eventItem: any) => eventItem.attributes.event_type == "Digital")
            const restaurantType=restaurant_type.data && restaurant_type.data.filter((parent:any)=>parent.attributes.active)

            this.setState({ videos,mediaOpen:this.checkForLength(videos),integrations_count,isLoading: false,eventsOpen:this.checkForLength(event.data),integrationOpen:this.checkForLength(integrations.data),featured,bookmarked,directory_id,hideRatingForm:review_posted, domain_url,bookmark_id,integrationlink: integration_link, markets, digitalEvents, physicalEvents, categories, restaurantType, logo, files: features_pdf, name, description, featureData: feature, contactData: contact, aboutData: vendor, serviceData: service, ratingsData: reviews?.data, target_user: target_user, feature_platform: platform, feature_ownership: data_ownership, features_screenshots: features_documents, service_screenshots, integrations:integrations.data, sub_category })
            this.checkContactClose(contact);
            if(!this.checkForLength(categories)) this.setState({featureOpen:false})
            this.checkVendorName(vendor)
            this.checkSupportUrl(service)
           
        } else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }

    checkForLength=(data:any)=>{
        return data && data.length
    }
    
    checkContactClose=(contact:any)=>{
        if(!contact.phonenumber && !contact.address && !contact.contactus) this.setState({contactOpen:false})

    }
    handleReviewSubmit = async () => {

        if (this.state.token) {
            this.setState({ isLoading: true })

            let formdata = new FormData();
            formdata.append("catalogue_id", this.state.catalogueId);
            formdata.append("rating", JSON.stringify(this.state.ratingVal));
            formdata.append("comment", this.state.reviewComment);

            const header = {
                token: this.state.token
            };

            const reviewMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );

            this.premiumpostReviewApiId = reviewMessage.messageId;

            reviewMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `${configJSON.postReviewEndPoint}`
            );
            reviewMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(header)
            );
            reviewMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formdata

            );
            reviewMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.exampleAPiMethod
            );


            runEngine.sendMessage(reviewMessage.id, reviewMessage);
            return true;

        }
        else {
            
            this.goToLoginPage()
        }

    }
    handleSearchText = (e: any) => {
        this.setState({ searchText: e.target.value })
    }

    handleBookmarkCall = async () => {
        if(this.state.token){

        
        this.setState({ isLoading: true })
        const token = await getStorageData("loginToken")
        let formdata = new FormData();
        formdata.append("catalogue_id", this.state.catalogueId);
        formdata.append("directory_id", this.state.directory_id);


        const header = {
            token
        };

        const bookmarkmessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.premiumbookmarkApiId = bookmarkmessage.messageId;

        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.bookmarkEndPoint}`
        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );
        bookmarkmessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );


        runEngine.sendMessage(bookmarkmessage.id, bookmarkmessage);

        return true;
        }
        else {
            this.goToLoginPage()
        }
    }

    handleReviewComment = (event: any) => {
        this.setState({ reviewComment: event.target?.value })
    }
    handleBookmarkDeleteResponse=(responseJson:any,errorReponse:any)=>{
        if(responseJson && responseJson.success){
            this.setState({bookmarked:false})
        }
        else {
            this.parseApiCatchErrorResponse(errorReponse);
        }
    }


    getCatalogueId = () => {
        const currentPath = window.location.pathname;
        const pathParts = currentPath.split('/');
        const id = pathParts[pathParts.length - 1];
        return id
    }

    goToLoginPage = () => {
        this.handleSignUp();
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(message)
    }
    handleSignUp=async()=>{
        await setStorageData("component",JSON.stringify({component:"PremiumListing",id:this.state.catalogueId}))
    }

    handlemoreCategories = () => {
        this.setState({ moreCategories: !this.state.moreCategories })
    }
    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
        const token = await getStorageData("loginToken")
        const storedData = await getStorageData("userdetails");
        const accountDetails = JSON.parse(storedData);

        if (token != this.state.token && this.state.token == null) {
            this.setState({ token, userdetails: accountDetails },()=>{
                this.getCatalogueData(this.state.catalogueId)
            })
        }


    }

    handleEditBtn = (data: any) => {
        const { rating, comment, id } = data.attributes
        this.setState({ editReview: !this.state.editReview, reviewComment: comment, ratingVal: rating, editReviewId: id })
    }

    handleReviewUpdate = async () => {
        this.setState({ isLoading: true })
        let formdata = new FormData();
        formdata.append("id", this.state.editReviewId);
        formdata.append("rating", JSON.stringify(this.state.ratingVal));
        formdata.append("comment", this.state.reviewComment);
        const header = {
            token: this.state.token
        };

        const reviewMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.reviewUpdateApiId = reviewMessage.messageId;

        reviewMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.reviewUpdateEndPoint}`
        );
        reviewMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        reviewMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putAPIMethod
        );
        reviewMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formdata

        );


        runEngine.sendMessage(reviewMessage.id, reviewMessage);
        return true;

    }

    handleEventType = (event: React.MouseEvent<HTMLElement>, eventType: string) => {
        this.setState({ eventType });
    };
    handleBookmarkDelete= () => {
        this.setState({isLoading:true})
        const header = {
            "Content-Type": configJSON.validationApiContentType,
          token: this.state.token
        };
        const requestMessageDeleteApi = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
    
        this.deleteBookmarkApiId = requestMessageDeleteApi.messageId;
    
        requestMessageDeleteApi.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.bookmarkDeleteEndpoint+ `${this.state.bookmark_id}`
        );
        requestMessageDeleteApi.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
    
        requestMessageDeleteApi.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpDeleteMethod
        );
    
        runEngine.sendMessage(requestMessageDeleteApi.id, requestMessageDeleteApi);
        return true;
      };
    
      handleErrorClose=()=>{
        this.setState({showErrorToast:false})

      }

      handleAdvancedSearch = (directory:string) => {
        this.setState({showDirectoryPopup:false})
        const msg: Message = new Message(
          getName(MessageEnum.NavigateToAdvancedSearch)
        );
        msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
        this.send(msg);
    
      }
      handleSubmit=(event:any)=>{
        event.preventDefault();
        this.handleAdvancedSearch("All")
      }

      handleSearchInput = (event:any) =>{  
        this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
        if(!event.target.value) this.setState({showDirectoryPopup:false});
      }

      getSearchResultDirectory = () => {
        this.setState({isLoading:true})
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getSearchResultDataReqId = searchRequestMessage.messageId;
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);
    
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
    
    
      }
      handleDirectorySearch=(responseJson:any)=>{
        if(responseJson && responseJson.response){
          this.setState({isLoading:false,searchDirectoryData:responseJson.response})
        }
      }

      getListingUrl=(id:number,payment_status:string)=>{
        return payment_status=="success"?`/premiumlisting/${id}`: `/basicListing/${id}`
      }


      handleCategoryFilter=(event: React.ChangeEvent<{ value: unknown }>)=>{
        this.setState({categoryId:event.target?.value as number},()=>this.getCatalogueData(this.state.catalogueId))
      }


      getCategoryListData = () => {
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
    
      const searchRequestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
        this.getAllCategoryDataId = searchRequestMessage.messageId;
        searchRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
          );
          searchRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getIntegrationsCategoriesAPIEndpoint}?catalogue_id=${this.state.catalogueId}` );
    
          searchRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
      );
    
      runEngine.sendMessage(searchRequestMessage.id, searchRequestMessage);
      }


      handleCategoryListData=(responseJson:ListDataResponse)=>{
        if(responseJson){
            this.setState({categoryListData:responseJson.categories})
        }
      }
      checkVendorName=(vendor:any)=>{
        if(!vendor.name) this.setState({aboutOpen:false})

      }

      checkSupportUrl=(service:any)=>{
        if(!service.supporturl) this.setState({supportOpen:false})
      }

      handleDirectoryPopupClose=()=>{
        this.setState({showDirectoryPopup:false})
      }
      toggleText = () => {
        this.setState(prevState => ({
          isExpanded: !prevState.isExpanded
        }));
      };



}









// Customizable Area End

