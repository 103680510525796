import React from "react";

// Customizable Area Start
import {
    Grid, Typography, Divider, Table, TableBody, TableCell, RadioGroup, FormControlLabel,
    Radio, TableContainer, TableHead, TableRow, Button, Paper, Box, Dialog, Select, MenuItem, FormControl, InputLabel
} from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import { styled, withStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import TablePagination from '@material-ui/core/TablePagination';
import moment from "moment";
// Customizable Area End

// Customizable Area Start
import ListVendorController, {
    Props
} from "./ListVendorController";
import { closeIcon } from './assets';
// Customizable Area End

// Customizable Area Start
const listVendorTabs = ["Listings", "Claims"]
// Customizable Area End

export class ListVendor extends ListVendorController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const {classes}: any =this.props;
        const { listVendorData } =this.state;
        // Customizable Area End
        return (
            // Customizable Area Start

            <>
                <Grid item xs={12} className={classes.viewListContainer}>
                    <Box className={classes.listHeaderTitle}>
                        <Typography className={classes.titleListView}>Listings</Typography>
                        <Button onClick={() => this.HandleNavigateAddNewList()} className={classes.addListBtn} data-test-id={`add-listing`}>
                            Add new Listing <AddCircleOutlineOutlinedIcon className={classes.adddListIcon}/>
                        </Button>
                    </Box>
                    <Box className={classes.tabsBlock}>
                        {listVendorTabs?.map(tab => { const isTabSelected = this.state.listTab === tab
                            return( <Typography
                                    className={classes.tabItem}
                                    style={{ background: isTabSelected ? "#B300ED" : "none", color: isTabSelected ? "#FFF" : "#2A0066" }}
                                    onClick={() => this.handleTabChange(tab)}
                                    data-test-id={`tab${tab}`}
                                    key={tab}
                                >
                                    {tab}
                                </Typography>
                            )
                        })}
                    </Box>

                    <Box className={classes.navBar}>
                        <Tabs
                            value={this.state.tabCode}
                            onChange={this.handleChange}
                            centered
                            classes={{ centered: classes.tabContainer }}
                            TabIndicatorProps={{ style: { height: '3px', backgroundColor: "#B300ED" } }}
                        >
                            <Tab value={"Suppliers"} label="Supplier" style={{ color:"#2A0066",fontWeight:700,opacity:1}} />
                            <Tab value={"Software"} label="Software" style={{ fontWeight:700,color:"#2A0066",opacity:1}} />
                            <Tab value={"Services"}  label="Services" style={{ color:"#2A0066",fontWeight:700 ,opacity:1}} />
                        </Tabs>

                    </Box>
                    <TableContainer component={Paper} className={classes.container}>
                        <Table stickyHeader className={classes.table} aria-label="caption table">
                            <TableHead className={classes.head}>
                                <TableRow className={classes.ListTableHeader}>
                                    <TableCell align="center" className={classes.listlogoHead} > <span className={classes.fontSiveStyle}>Logo</span></TableCell>
                                    <TableCell align="left" className={classes.DateHeaders}><span className={classes.fontSiveStyle}>Name</span></TableCell>
                                    <TableCell align="left" className={classes.listingNameHead}><span className={classes.fontSiveStyle}>Description</span></TableCell>
                                    <TableCell align="left" className={classes.DateHeaders} ><span className={classes.fontSiveStyle}>Date</span></TableCell>
                                     <TableCell align="center" className={ classes.ActionsHeaders } > <span className={classes.fontSiveStyle}>Actions</span> </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody className={classes.listingBody}>
                                {listVendorData && listVendorData.length > 0 ?
                                    listVendorData.map((item: any, index: any) => {
                                        return( <TableRow className={classes.Listingrows} key={index}>
                                                <TableCell component="th" scope="row" align="center" className = { classes.listingLogoRow }>
                                                    {item.logo_url ?
                                                    <img className={classes.listImage} src={item.logo_url} alt={item.catalogue_name} />
                                                    :
                                                    <Box>
                                                        <p className={classes.listImageNologo}>No Logo</p>
                                                    </Box>
                                                    }
                                                </TableCell>
                                            <TableCell align="left" className={classes.ListingdateRow} ><span style={{
                                                fontFamily: 'Poppins, sans-serif',
                                                fontWeight: 500,
                                                fontSize: '12px',
                                                lineHeight: '14.1px'
                                            }}>{item.catalogue_name}</span></TableCell>
                                                <TableCell align="left" className={ classes.listinNameRowDesc } >
                                                    <DescTypo>
                                                  <span className={classes.descriptionStyle}>  {item.short_description && `${item.short_description?.substring(0, 90)}`}</span>
                                                    </DescTypo>
                                                </TableCell>
                                                <TableCell align="left" className={classes.ListingdateRow}><span 
                                                style={{
                                                    fontFamily: 'Poppins, sans-serif',
                                                    fontWeight: 500,
                                                    fontSize: '14px',
                                                    lineHeight: '14px'
                                                }}
                                                >{moment(item.created_at).format("D-MMM-YY")}</span></TableCell>
                                                <TableCell align="center">
                                                    <CustomFormControl>
                                                        <InputLabel id="demo-simple-select-autowidth-label" className={classes.selectLabel}>
                                                            <SettingsOutlinedIcon style={{marginLeft:"14px"}}/>
                                                        </InputLabel>
                                                        <Select
                                                            style={{ margin: "0" }}
                                                            labelId="demo-simple-select-autowidth-label"
                                                            id="demo-simple-select-autowidth"
                                                            autoWidth
                                                            displayEmpty
                                                            data-test-id={"action-select"}
                                                            onChange={(event) => this.handleSelectRow(event, item, this.state?.tabCode)}
                                                            inputProps={{ 'aria-label': 'Without label' }}
                                                            className={classes.select}
                                                            IconComponent={(props)=>(<KeyboardArrowDownIcon className={classes.icon} style={{color: '#006FFF'}} {...props}/>)}
                                                            MenuProps={{
                                                                anchorOrigin: {
                                                                    vertical: "bottom",
                                                                    horizontal: "left",
                                                                },
                                                                getContentAnchorEl: null,
                                                                PaperProps: {
                                                                    style: {
                                                                      boxShadow: 'none',
                                                                      borderRadius:"8px"
                                                                    },
                                                                  },
                                                            }}
                                                        >
                                                            <MenuItem data-test-id="edit-option-id" value={1} className={classes.menuItem}>Edit</MenuItem>
                                                            <MenuItem data-test-id="upgrade-option-id" value={2} className={classes.menuItem}>Upgrade/Downgrade</MenuItem>
                                                            <MenuItem data-test-id="Add-option-id" value={3} className={classes.menuItem}>Add On</MenuItem>
                                                        </Select>
                                                    </CustomFormControl>
                                                 </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    :
                                    null}
                            </TableBody>
                        </Table>

                    </TableContainer>
                    <TablePagination
                        rowsPerPageOptions={[5]}
                        component="div"
                        count={this.state.counts}
                        rowsPerPage={this.state.rowsPerPage}
                        page={this.state.page}
                        onPageChange={this.handleChangePage}
                        onRowsPerPageChange={this.handleChangeRowsPerPage}
                    />
                </Grid>

                <Dialog open={this.state.isOpen} data-testid="import-export"
                    PaperProps={{ className: classes.dialogImport }}>
                    <Box style={{ width: '100%' }}>
                        <Box className={classes.ImportTopHeader}>
                            <Typography className={classes.ImportFontStyle}>Import/Export Data</Typography>
                            <img
                                src={closeIcon}
                                className={classes.ImportcloseIcon}
                                onClick={() => this.setState({ isOpen: false })}
                                data-test-id="closeIcon"
                            />
                        </Box>
                        <Divider
                            style={{ backgroundColor: '#D9D9D9', marginTop: '5px ', marginBottom: '5px' }}
                        />

                        <Box style={{ margin: '10px 45px 45px 45px', }}>
                            <Box style={{ display: "flex" }}>
                                <CustomRadioGroup
                                    aria-label="import-export"
                                    name="import-export-radio"
                                    value={this.state.selectedOption}
                                    onChange={this.handleOptionChange}
                                    data-testid={"import-export"}
                                >
                                    <FormControlLabel
                                        value="import"
                                        control={<CustomRadio />}
                                        label={
                                            <>
                                                <span style={{ color: '#0F172A', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px' }}>
                                                    Import Data from file
                                                </span>
                                            </>
                                        }
                                        data-testid="import-radio"
                                    />
                                    {this.state.selectedOption === "import" &&
                                        <>
                                            <input
                                                type="file"
                                                accept=".csv"
                                                ref={this.fileInputRef}
                                                style={{ display: 'none' }}
                                                onChange={this.handleFileChange}
                                                data-testid={"inputrefdocument"}
                                            />
                                            <Button className={classes.downloadBtn} onClick={this.handleUpload}><AddCircleOutlineOutlinedIcon /> Add File</Button>
                                        </>}
                                    <FormControlLabel
                                        value="export"
                                        control={<CustomRadio />}
                                        label={
                                            <>
                                                <span style={{ color: '#0F172A', fontFamily: 'Inter', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, lineHeight: '24px' }}>
                                                    Export Data to your system
                                                </span>
                                            </>
                                        }
                                        data-testid="export-radio"
                                    />
                                    {this.state.selectedOption === "export" && <Button className={classes.downloadBtn} onClick={this.handleDownload}>Download file</Button>}
                                </CustomRadioGroup>
                            </Box>
                            <Box className={classes.ImportboxStyle}>
                                <Button
                                    variant="contained"
                                    className={classes.updateButtonlist}
                                    data-testid="updateButton"
                                    onClick={this.HandleNextSubmit}
                                >
                                    Next
                                </Button>

                                <Button
                                    variant="contained"
                                    className={classes.cancelButtonList}
                                    data-test-id="cancelButton"
                                    onClick={() => this.setState({ isOpen: false })}
                                >
                                    Cancel
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Dialog>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export const DescTypo = styled(Typography)({
    display: "-webkit-box",
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitBoxOrient: 'vertical',
    WebkitLineClamp: 2,
})
export const userStyles = () => ({
    listinNameRowDesc:{
        textOverflow: "ellipsis",overflow:"hidden", wordBreak:"break-word" as "break-word"
    },
    formControl: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #006FFF",
        borderRadius: "5px",
        width: "87px",
        height: "50px",
        '& .MuiInputBase-root': {
            textAlign: 'center',
            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    borderBottom: 'unset',
                },
            },
        },
    },
    select: {
       width:"62%",
        '& .MuiSelect-root': {
            padding: '10px',
            lineHeight: '1.5',

        },
    },
    icon: {
        color: '#006FFF',height:"21px",width:"21px",
    },
    menuItem :{fontSize:"14px",lineHeight:"22px",fontWeight:400, fontFamily:"Poppins",color:"#0F172A"},
    withicon: {
        color: '#006FFF',
        display: 'block',
        '&.Mui-focused': {
            display: 'none',
        },
    },
    ListCompletedCell: {
        color: "#00874D",
        fontSize: "16px",
        fontWeight: 500
    },
    listingLogoRow: {
        // display: "flex",
        alignContent: "center" as const,
        justifyContent: "center" as const,
    },
    listImage: {
        width: "75px",
        height: "75px",
    },listImageNologo:{
        fontSize:"14px",
        fontWeight:400,
        fontFamily:"Poppins",
        color:"#000000"
    },
    ListPendingCell: {
        color: "#B08800",
        fontSize: "16px",
        fontWeight: 500
    },
    ListTableCell: {
        color: "#3B3B3B",
        fontSize: "16px",
        fontWeight: 500,
    },
    ListTableCell1: {
        borderBottom: 0,
        width: 10,
        padding: "16px 0"
    },
    ListTableCellBtn: {
        borderRadius: "8px",
        border: "1px solid #6200EA",
        background: "#FFF",
        textTransform: "capitalize" as const,
        color: "var(--primary-purple-600, #6200EA)",
        fontSize: "14px",
        fontWeight: 400
    },
    descriptionStyle:{
        fontFamily: 'Poppins, sans-serif',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '14px'
    },
   
    ListContainer: {
        background: "#F8F2FF",
    },
    downloadBtn: {
        cursor: "pointer",
        display: "inline-flex",
        alignItems: "center" as const,
        verticalAlign: "middle",
        height: "45px",
        padding: "0",
        width: "140px",
        borderRadius: "8px",
        background: "#B300ED",
        color: "#fff",
        justifyContent: "center",
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    ListTableContainer: {
        margin: "30px",
        width: "auto",
    },
    tabsBlock: {
        display: "flex",
        background: "#D9D9D9",
        borderRadius: 12,
        height: "3.87rem",
        width: "100%",
        marginTop: "20px",
        "@media(max-width: 480px)": {
            width: "90%",
            margin: "0 5%",
            marginTop: "0.8rem",
        }
    },
    tabItem: {
        display: "flex",
        width: "50%",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
        fontSize: 16,
        fontWeight: 700,
        margin: "auto 0",
        borderRadius: 12,
        cursor: "pointer",
        fontFamily:"Poppins",
        lineHeight:"24px",
    },
    ListTitleStyle: {
        color: "#262424",
        fontSize: "24px",
        fontWeight: 500,
        margin: "30px"
    },
    ListTableHeader: {
        "& th:first-child": {
            borderTopLeftRadius: "16px",
            borderBottomLeftRadius: "16px",
        },
        "& th:last-child": {
            borderTopRightRadius: "16px",
            borderBottomRightRadius: "16px",
            paddingRight:15
        },
        "& th": {
            backgroundColor: "#B300ED",
            color: "#fff",
            fontSize: "18px",
            fontWeight: 500,
        },
    },
    listlogoHead: {
        borderBottom: 0,
        width: "20%",
        padding: "16px 0"
    },
    fontSiveStyle: {
        fontFamily: "'Poppins', sans-serif",
        fontWeight: 500,
        fontSize: '18px',
        lineHeight: '14px'
    },
    listingNameHead: {
        borderBottom: 0,
        padding: "16px",width: "25%",
    },
    DateHeaders: {
        borderBottom: 0,
        width: "16%",
        padding: "16px"
    },
    ActionsHeaders: {
        borderBottom: 0,
        width: "20%",
        padding: "16px"
    },
    ListTableStyle: {
        minWidth: 650,
        background: "#fff",
        borderRadius: "16px",
        margin: "0 auto",
    },
    viewListContainer: {
        background: "#F8F2FF",
        padding: 0,
    },
    addListBtn: {
        color: "#8833FF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "24px",
        display: "flex",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        background: "#FFF",
        textTransform:"capitalize" as "capitalize",
        whiteSpace:"nowrap" as "nowrap",
        borderRadius:"8px",
        height:"44px",
        width:"193px",
        '&:hover': {
            backgroundColor: "#B300ED",
            color: "#FFF",
        }
    },
    adddListIcon:{
      height:"24px",
      width:"24px",
      marginLeft:"5px"
    },
    titleListView: {
        color: "#262424",
        fontSize: "24px",
        fontWeight: 500,
        fontFamily: "Poppins",
        lineHeight:"14px",
    },
    listHeaderTitle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        "@media(max-width: 480px)": {
            padding: "0 1.8rem",
        }
    },
    viewListMain: {
        background: "#FFF",
        border: "1px solid #FFF",
        borderRadius: "16px",
        padding: 0
    },
    headerListTitle: {
        padding: 0,
        display: "flex",
        // justifyContent: "spaceBetween",

    },
    navBar: {
        display: 'flex',
        margin: '15px 0 0 0',
        width: "100%",
        background: "#fff",
        justifyContent: "space-between",
        borderRadius: "16px 16px 0 0",
        "@media(max-width: 480px)": {
            margin: "10px 3%",
            width: "94%",
        }
    },
    tabContainer: {
        padding: '5px 0px',
        '& button': {
            minWidth: '110px'
        },
        '& button span:first-child': {
            fontSize: '16px',
            fontWeight: 700,
            textTransform: 'capitalize',
            fontFamily: 'Poppins, sans-serif'
        },
        '& button.Mui-selected span:first-child': {
            fontWeight: '700'
        },
        "@media(max-width:540px)": {
            justifyContent: 'flex-start',
            overflow: 'scroll'
        },
        '& + span': {
            "@media(max-width:540px)": {
                display: 'none'
            },
        }
    },
    container: {
        maxHeight: 780,
        borderRadius: "0 0 16px 16px",
        paddingTop: "18px",
        boxShadow:"none",
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        "&::-webkit-scrollbar": {
            width: "0",
            display: "none",
        },
        "@media(max-width: 480px)": {
            width: "94%",
            margin: "0 3%",
            overflowX: 'auto'
        }
    },
    table: {
        borderRadius: "16px",
    },
    head: {
        height: "58px",
        flexShrink: 0,
        borderRadius: "16px",
        '& .MuiTableCell-head': {
            fontFamily: "Poppins",
            color: "#FFF",
            fontWeight: 500,
            lineHeight: "14px",
            fontSize: "18px",
        },
    },
    impBtn: {
        color: "#6200EA",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "22px",
        background: "none",
        boxShadow: "none",
        '&:hover': {
            backgroundColor: "#FFFFFF",
        },
        "@media(max-width: 480px)": {
            padding: 0,
            margin: 0,
            maxWidth: "88px",
            fontSize: "10px",
            lineHeight: "14px",
        }
    },
    dialogImport: {
        overflow: 'hidden',
        width: '500px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
    },
    ImportTopHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px'
    },
    ImportFontStyle: {
        fontSize: '22px',
        fontWeight: 600,
        color: '#000000',
        fontFamily: 'Poppins'
    },
    ImportcloseIcon: {
        height: '25px',
        width: '25px',
        cursor: 'pointer'
    },
    ImportboxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    updateButtonlist: {
        fontSize: '18px' as const,
        textTransform: 'initial' as const,
        fontWeight: 400,
        borderRadius: '8px',
        marginTop: '3vw',
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        minWidth: '44%',
        backgroundColor: '#B300ED',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },

    cancelButtonList: {
        fontSize: '18px' as const,
        color: '#B300ED',
        fontFamily: 'Poppins',
        marginTop: '3vw',
        backgroundColor: '#FFFFFF',
        fontWeight: 400,
        minWidth: '44%',
        border: '1px solid #B300ED',
        borderRadius: '8px',
        textTransform: 'initial' as const,
        '&:hover': {
            backgroundColor: "#FFFFFF",
        }
    },
});

export const CustomFormControl = withStyles({
    root: {
        width: "85px",
        border: "1px solid #006FFF",
        height: "40px",
        alignItems: "center",
        borderRadius: "8px",
        margin: "0",
        padding: "0",
        flexDirection: "unset",
        position: "relative",
        alignContent: "center" as const,
        justifyContent: "center",
        "& .MuiInputLabel-formControl": {
            transform: "unset",
            width: "90%",
            display: "flex",
            alignContent: "center" as const,
            left: "5%",
            color: "#006FFF",
            top: "unset"
        },
        "& .label + .MuiInput-formControl": {
            margin: "unset"
        },
        "& .MuiInput-underline::before": {
            borderBottom: "unset",
        },
        "& .MuiInput-underline::after": {
            borderBottom: "unset",
        },
        "& .MuiInput-underline:hover:not(.Mui-disabled)::before": {
            borderBottom: "unset",
        }
    },

    selectLabel: {
        position: "unset",
        color: '#006FFF',
        display: 'block',
        '&.Mui-focused': {
            display: 'none',
        },
    }
})((props: any) => <FormControl {...props} />);
const CustomRadioGroup = withStyles({
    root: {
    },
})(RadioGroup);
const CustomRadio = withStyles((theme) => ({
    root: {
        color: "#64748B",
        '&$checked': {
            color: "#83F",
        },
    },
    checked: {},
}))((props) => <Radio color="default" {...props} />);
// Customizable Area End

export default withStyles(userStyles)(ListVendor);