Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.patchAPIMethod = "PATCH";
exports.vendorUpadteAPIEndpoint = "/bx_block_catalogue/catalogues";
exports.catlougeListAPIEndPoint = "/bx_block_catalogue/events_list"
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "customform4";
exports.labelBodyText = "customform4 Body";

exports.updateCustomForm = "/bx_block_catalogue/catalogues"

exports.btnExampleTitle = "CLICK ME";
exports.patchApiMethod = "PATCH";
exports.getApiMethod = "GET";

exports.vendorText = "Vendor";
exports.enterVendorName = "Enter the commercial Vendor name";
exports.placeHolder1 = "2TouchPOS";
exports.vendorurl = "Vendor URL";
exports.enterVendorUrl = "Enter Vendor URL";
exports.placeholder2 = "www.2touchpos.com";
exports.vendorCountry = "Vendor Country";
exports.enterVendorCountry = "Enter the country where the headquaters of company is located";
exports.placeholder3 = "United States";
exports.vendorFounded = "Vendor Founded";
exports.enterYearFounded = "The Year when the company was founded";
exports.placeholder4 = "1999";
exports.fromSameVendor = "From same Vendor";
exports.chooseListing = "Choose other Listings from the same vendor to show if there are more than one listings present";
exports.select = "Select";
exports.addMore = "Add more";
exports.servicesgroup = "Service groups";
exports.category = "Category";
exports.addServices = "Add service groups to the box below by clicking in the white area. You can select more than one service groups  at the same time. You can also type characters to reduce the list. Please select one or more categories first."

exports.backBtn = "Back";
exports.saveBtn = "Save & Publish";
exports.services = "Services";
exports.markets = "Markets";
exports.selectMarket = "Select Market";
exports.trainingUrl = "Training URL";
exports.enterUrl = "Enter url for training site or academy page URL";
exports.supportUrl = "Support URL";
exports.supportLinkUrl = "Link to support url for product";
exports.placeholder5 = "www.supoortdummy.com";
exports.languages = "Languages";
exports.enterlanguage = "Enter Langauges";
exports.placeholder6 = "English";
exports.support = "Support";
exports.training = "Training";
exports.screenshots = "Screenshots";
exports.addmanuals = "Add manuals, datasheets or other documents here";
exports.fileExt = "account_final.xlsx";
exports.sizeValue = "45kb";
exports.fileName = "File Name";
exports.size = "Size";
exports.maxSize = "Max file size 30 Mb";
exports.addFile = "Add File";
exports.integartions = "Integrations";
exports.enterNewIntegration = "Enter New integrations";
exports.deliveryManage = "Delivery Management";
exports.giftCardMang = "Gift Card Management";
exports.files = "Files";
exports.download = "Download";
exports.downloadCSV = "Click here to download CSV file template";
exports.selectCheckLable = "I would like to have my product catalogue show on my listing";
exports.productDescription = "Upload your catalogue here to enhance product discoverability through search, ensuring your listings stand out for customers seeking specific products.";
exports.addFiles = "Add your existing catalogue in PDF format";
exports.link = "Link";
exports.linkIntegration = "Link to the integration page on you website";
exports.note = "Note :";
exports.description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.";
exports.description_2 = "Too many integrations to add? Reach out to us and we'll be happy to help!";
exports.contactus = "Contact Us";
exports.needHelp = "Are we missing something? Do you need help?";
exports.no = "No";
exports.yes = "Yes";
exports.videos = "Videos";
exports.youtube = "Youtube";
exports.vimeo = "Vimeo";
exports.areubrand = "Are you a Brand?";
exports.areudistributor = "Are you a Distributor?";
exports.whoareDistribtr = "Who are your Distributors?";
exports.listyourproduct = "Please List your brands";
exports.whichBrands = "Which Brands do you represent";
exports.video = "Video";
exports.videoUrl = "Select Video Source URL";
exports.videoId = "Enter video ID or URL";
exports.addVideo = "Add Video";
exports.events = "Events";
exports.addmorecategory = "Add more Category";
exports.event_2 = "Event";
exports.event2Url = "M1physical event";
exports.enterBoothNo = "Enter Booth Number";
exports.contacts = "Contact";
exports.address = "Address";
exports.facebookUrl = "Enter Facebook username or URL";
exports.instausername = "Enter Instagram username or URL. Prefix with “#” if hashtag";
exports.linkdlinLink = "LinkedIn URL";
exports.phoneNumber = "Phone Number";
exports.twitterUsername = "Enter Twitter username or URL";
exports.contactusname = "Enter URL for Contact Us";


exports.youtubeUsername = "Enter YouTube username or URL"
exports.catalogueAPiEndPoint = "/bx_block_catalogue/catalogues";
exports.congratulation = "Details Updated Successfully";
exports.contacturl = "Enter URL for Contact Us";
exports.backtoaccount = "Back To Account";
exports.contactus = "Please attach a list of your integration partners or a link to the integration section of your website.";
exports.contact = "Contact Us";
exports.send = "Send";
exports.feedback = "Tell Us How We Can Improve";
exports.shortDescription = "Short Description";
exports.longDescriptio = "Long Description";
exports.longDescriptionText = "Enter a long description of your company or product. It will be shown on your listing .";
exports.servicesUrl = "Services URL";
exports.domain = "Domain";
exports.serviceDescription = "Enter a url for the product page. There is a separate field for the Vendor website in the Vendor section below.";
exports.longDescription = "Enter a short description of your company or product. It will be shown in search results and lists. The short description should not be longer than 250 characters. There is a long description field in the Features section that will be shown in the detail page of your listing."
exports.restaurantTypes = "/bx_block_catalogue/restaurant_types";
exports.categories = "Categories"
exports.feedback = "Tell Us How We Can Improve"
exports.restaurantTypes = "/bx_block_catalogue/restaurant_types";
exports.categoryList = "/bx_block_catalogue/category_list";
exports.platforms = "/bx_block_catalogue/platforms";
exports.productGroups = "Product groups";
exports.addProductDes = "Add product groups to the box below by clicking in the white area. You can select more than one product group at the same time. You can also type characters to reduce the list. Please select one or more categories first."
exports.feedback = "Tell Us How We Can Improve"
exports.restaurantTypes = "/bx_block_catalogue/restaurant_types";
exports.categoryList = "/bx_block_catalogue/category_list";
exports.platforms = "/bx_block_catalogue/platforms";
exports.dataOwnership = "/bx_block_catalogue/data_ownerships";
exports.targetusers = "/bx_block_catalogue/target_users";
exports.integrationapi = "/bx_block_catalogue/integration_list"
exports.typeofBusiness = "account_block/type_of_business";
exports.postReviewEndPoint = "bx_block_catalogue/reviews";
exports.feedbackAPIEndPoint = "/bx_block_catalogue/tell_us";
exports.bookmarkEndPoint = "bx_block_content_management/bookmarks"
exports.getEvent = "bx_block_catalogue/events"
exports.postReviewEndPoint = "bx_block_catalogue/reviews"
exports.bookmarkEndPoint = "bx_block_content_management/bookmarks";
exports.getEvent = "bx_block_catalogue/events";
exports.getbrandList = "/bx_block_catalogue/integration_list";
exports.feedbackAPI="bx_block_content_management/feedbacks";
exports.getCountries = "bx_block_categories/get_countries";
exports.contactusapi = "bx_block_catalogue/contact_us";
exports.content_goodcompany = "bx_block_catalogue/content_managments";






exports.allCategoriesEndPoint = "bx_block_categories/categories"
exports.reviewUpdateEndPoint="bx_block_catalogue/update_review"
exports.putAPIMethod="PUT"






exports.httpDeleteMethod = "DELETE";
exports.bookmarkDeleteEndpoint="bx_block_content_management/bookmarks/"





exports.attachmentDeleteEndpoint="bx_block_catalogue/destroy_catalogue_image"




exports.getSearchResultEndPoint="/bx_block_catalogue/search_listing"

exports.backText="Back"
exports.goBackText="Save all changes before proceeding further."
exports.getIntegrationsCategoriesAPIEndpoint="/bx_block_categories/categories_with_integrations"


exports.videoDeleteApiEndPoint="bx_block_catalogue/delete_catalogue_videos"

exports.categoryDeleteEndpoint="bx_block_catalogue/delete_category_in_catalogue"
// Customizable Area End