import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Checkbox,
    Button,
    OutlinedInput,
    Dialog,
    Grid,
    Theme,
    Backdrop,
    CircularProgress,
    TextField,
    TextFieldProps,
    Chip,
} from "@material-ui/core";
import clsx from 'clsx';
import { withStyles } from "@material-ui/core/styles";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Customizable Area End

// Customizable Area Start
import ServicesPageController, {
    Props,
    configJSON,
} from "./ServicesPageController";
import { addIcon, deleteIcon, CloseICon, deleteIcon_1 } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";

// Customizable Area End

export class ServicesPage extends ServicesPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.boxWrapperStyle}>
                <Backdrop className={classes.loaderStyle} open={this.state.isLoadData}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography className={classes.vendorTextStyle}>{configJSON.services}</Typography>
                <Box className={classes.textFeildBoxStyle}>

                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <Box className={classes.contentBoxStyle}>
                                <Typography className={classes.fontStyle1}>{configJSON.supportUrl}</Typography>
                                <Typography className={classes.fontStyle2}>{configJSON.supportLinkUrl}</Typography>
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={this.state.updatedServiceData?.supporturl}
                                    onChange={this.handleChangeSuopportUrl}
                                    data-testid={'change_support_url'}
                                    inputProps={{
                                        'aria-label': 'address',

                                        className: classes.placeholder,
                                    }}
                                    classes={{
                                        root: classes.outlinedInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.inputText
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={classes.contentBoxStyle}>
                                <Typography className={classes.fontStyle1}>{configJSON.languages}</Typography>
                                <Typography className={classes.fontStyle2}>{configJSON.enterlanguage}</Typography>

                                <Autocomplete
                                    multiple
                                    id="tags-filled"
                                    options={this.state.userRole}
                                    getOptionLabel={(option: any) => option.name}
                                    data-testid={"change_language"}
                                    value={this.state.selectedLanguage}
                                    disableClearable={true}
                                    onChange={(event: any, newValue: any) => this.onAutoCompleteChange(event, newValue)}
                                    className={classes.autocomplete}
                                    renderOption={(option: { name: string | number | boolean | {} | React.ReactElement<any, string | React.JSXElementConstructor<any>> | React.ReactNodeArray | React.ReactPortal | null | undefined; }, { selected }: any) => (
                                        <React.Fragment>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon />}
                                                checkedIcon={<CheckBoxIcon />}
                                                style={{
                                                    color: selected ? "#B300ED" : '#64748B',
                                                    marginRight: 8
                                                }}
                                                checked={selected}
                                            />
                                            {option.name}
                                        </React.Fragment>
                                    )}
                                    renderTags={(value: any[], getTagProps: (arg0: { index: number; }) => any) =>
                                        value.map((option: any, index: number) => (
                                            <Chip variant="outlined" label={option.name} {...getTagProps({ index })} className={classes.chip}
                                                style={{ fontSize: '14px', fontFamily: "Poppins" }}
                                                deleteIcon={<img src={deleteIcon_1} style={{ height: '20px', width: '20px' }} />}
                                            />
                                        ))
                                    }
                                    renderInput={(params: JSX.IntrinsicAttributes & TextFieldProps) => (
                                        <TextField
                                            {...params}
                                            variant="standard"
                                            placeholder={this.state.selectedLanguage.length > 0 ? "" : " Select Languages"}

                                            InputProps={{ ...params.InputProps, disableUnderline: true }}
                                        />
                                    )}
                                />
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box className={classes.contentBoxStyle}>
                                <Typography className={classes.fontStyle1} data-testid={"training_name"}>{configJSON.trainingUrl}</Typography>
                                <Typography className={classes.fontStyle2}>{configJSON.enterUrl}</Typography>
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    value={this.state.updatedServiceData?.trainingurl}
                                    onChange={this.handleChangeTrainingUrl}
                                    data-testid={'change_training_url'}
                                    inputProps={{
                                        'aria-label': 'address',

                                        className: classes.placeholder,
                                    }}
                                    classes={{
                                        root: classes.outlinedInput,
                                        focused: classes.focused,
                                        notchedOutline: classes.notchedOutline,
                                        input: classes.inputText
                                    }}
                                />
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container spacing={4}>
                        <Grid item md={6}>
                            <Box>
                                <Typography className={classes.supportText}>{configJSON.support}</Typography>
                                <Box className={classes.checkboxContainer}>
                                    {this.state.supportCheckbox.checkboxes.map((checkbox) => (
                                        <Box key={checkbox.id} className={classes.checkboxWrapper}>
                                            <Checkbox
                                                checked={checkbox.active}
                                                onChange={this.handleSupportCheckboxChange(checkbox.id)}
                                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                icon={<span className={classes.icon} />}
                                                inputProps={{ 'aria-label': 'decorative checkbox' }}
                                                data-testid={"checkbox"}
                                            />
                                            <label className={classes.lebelTextStyle}>{checkbox.support_type}</label>
                                        </Box>
                                    ))}
                                </Box>
                            </Box></Grid>
                        <Grid item md={6}>
                            <Box>
                                <Typography className={classes.supportText}>{configJSON.training}</Typography>
                                <Box className={classes.checkboxContainer}>
                                    {this.state.trainingChecbox.checkboxes.map((checkbox) => (
                                        <Box key={checkbox.id} className={classes.checkboxWrapper}>
                                            <Checkbox
                                                checked={checkbox.active}
                                                onChange={this.handleTrainingCheckboxChange(checkbox.id)}
                                                checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
                                                icon={<span className={classes.icon} />}
                                                inputProps={{ 'aria-label': 'decorative checkbox' }}
                                                data-testid={"trainingCheckbox"}
                                            />
                                            <label className={classes.lebelTextStyle}>{checkbox.training_type}</label>
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>

                    </Grid>

                </Box>

                <Box className={classes.boxWidth_2}>
                    <Box className={classes.contentBoxStyle}>
                        <Typography className={classes.screenShotText}>{configJSON.screenshots}</Typography>
                        <Typography className={classes.fontStyle2}>{configJSON.addmanuals}</Typography>
                        <Grid container className={classes.screenshotheader}>
                            <Grid item xs={6} md={6}><Typography>{configJSON.fileName}</Typography></Grid>
                            <Grid item xs={5} md={5}><Typography>{configJSON.size}</Typography></Grid>
                        </Grid>
                        <Grid container className={classes.screenshotBody} >

                            {this.state.serviceScreenShotArray.map((item) => {
                                return (
                                    <>
                                        <Grid item xs={6} md={6} className={classes.screenshotitem}><Typography>{decodeURIComponent(item.name)}</Typography></Grid>
                                        <Grid item xs={5} md={5}><Typography>{item.id ? item.size : this.formatFileSizeServiceFile(item.size)}</Typography></Grid>
                                        <Grid item xs={1} md={1} data-testid={"deletescreenshot"} className={classes.cancelIcon} onClick={() => this.removeScreensHot(item)}><img src={deleteIcon} alt="deleteicon" /></Grid>
                                    </>
                                )
                            })}
                        </Grid>
                    </Box>
                </Box>


                <Box className={classes.boxWidth_3}>
                    <Typography className={classes.fontStyle_1}>{configJSON.maxSize}</Typography>

                    <Box style={{ display: "flex", alignItems: "center" }}>
                        <label htmlFor="upload-screenshot" className={classes.addMoreBtn}><img src={addIcon} alt="AddIConWhite" /><Typography className={classes.BtnTextStyle}>{configJSON.addFile}</Typography></label>
                        <input
                            type="file"
                            style={{ display: "none" }}
                            accept="image/*"
                            onChange={this.onScreenshotUpload}
                            data-testid={"inputref"}
                            id="upload-screenshot"
                        />
                    </Box>
                </Box>


                <Box className={classes.saveBtn_Wrapper}>
                    <Box className={classes.buttonBox}>
                        <Typography className={classes.fontStyle5}>{configJSON.needHelp}</Typography>
                        <Typography className={classes.fontStyle4}>{configJSON.no}</Typography>
                        <Typography className={classes.fontStyle4} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                    </Box>

                    <Box className={classes.buttonBoxStyle_4}>
                        <Button
                            variant="outlined"
                            className={classes.declineBtn_7}
                            onClick={this.goToVendorAccount}
                            data-testid="navigateToAccount"
                        >
                            {configJSON.backBtn}
                        </Button>

                        <Button
                            variant="contained"
                            className={classes.acceptBtn_7}
                            onClick={this.updateServiceListingData}
                            data-testid={"save_button"}
                        >
                            {configJSON.saveBtn}
                        </Button>
                    </Box>
                </Box>
                <CustomToast open={this.state.isSucess} onClose={this.handleCloseSuccessDialog} />


                <Dialog
                    open={this.state.error}
                    onClose={this.handleCloseErrorDialog}
                    PaperProps={{ style: { borderRadius: "8px 8px 32px 8px", width: "529px", height: "240px", background: "#FFF", boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)" } }}
                >
                    <Box className={classes.successMediaBox}>

                        <Typography className={classes.detailSuccess_Text}>Can't upload file greater than 30mb</Typography>
                        <Button variant="contained" className={classes.backToAccount_Button} onClick={this.handleCloseErrorDialog} >Back to Accounts</Button>
                    </Box>
                </Dialog>

                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackService} handleFeedbackEmailChange={this.handleFeedbackEmailChange} handleFeedbackMessageChange={this.handleFeedbackMessageChange} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    boxWrapperStyle: {
        margin: '33px 46px 50px 45px',
        border: '2px solid #C8C9CA',
        padding: '40px 5% 40px 5%',
        borderRadius: '6px',

        [theme.breakpoints.down('md')]: {
            margin: "0",
        },
    },

    textFeildBoxStyle: {
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '3%',
    },
    loaderStyle: {
        zIndex: 1200,
        color: '#fff',
    },
    outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    focused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    placeholder: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },
    vendorTextStyle: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },
    contentBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '8px'
    },

    contentBoxStyle_2: {
        display: 'flex',
        borderBottom: '1px solid #C8C9CA',
        marginTop: '10px'
    },

    contentBoxStyle_3: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 5px 0px 5px'
    },

    boxWidth: {
        width: '45%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '30px',

        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    boxWidth_3: {
        width: '45%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '5%',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    saveBtn_Wrapper: {
        marginTop: "59px",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "unset",
            gap: "20px",

        },
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
    },
    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer",
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',
        [theme.breakpoints.down('md')]: {
            width: '100%',
        }

    },
    boxWidth_2: {
        width: '80%',
        marginTop: '30px',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    fontStyle1: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    fontStyle_1: {
        fontSize: '14px',
        fontWeight: 300,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },
    fontStyle2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },
    fontStyle5: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    formcontrol: {
        width: "100%",
        borderRadius: '3px',
        backgroundColor: '#FAF5FF',
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
    },
    checkboxContainer: {
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        marginTop: '15px'
    },
    checkboxWrapper: {
        display: 'flex',
        alignItems: 'center',
    },

    addMoreBoxStyle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '30px',
        gap: '5px',
        cursor: 'pointer'
    },

    iconStyle: {
        height: '15px',
        width: '15px'
    },
    fontStyle3: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins'
    },
    supportText: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins',
        marginTop: '30px',
        marginLeft: '10px'
    },

    screenShotText: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins',
    },

    icon: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        border: '1px solid #64748B',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#B300ED',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },

    lebelTextStyle: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F172A',
        fontFamily: 'Poppins',
        lineHeight: '20px'
    },
    addMoreBtn: {
        display: "flex",
        backgroundColor: "#B300ED",
        padding: "10px 16px",
        borderRadius: '10px',
        cursor: "pointer",

        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    BtnTextStyle: {
        textTransform: 'initial' as const,
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        marginLeft: "8px",

    },
    acceptBtn_7: {
        height: "55px",
        width: "241px",
        fontSize: "16px",
        padding: "16px 71px",
        justifyContent: "center",
        lineHeight: "24px",
        gap: "8px",
        background: "#B300ED",
        color: "#FFF",
        fontFamily: "Poppins",
        fontWeight: 400,
        alignItems: "center",
        textTransform: "initial",
        borderRadius: "8px",
        textWrap: "nowrap",

        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    declineBtn_7: {
        fontSize: "18px",
        color: "#B300ED",
        textAlign: "center",
        padding: "2px 81px",
        fontFamily: "Poppins",
        fontWeight: 400,
        textTransform: "initial",
        borderRadius: "6px",
        border: "1px solid #B300ED",
        lineHeight: "50.956px",
        background: "#FFF",
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    buttonBoxStyle_4: {
        display: 'flex',
        gap: '20px',
        justifyContent: 'flex-end',
        marginTop: '3%'
    },

    dialogStyles_5: {
        overflow: 'hidden',
        justifyContent: 'center',
        width: '529px',
        borderRadius: '8px 8px 32px 8px',
        backgroundColor: ' #FFFFFF',
        display: 'flex',
        alignItems: 'center',
    },

    dialogBoxStyle_2: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        alignItems: 'center',
        gap: '20px',
        marginBottom: '40px',
        paddingTop: '30px'
    },

    rightTickIcon_2: {
        height: '65px',
        width: '65px',
    },

    buttonStyle_2: {
        fontSize: '16px' as const,
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#FFFFFF',
        backgroundColor: '#B300ED',
        textTransform: 'initial' as const,
        borderRadius: '8px',
        padding: '8px 32px 8px 32px',
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset"
        },
    },

    welcomeFontStyle_5: {
        fontSize: '24px',
        fontWeight: 700,
        fontFamily: 'Poppins',
        lineHeight: '32px',
        textAlign: 'center' as const,
        color: '#0F172A'
    },

    screenshotheader: {
        padding: "12px",
        color: "#475569",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "22px",
        borderBottom: "1px solid #CBD5E1",
    },

    screenshotBody: {
        color: "#262424",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "24px",
        padding: "12px 7px 12px 12px"
    },

    screenshotitem: {
        marginBottom: "12px",
    },
    chip: {
        margin: "0 3px",
        border: "none",
    },
    cancelIcon: {
        textAlign: "end" as const,
        cursor: 'pointer'
    },
    autocomplete: {
        backgroundColor: "#f6f0ff",
        padding: "11px 5px",
        borderRadius: '8px',
        border: "1px solid  #CBD5E1",
        marginBottom: "42px",

        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent",
        },

        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },
        '&:before': {
            borderBottom: "none",
            display: "none"
        },


        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
        },

        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },
        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",
        }

    },


    successMediaBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingTop: "20px",
        height: "100%",
    },

    detailSuccess_Text: {
        margin: "20px 0 51px",
        fontWeight: 700,
        fontSize: "24px",
        color: "#0F172A",
        lineHeight: "32px",
        textAlign: "center",
        letterSpacing: "-0.12px",
    },

    backToAccount_Button: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        gap: "8px",
        background: "#B300ED",
        color: "#FFF",
        borderRadius: "8px",
        lineHeight: "80.956px",
        fontSize: "18px",
        fontFamily: "Poppins",
        textTransform: "initial",
        '&:hover': {
            background: "#B300ED",
        },
    }

});

export default withStyles(userStyles)(ServicesPage)
// Customizable Area End