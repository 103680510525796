import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    FormControl,
    MenuItem,
    Select,
    Chip,
    IconButton,
    Button,
    Paper,
    Checkbox,
    Grid,
    TextField,
    Dialog,
    Theme,
    Backdrop,
    CircularProgress,
    Popper

} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import clsx from 'clsx';

// Customizable Area End

// Customizable Area Start
import SupplierSuppliersPageController, {
    Props,
    configJSON,
} from "./SupplierSuppliersPageController";
import { deleteIcon, CloseICon, chipDel } from "./assets";
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';


const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export const PaperStye = {
    width: "300px",
    marginTop: "20px",
    fontSize: '14px',
    fontWeight: 400,
    fontFamily: "Poppins",
    border: "1px solid #CBD5E1",
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06)"

}



// Customizable Area End
export class SupplierSuppliersPage extends SupplierSuppliersPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props

        // Customizable Area End
        return (
            // Customizable Area Start
            <Grid container spacing={3} className={classes.main_Container}>


                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Box className={`${classes.categoryBoxSupplier}`}>
                        <Typography className={classes.categoryTypo}>Categories</Typography>
                        {this.state.categoryList.map((item: any, index: number) => {
                            return (
                                <div key={index}>
                                    <Box className={classes.categorylabelContainerSupplier}>
                                        <Typography className={classes.categorylabelSupplier}>Category {index + 1}</Typography>
                                        <Button disableRipple className={classes.categoryRemoveBtnSupplier} onClick={() => this.removeCategory(index)} data-test-id={`removeCategory-${index + 1}`}><img src={deleteIcon} alt="deleteicon" /></Button>
                                    </Box>
                                    <FormControl style={{ width: "100%" }}>
                                        <Select
                                            displayEmpty
                                            labelId="demo-simple-select-label"
                                            aria-placeholder="select Category"
                                            id="demo-simple-select"
                                            inputProps={{ 'aria-label': 'Without label' }}
                                            key={index}
                                            disableUnderline
                                            data-test-id={`selectCategory-${index + 1}`}
                                            className={classes.categorySelectSuppliers}
                                            value={item.category.category_name}
                                            onChange={(event) => this.selectCategoryDynamicPage(event, index)}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: '300px', width: '250px',

                                                    }
                                                },
                                                getContentAnchorEl: null,
                                                anchorOrigin: {
                                                    vertical: "bottom",
                                                    horizontal: "left",
                                                },
                                                transformOrigin: {
                                                    vertical: "top",
                                                    horizontal: "left",
                                                },
                                            }}>
                                            <MenuItem disabled value={""}><span style={{ color: "#737373ad" }}>Select</span></MenuItem>
                                            {this.state.categoryListName.map((item) => {
                                                return <MenuItem key={item.category_id} value={item.category_name}>{item.category_name}</MenuItem>
                                            })}

                                        </Select>
                                    </FormControl>
                                    <Typography className={classes.featureTypoSupplier}>Feature</Typography>
                                    <Typography className={classes.paraSupplier}>Add features to the box below by clicking in the white area. You can select more than one feature at the same time. You can also type characters to reduce the list. Please select one or more categories first.</Typography>


                                    <Autocomplete
                                        multiple
                                        id="tags-filled"
                                        options={item.option}
                                        getOptionLabel={(option) => option.sub_category_name}
                                        data-test-id={`autocomplete-${index + 1}`}
                                        value={item.feature}
                                        disableClearable={true}


                                        onChange={(event, newValue) => this.onAutoCompleteChangeSupplierPage(event, newValue, index)}
                                        // freeSolo
                                        className={classes.autocompleteSupplier}
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    checkedIcon={<span className={clsx(classes.icon_style_2, classes.checkedIcon_style_2)} />}
                                                    icon={<span className={classes.icon_style_2} />}
                                                    style={{ color: selected ? "#B300ED" : '#64748B', marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                <span className={classes.fontStyle1}>{option.sub_category_name}</span>
                                            </React.Fragment>
                                        )}
                                        renderTags={(value: readonly string[], getTagProps) =>
                                            value.map((option: any, index: number) => (
                                                <Chip deleteIcon={<img src={chipDel} alt="deleteIcon" />} key={index} variant="outlined" label={option.sub_category_name} {...getTagProps({ index })} className={classes.chipSupplier} />
                                            ))
                                        }
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="standard"
                                                placeholder={!item.feature.length ? "Select Features" : ""}
                                                InputProps={{ ...params.InputProps, disableUnderline: true }}
                                            />
                                        )}

                                    />

                                </div>
                            )
                        })}

                        <Box className={classes.categoryBtnWrap}>
                            <Button className={classes.addCategoryBtn} onClick={this.addCategory} data-test-id="add-category"><AddCircleOutlineOutlinedIcon className={classes.adddListIcon} />Add more Category</Button>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} className={classes.buttonBoxMargin}>
                    <Grid item container >
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.feedBackTextStyle}>
                            <Typography className={classes.supplier_fontStyle_2}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.textFontStyle}>{configJSON.no}</Typography>
                            <Typography className={classes.textFontStyle} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.feedBackTextStyleButton}>



                            <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.navigateToVendorAccount} />
                            <CustomSaveButton label={configJSON.saveBtn} data-testid={"saveBtn"} handleClick={this.handleSubmitSupplierPage} />
                        </Grid>
                    </Grid>
                </Grid>




                <CustomToast open={this.state.updatedSuccessDialog} onClose={this.handleCloseSuccessDialog} />


                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackSupplier} handleFeedbackEmailChange={this.handleFeedbackEmailChangeSupplier} handleFeedbackMessageChange={this.handleFeedbackMessageChangeSupplier} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />
                <Backdrop className={classes.backdrop} open={this.state.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>


            </Grid >
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    main_Container: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },
    adddListIcon: {
        height: "24px",
        width: "24px",
        marginRight: "5px"
    },

    checkbox_Container: {
        marginTop: '20px',
        padding: '10px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '8px',
    },

    supplier_addMoreBtn: {
        backgroundColor: "#B300ED",
        width: '20%',
        borderRadius: '10px',
        '&:hover': {
            backgroundColor: "#B300ED",
        }
    },
    icon_style_2: {
        backgroundColor: '#f5f8fa',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        borderRadius: 6,
        width: 20,
        justifyContent: 'center',
        alignItems: 'center',
        border: '1px solid #8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        height: 20,
        display: 'flex',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',

        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },

    },
    checkedIcon_style_2: {
        backgroundColor: '#8833FF',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            width: 20,
            height: 20,
            display: 'block',
            content: '""',
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",

        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },
    supplier__mediaFont: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#FFFFFF',
        fontFamily: 'Poppins',
        textTransform: 'initial' as const,
    },
    font_1: {
        fontSize: '14px',
        fontFamily: "Poppins",
        fontWeight: 700,
        color: '#2D2E2F'
    },

    text_1: {
        fontSize: '14px',
        fontFamily: "Poppins",
        fontWeight: 600,
        color: '#2D2E2F'
    },
    text_2: {
        fontSize: '14px',
        fontFamily: "Poppins",
        fontWeight: 400,
        color: '#2D2E2F',
        lineHeight: '20px'
    },

    textFontStyle: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },

    font_2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },

    BoxFeildStyle: {
        padding: '10px',
        border: '2px solid #CBD5E1',
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
        display: 'flex',
        gap: '20px',
    },

    iconStyle: {
        height: '20px',
        width: '20px',
    },

    fontStyle1: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#000000',
        fontFamily: 'Poppins',
        background: 'none',
    },

    supplier_form_control: {
        width: "100%",
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
        '& .MuiOutlinedInput-root': {
            borderRadius: '8px',
        },
        '& .Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
    },

    supplier_iconStyle_1: {
        borderRadius: 6,
        width: 20,
        height: 20,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        border: '1px solid #64748B',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:hover ~ &': {
            backgroundColor: '#ebf1f5',
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    supplier_checked_Icon: {
        backgroundColor: '#B300ED',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        '&:before': {
            display: 'block',
            width: 16,
            height: 16,
            backgroundImage:
                "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
                " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
                "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
            content: '""',
        },
        'input:hover ~ &': {
            backgroundColor: '#B300ED',
        },
    },

    supplier_labelFont: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#0F172A',
        fontFamily: 'Poppins',
        lineHeight: '20px'
    },

    feedBackTextStyle: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '5%',
    },

    feedBackTextStyleButton: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        justifyContent: 'end',
        marginTop: '5%',
    },


    buttonBoxMargin: {
        marginTop: '10%',
        marginBottom: '5%'
    },

    supplier_fontStyle_2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    categoryBoxSupplier: {
        overflowX: "hidden",
        height: "400px",
        paddingRight: '30px',
        overflowY: "auto",
        [theme.breakpoints.down('md')]: {
            paddingRight: "0",
            borderBottom: "0"
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "#D6D6D6",
        },
        "&::-webkit-scrollbar-thumb:focus": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:active": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-corner": {
            backgroundColor: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: "#B300ED",
        },
        "&::-webkit-scrollbar-thumb": {
            minWidth: 14,
            background: "#B300ED",
            minHeight: 24,
            borderRadius: 6,
        },
        "&::-webkit-scrollbar": {
            padding: '30px',
            height: 6,
            width: 4,
            backgroundColor: "#D6D6D6",
        },

    },
    categoryTypo: {
        color: "#2D2E2F",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "20px",
        marginBottom: "24.3px"
    },
    categorylabelContainerSupplier: {
        display: "flex",
        alignItems: "center",
        marginBottom: "12px",
        justifyContent: "space-between",
    },
    backdrop: {
        zIndex: 1200,
        color: '#fff',
    },
    categorylabelSupplier: {
        fontFamily: "Poppins",
        color: "#2D2E2F",
        fontWeight: 600,
        lineHeight: "20px",
        fontSize: "14px",
    },
    categoryRemoveBtnSupplier: {
        cursor: "pointer",
        all: "unset",
    },
    categorySelectSuppliers: {
        fontFamily: "Poppins",
        backgroundColor: "#f6f0ff",
        color: "#0F172A",
        fontSize: "14px",
        borderRadius: "6px",
        lineHeight: "20px",
        padding: "10px 8px",
        width: "100%",
        fontWeight: 400,
        position: 'realtive',
        border: "1px solid  #CBD5E1",
        '& .MuiSelect-icon': {
            marginRight: "10px",
        },
        menuPaper: {
            maxHeight: '150px',
            width: '150px', // Match this width with the Select component's width
            marginTop: 0,    // Adjust margin to align properly
            marginBottom: 0, // Ensure no extra margin
        },
        menuList: {
            padding: 0, // Remove extra padding if needed
        }
        // '&:before': {
        //     borderBottom: "1px solid  #CBD5E1",
        // },
        // '&:after': {
        //     borderBottom: 'none',
        //     border: "1px solid  #CBD5E1",
        // },



    },
    featureTypoSupplier: {
        color: "#262424",
        fontFamily: "Poppins",
        lineHeight: "24px",
        fontSize: "14px",
        fontWeight: 600,
        marginTop: "28px",
        marginBottom: "2px",
    },
    paraSupplier: {
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        color: "#262424",
        lineHeight: "24px",
        marginBottom: "6px",

    },
    autocompleteSupplier: {
        padding: "6px 0",
        backgroundColor: "#f6f0ff",
        border: "1px solid  #CBD5E1",
        marginBottom: "42px",
        borderRadius: '8px',

        "& .MuiAutocomplete-inputFocused": {
            paddingLeft: "10px"
        },

        '& .MuiInputBase-fullWidth': {
            flexWrap: "wrap",
        },


        "& input::placeholder": {
            fontFamily: "Poppins",
            fontWeight: 400,
            paddingLeft: "10px",
            fontSize: "14",
        },
        '& .MuiAutocomplete-inputRoot-251[class*="MuiFilledInput-root"]': {
            padding: "0",
            background: "transparent"
        },


        '&:before': {
            borderBottom: "none",
            display: "none"
        },

        '& .MuiChip-deleteIcon-309': {
            color: "#2A0066",
        },


    },
    chipSupplier: {
        margin: "0 3px",
        border: "none",
        fontFamily: "Poppins"
    },
    categoryBtnWrap: {
        display: "flex",
        justifyContent: "end",
        marginTop: "39px",
    },

    addCategoryBtn: {
        height: "44px",
        padding: "10px 16px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "none",
        color: "white",
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: 400,
        textAlign: "center",
        lineHeight: "50.956px",
        "&:hover": {
            background: "#B300ED",
        }
    },
    successboxSupplierSupplier: {
        width: "529px",
        borderRadius: "8px 8px 32px 8px",
        background: "#FFF",
        height: "329px",
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
    },
    successMediaSupplier: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "20px",
        alignItems: "center",
        height: "100%",
    },
    detailSuccessTextSupplier: {
        textAlign: "center",
        color: "#0F172A",
        fontSize: "24px",
        letterSpacing: "-0.12px",
        lineHeight: "32px",
        margin: "20px 0 51px",
        fontWeight: 700,
    },
    backToAccountButtonSupplier: {
        display: "flex",
        color: "#FFF",
        height: "56px",
        gap: "8px",
        alignItems: "center",
        textTransform: "initial",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontSize: "18px",
        background: "#B300ED",
        width: "367px",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
    declineBtnGotoVendor: {
        fontFamily: "Poppins",
        padding: "2px 81px",
        fontWeight: 400,
        color: "#B300ED",
        textAlign: "center",
        lineHeight: "50.956px",
        borderRadius: "6px",
        textTransform: "initial",
        border: "1px solid #B300ED",
        fontSize: "18px",
        background: "#FFF",
        height: 55,
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
            padding: "0",
            lineHeight: "50.956px",

        },
    },
    saveBtn: {
        borderRadius: "8px",
        background: "#B300ED",
        padding: "15.5px 71px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        color: "#FFFFFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textTransform: "initial",
        textWrap: "nowrap",
        marginLeft: 15,
        height: "56px",
        width: "242px",

        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            width: "100%",
            padding: "0",
            lineHeight: "50.956px",


        },
    },

});

export default withStyles(userStyles)(SupplierSuppliersPage)
// Customizable Area End