import React from "react";


// Customizable Area Start
import {
    Typography,
    Button,
    Box,
    TextField,
    InputAdornment,
    Slider,
    Tooltip,
    Dialog,
    DialogContent,
    withStyles,
    Theme,
    Paper
} from "@material-ui/core";
import { closeModalIcon ,CheckIcon} from "./assets";
import SearchIcon from '@material-ui/icons/Search';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SurveyController, {
    Props
} from "./SurveyController.web";

export const configJSON = require("./config");

// Customizable Area End

// Customizable Area Start

const styles: any = (theme:Theme)=>({
    dialog: {
        "&::-webkit-scrollbar": {
            display: "none",
            width: "0",
        },
        "-ms-overflow-style": "none",
        "scrollbar-width": "none",
        padding: 0,
    },
    surveyContainer: {
        padding: "0 1.5625rem 3.125rem 5.125rem",
        fontFamily: "Poppins, sans-serif",
        [theme.breakpoints.down('md')]: {
            padding:"0 20px 20px"
        },
      
    },
    progressContainer: {
        display: "flex",
        alignItems: "center",
    },
    barGroup: {
        display: "flex",
        gap: "0.875rem",
        marginLeft: "auto",
    },
    closeIcon: {
        marginLeft: "auto",
        cursor: "pointer",
    },
    bar: {
        width: "4.6875rem",
        height: "0.5rem",
        top: "5.9375rem",
        borderRadius: "1.125rem",
        backgroundColor: "#B300ED",
        cursor: "pointer",
    },
    yourself: {
        marginTop: "4rem",
        fontSize: "2rem",
        lineHeight: "3rem",
        fontFamily: "Poppins, sans-serif",
        [theme.breakpoints.down('md')]: {
            marginTop: "2rem",
        },
    },
    location: {
        fontSize: "1.5rem",
        fontWeight: "400",
        lineHeight: "2.25rem",
        margin: "2.8125rem 0 0.65625rem",
        fontFamily: "Poppins, sans-serif",
    },
    searchLocation: {
        display: "flex",
        gap: "1.25rem",
    },
    fontFamily:{
        "& .MuiInputBase-root ":{
            fontFamily: "Poppins, sans-serif",
        }
    },
    textfield: {
        "& .MuiInputBase-root": {
            height: "3.75rem",
            fontFamily:"Poppins, sans-serif"
        },
        width: "34rem",
        backgroundColor: "#FAF5FF",
        borderRadius: "0.3125rem",
        border: "0.0625rem solid ##DDDDDD",
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    autocomplete: {
        "& .MuiAutocomplete-inputRoot[class*=MuiOutlinedInput-root][class*=MuiOutlinedInput-marginDense]": {
            paddingLeft: "14px"
        }
    },

    role: {
        fontFamily: "Poppins",
        fontSize: "1.5rem",
        fontWeight: "400",
        lineHeight: "2.25rem",
        margin: "1.625rem 0 1.1875rem",
    },
    roleButtonGroup: {
        display: "flex",
        gap: "1.125rem",
        flexWrap: "wrap",
        [theme.breakpoints.down("md")]:{
            gap:"1rem"
        }
    },
    roleButton: {
        width: "max-content",
        padding: "1.25rem 1.625rem",
        borderRadius: "0.5rem",
        backgroundColor: "#FAF5FF",
        cursor: "pointer",
    },
    roleButtonText: {
        textTransform: "capitalize",
        fontFamily: "Poppins",
        fontSize: "1.125rem",
        fontWeight: "400",
        lineHeight: "1.6875rem",
    },
    selected: {
        border:"1px solid #B300ED",
        color:"#B300ED"
    },
    otherSelected:{
        border:"1px solid #B300ED",
    },
    ctaButtons: {
        display: "flex",
        marginTop: "6rem",
        gap: "2.0625rem",
        [theme.breakpoints.down('md')]: {
            marginTop: "3rem",
        },
    },
    skip: {
        padding: "0.5625rem 0",
        width: "16.625rem",
        border: "0.0625rem solid #B300ED",
        color: "#B300ED",
        textTransform: "capitalize",
        fontFamily: "Poppins",
    },
    continue: {
        padding: "0.5625rem 0",
        width: "16.625rem",
        backgroundColor: "#B300ED",
        textTransform: "capitalize",
        color: "white",
        fontFamily: "Poppins",
        '&:hover': {
            backgroundColor:"#B300ED",
        }
    },
    count: {
        textAlign: "end",
        marginRight: "1.5625rem",
        [theme.breakpoints.down("md")]:{
            marginTop:"1rem"
        }
    },
    typehere: {
        "& .MuiInputBase-root": {
            height: "4.1875rem",
        },
        backgroundColor: "#FAF5FF",
        borderRadius: "0.3125rem",
        border: "0.0625rem solid ##DDDDDD",
    },
    businessItem: {
        display: "flex",
        gap: "1.125rem",
        flexWrap: "wrap",
        marginTop: "27px",
    },
    annualLabel: {
        marginBottom: "98px",
        fontFamily: "Poppins",
        fontSize: "1.5rem",
        fontWeight: "400",
        lineHeight: "2.25rem",
        margin: "1.625rem 0 1.1875rem",
    },
    slider: {
        color: '#B300ED',
        maxWidth: "45rem",
        display: "flex",
        alignItems: "center",
        '& .MuiSlider-track': {
            height: "8px",
            border: 'none',
            borderRadius: 0,
        },
        '& .MuiSlider-rail': {
            height: "8px",
            border: 'none',
            borderRadius: 0,
        },
        '& .MuiSlider-thumb': {
            height: "30px",
            width: "30px",
            backgroundColor: '#B300ED',
            border: '5px solid white',
            marginTop: "0",
            '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
                boxShadow: 'inherit',
            },
            '&:before': {
                display: 'none',
            },
        },
        '& .MuiSlider-valueLabel': {
            lineHeight: 1.2,
            fontSize: 12,
            background: 'unset',
            padding: 0,
            width: 32,
            height: 32,
        },
        '& .MuiSlider-markLabel': {
            top: "36px",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontWeight: "400",
            lineHeight: "21px",
            color: "black",
            [theme.breakpoints.down("md")]:{
                transform: 'translateX(-100%)'
            }
            

        },
    },
    tooltip: {
        backgroundColor: 'red', 
    },
    barInactive: {
        width: "4.6875rem",
        height: "0.5rem",
        top: "5.9375rem",
        borderRadius: "1.125rem",
        backgroundColor: "#D9D9D9",
        cursor: "pointer",
    },
    successboxFilesSurvey:{
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        borderRadius: "8px 8px 32px 8px",
        height: "329px", 
        width: "529px",
        background: "#FFF", 
        [theme.breakpoints.down('md')]: {
            width:"90%",
        },
    },
    successMediaDialogSurvey: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "20px",
        alignItems: "center",
        height: "100%",
    },

    detailSuccessTypoSurvey: {
        color: "#0F172A",
        letterSpacing: "-0.12px",
        textAlign: "center",
        fontWeight: 700,
        lineHeight: "32px",
        fontSize: "24px",
        margin: "20px 0 51px"
    },
    backToAccountBtnSurvey: {
        display: "flex",
        borderRadius: "8px",
        width: "367px",
        height: "56px",
        alignItems: "center",
        background: "#B300ED",
        textTransform: "initial",
        color: "#FFF",
        fontFamily: "Poppins",
        gap: "8px",
        lineHeight: "80.956px",
        fontSize: "18px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width:"unset",
       },
    },
})

const CustomTooltip = withStyles((theme) => ({

    tooltip: {
        overflow: "visible",
      backgroundColor: "#FAF5FF",
      color: 'black',
      width:"133px",
      height:"48px",
      display:"flex",
      justifyContent:"center",
      alignItems:"center",
      fontSize:"14px",
      [theme.breakpoints.down('md')]: {
        width:"80px",
        height:"50px",
      },

    },

    // transform: 'translate3d(86px, 565px, 0px)'

}))(Tooltip);


function ValueLabelComponent(props: any) {
    const { children, open, value } = props;

    return (
        <CustomTooltip 
        open={open} interactive enterTouchDelay={0} title={`$ ${ value.toLocaleString('en-IN')}`}  placement="top">
            {children}
        </CustomTooltip>
    );
}
// Customizable Area End


class Survey extends SurveyController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start

            <>
            <Dialog
                open={this.state.openSurvey}
                onClose={this.handleCloseSurveyModal}
                PaperProps={{ style: { minWidth: '80%' } }}
                className="surveyDialog"
            >

                <DialogContent className={classes.dialog} >
                    <Box className={classes.surveyContainer}>
                        <Box className={classes.progressContainer}>

                            <Box className={classes.barGroup}>
                                <div className={classes.bar} data-test-id="skipButton" onClick={() => this.toggleTab(1)}></div>
                                <div className={this.state.tab == 2 ? classes.bar : classes.barInactive} data-test-id="continueButton" onClick={() => this.toggleTab(2)}></div>
                            </Box>
                            <img data-test-id="modalCloseIconBtn" onClick={this.handleCloseSurveyModal} src={closeModalIcon} alt="close" width={28} height={28} className={classes.closeIcon} />

                        </Box>
                        {this.state.tab == 1 &&
                            <Box data-test-id={"firstBlock"}>
                                <Typography className={classes.yourself} variant="h4">Tell us a little about yourself...</Typography>
                                <Typography className={classes.location}>Home Location</Typography>
                                <Box className={classes.searchLocation}>

                                    <Autocomplete
                                        data-test-id="country"
                                        className={classes.textfield}
                                        freeSolo
                                        options={this.state.countryOption}
                                        getOptionLabel={(options:any) => {
                                            return options["name"];
                                        }}
                                        onChange={(e:any, newValue:any) => this.handleCountry(newValue)}
                                        renderInput={(params:any) =>
                                            <TextField
                                                className={classes.autocomplete}
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                placeholder="Search Country"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />}
                                    />
                                    <Autocomplete
                                        className={classes.textfield}
                                        freeSolo
                                        options={this.state.cityOption}
                                        getOptionLabel={(options:any) => {
                                            return options["name"];
                                        }}
                                        onChange={(e:any, newValue:any) => this.handleCity(newValue)}
                                        data-test-id={"cityAutocomplete"}
                                        renderInput={(params:any) =>
                                            <TextField
                                                className={classes.autocomplete}
                                                {...params}
                                                variant="outlined"
                                                size="small"
                                                placeholder="Search City"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    startAdornment: (
                                                        <InputAdornment position="start">
                                                            <SearchIcon />
                                                        </InputAdornment>
                                                    ),
                                                }} />}
                                    />

                                </Box>

                                <Typography className={classes.role}>Role Level</Typography>
                                <Box className={classes.roleButtonGroup} >
                                    {this.state.roleOption?.map((item, index) => {
                                        return <Button key={index} className={`${classes.roleButton} ${this.state.role == item.id && classes.selected }`} data-test-id={`roleButton-${index}`} onClick={() => this.selectRoleLevel(item.id)}><Typography className={classes.roleButtonText}>{item.name}</Typography></Button>
                                    })}
                                </Box>

                                <Typography className={classes.role}>Department</Typography>
                                <Box className={classes.roleButtonGroup}>
                                    {this.state.departmentOption?.map((item, index) => {
                                        return <Button key={index} className={`${classes.roleButton} ${this.state.department == item.id && classes.selected }`} data-test-id={`departmentButton-${index}`} onClick={() => this.selectDepartment(item.id)}><Typography className={classes.roleButtonText}>{item.attributes.name}</Typography></Button>
                                    })}
                                    <Button className={`${classes.roleButton} ${this.state.otherDepartment && classes.otherSelected }`} data-test-id="toggleOther" onClick={this.toggleOther}><Typography className={classes.roleButtonText}>Other</Typography></Button>

                                    {this.state.otherDepartment &&
                                        <TextField
                                            data-test-id="otherdepartmentid"
                                            id="otherTextDepartment"
                                            value={this.state.otherTextDepartment}
                                            onChange={(e) => this.handleChangeDepartment(e.target.value)}
                                            onKeyPress={this.handleKeyPress}
                                            className={`${classes.typehere} ${classes.fontFamily}`}
                                            variant="outlined"
                                            size="small"
                                            placeholder="Type here" />
                                    }
                                </Box>

                                <Box className={classes.ctaButtons}>
                                    <Button variant="outlined" className={classes.skip} data-test-id="updateToggle" onClick={() => this.toggleTab(2)}>Skip</Button>
                                    <Button variant="contained" className={classes.continue} data-test-id="updateToggleTwo" onClick={() => this.toggleTab(2)}>Continue</Button>
                                </Box>

                                <Typography className={classes.count}>1/2</Typography>
                            </Box>
                        }
                        {this.state.tab == 2 &&

                            <Box>
                                <Typography className={classes.yourself} variant="h4">Tell us a little about where you work..</Typography>
                                <Typography className={classes.location}>Fill in the name of the company you work for</Typography>

                                <TextField
                                    data-test-id="companyName"
                                    value={this.state.companyName}
                                    onChange={(e) => this.handleCompanyName(e.target.value)}
                                    className={classes.textfield}
                                    variant="outlined"
                                    size="small"
                                    placeholder="Enter name of Company"
                                />
                                <Typography className={classes.role}>Type of Business</Typography>
                                <Box className={classes.roleButtonGroup}>
                                    {this.state.businessData?.map((item,index)=>{
                                        return <Button key={index} className={`${classes.roleButton} ${this.state.businessTab == item.id && classes.selected }`} data-test-id={`businessButton-${index}`} onClick={() => this.changeBusinessTab(item.id)}><Typography className={classes.roleButtonText}>{item.attributes.name}</Typography></Button>
                                    })}
                                </Box>

                                <Box className={classes.businessItem}>
                                    {this.state.businessDataSelected?.map((item,index)=>{
                                        return <Button key={index} className={`${classes.roleButton} ${this.state.selectedBusiness == +item.id && classes.selected }`} data-test-id={`restaurantItems-${index}`} onClick={() => this.selectBusiness(+item.id)}><Typography className={classes.roleButtonText}>{item.attributes.name}</Typography></Button>
                                    })}
                                    <Button className={`${classes.roleButton} ${this.state.otherBusiness && classes.otherSelected }`} onClick={this.toggleOtherBusiness} data-test-id="toggleOtherBusiness"><Typography className={classes.roleButtonText}>Other</Typography></Button>

                                    {this.state.otherBusiness &&
                                            <TextField
                                                data-test-id="otherbusinessid"
                                                id="otherTextBusiness"
                                                value={this.state.otherTextBusiness}
                                                onChange={(e) => this.handleChangeBusiness(e.target.value)}
                                                onKeyPress={this.handleKeyPressBusinessSubCategory}
                                                className={`${classes.typehere} ${classes.fontFamily}`}
                                                variant="outlined"
                                                size="small"
                                                placeholder="Type here" />
                                    }
                                </Box>

                                <Typography className={classes.role}>Number of Locations</Typography>
                                <Box className={classes.roleButtonGroup}>
                                    {["1", "2-10", "11-50", "50+"].map((item, index) => {
                                        return <Button key={index} className={`${classes.roleButton} ${this.state.noOfLocation == item && classes.selected }`} data-test-id={`location-${index}`} onClick={() => this.handleLocation(item)}><Typography className={classes.roleButtonText}>{item}</Typography></Button>
                                    })}
                                </Box>

                                <Typography className={classes.annualLabel}>Average annual revenue per property</Typography>
                                <Slider
                                    data-test-id="annualrange"
                                    className={classes.slider}
                                    aria-label="Always visible"
                                    min={0}
                                    max={20000000}
                                    step={2000}
                                    ValueLabelComponent={ValueLabelComponent}
                                    marks={[
                                        {
                                            value: 0,
                                            label: '$0',
                                        },
                                        {
                                            value: 20000000,
                                            label: '$20,000,000',
                                        },
                                    ]}
                                    value={this.state.annualRevenue}
                                    onChange={this.onChangeAnnualRevenue}
                                    valueLabelDisplay="on"

                                />

                                <Box className={classes.ctaButtons}>
                                    <Button variant="outlined" className={classes.skip} data-test-id="skipButtontwo" onClick={() => this.props.modalClose()}>Skip</Button>
                                    <Button variant="contained" className={classes.continue} data-test-id="formSubmit" onClick={this.updateSurveyForm} >Continue</Button>
                                </Box>

                                <Typography className={classes.count}>2/2</Typography>

                            </Box>
                        }
                    </Box>
                </DialogContent>
            </Dialog>

            <Dialog
                open={this.state.isSuccess}
                onClose={this.handleCloseSuccessDialog}
                data-test-id="backtoaccount"
                PaperComponent={props => (
                    <Paper {...props} className={classes.successboxFilesSurvey} />
                )}
            >
                <Box className={classes.successMediaDialogSurvey}>
                    <img src={CheckIcon} alt="checkicon" width={93} height={93} />
                    <Typography className={classes.detailSuccessTypoSurvey}>Registration Successful</Typography>
                    <Button variant="contained" className={classes.backToAccountBtnSurvey} onClick={this.goToHome} >Back to Login</Button>
                </Box>
            </Dialog>
            </>




        // Customizable Area End
        )
    }
}
export { Survey }
export default withStyles(styles)(Survey)