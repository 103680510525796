import React from "react";

// Customizable Area Start
import {
    Typography,
    OutlinedInput,
    Grid,
    Box,
    Button,
    Dialog,
    Theme,
    Paper,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import SupplierContactPageController, {
    Props,
    configJSON,
} from "./SupplierContactPageController";
import { rightTickIcon, CloseICon } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';
// Customizable Area End

export class SupplierContactPage extends SupplierContactPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Grid container className={classes.contact_maincontainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.contact_heading_1} data-testid={'contacts'}>{configJSON.contacts}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.contact_grid_item}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedContactData?.useraddress}
                                onChange={this.handleChangeAddressSupContact}
                                data-testid={'changeaddress'}
                                classes={{
                                    root: classes.contact_outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.contact_notchedOutline,
                                    input: classes.contact_inputText
                                }}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.address,
                                    className: classes.contact_placeholder,
                                }}
                            />
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedContactData?.userfburl}
                                onChange={this.handleChangeFbUrlSupContact}
                                data-testid={"change_fburl"}
                                inputProps={{
                                    'aria-label': 'address',
                                    className: classes.contact_placeholder,
                                    placeholder: configJSON.facebookUrl,
                                }}
                                classes={{
                                    root: classes.contact_outlinedInput,
                                    notchedOutline: classes.contact_notchedOutline,
                                    input: classes.contact_inputText,
                                    focused: classes.focused,
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedContactData?.userinstaurl}
                                onChange={this.handleChangeInstaUrlSupContact}
                                data-testid={"change_instaurl"}
                                inputProps={{
                                    'aria-label': 'address',
                                    className: classes.contact_placeholder,
                                    placeholder: configJSON.instausername,
                                }}
                                classes={{
                                    root: classes.contact_outlinedInput,
                                    notchedOutline: classes.contact_notchedOutline,
                                    input: classes.contact_inputText,
                                    focused: classes.focused,
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedContactData?.userlinkdlnurl}
                                onChange={this.handleChangeLinkdlnUrlSupContact}
                                data-testid={"change_lindlnurl"}
                                classes={{
                                    root: classes.contact_outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.contact_notchedOutline,
                                    input: classes.contact_inputText
                                }}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.linkdlinLink,
                                    className: classes.contact_placeholder,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.contact_grid_item}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                type="text"
                                value={this.state.updatedContactData?.userphonenumber}
                                onChange={this.handleChangePhoneNumberSupContact}
                                data-testid={"change-phno"}
                                inputProps={{
                                    placeholder: configJSON.phoneNumber,
                                    className: classes.contact_placeholder,
                                    'aria-label': 'address',
                                }}
                                classes={{
                                    root: classes.contact_outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.contact_notchedOutline,
                                    input: classes.contact_inputText
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedContactData?.usertwitterurl}
                                onChange={this.handleChangeTwitterUrlSupContact}
                                data-testid={"change_twturl"}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.twitterUsername,
                                    className: classes.contact_placeholder,
                                }}
                                classes={{
                                    input: classes.contact_inputText,
                                    root: classes.contact_outlinedInput,
                                    focused: classes.focused,
                                    notchedOutline: classes.contact_notchedOutline,
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedContactData?.usercontactus}
                                onChange={this.handleChangeContactUsSupContact}
                                data-testid={"change_contacturl"}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.contacturl,
                                    className: classes.contact_placeholder,
                                }}
                                classes={{
                                    root: classes.contact_outlinedInput,
                                    notchedOutline: classes.contact_notchedOutline,
                                    focused: classes.focused,
                                    input: classes.contact_inputText
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                value={this.state.updatedContactData?.useryoutubeurl}
                                onChange={this.handleChangeYouTubeUrlSupContact}
                                data-testid={"change_utuburl"}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.youtubeUsername,
                                    className: classes.contact_placeholder,
                                }}
                                classes={{
                                    root: classes.contact_outlinedInput,
                                    notchedOutline: classes.contact_notchedOutline,
                                    focused: classes.focused,
                                    input: classes.contact_inputText
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container >
                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.contact_feedBackcontainer}>
                            <Typography className={classes.contact_fontStyle2}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.contact_font_5}>{configJSON.no}</Typography>
                            <Typography className={classes.contact_font_5} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} className={classes.contact_feedBackcontainer}>


                            <CustomBackButton data-testid="navigateToAccount" label={configJSON.backBtn} handleClick={this.goBack} />
                            <CustomSaveButton label={configJSON.saveBtn} data-testid={"save_button"} handleClick={this.updateContactListDataSupContact} />

                            {/* </Box> */}
                        </Grid>

                    </Grid>
                </Grid>

                <Dialog
                    open={this.state.isSucess}
                    onClose={this.handleCloseSuccessDialog}
                    data-test-id={"backtoaccount"}
                    PaperComponent={props => (
                        <Paper {...props} className={classes.successboxSupplierContact} />
                    )}

                >
                    <Box className={classes.dialogBoxStyle_3SupplierContact}>
                        <img
                            src={rightTickIcon}
                            className={classes.rightTickIcon_3SupplierContact}
                            width={93} height={93}
                        />
                        <Typography className={classes.welcomeFontStyle_3SupplierContact}>{configJSON.congratulation}</Typography>
                        <Button
                            variant="contained"
                            fullWidth
                            className={classes.buttonStyle_3SupplierContact}
                            onClick={this.goToVendorAccount}
                        >
                            {configJSON.backtoaccount}
                        </Button>

                    </Box>
                </Dialog>

                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackContact} handleFeedbackEmailChange={this.handleFeedbackEmailChangeContact} handleFeedbackMessageChange={this.handleFeedbackMessageChangeContact} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Grid >
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    contact_maincontainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },

    contact_grid_item: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '25px'
    },

    contact_font_5: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer"
    },

    contact_feedBackcontainer: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '15%',
        marginBottom: '15%'
    },

    contact_fontStyle2: {
        fontSize: '14px',
        color: '#2D2E2F',
        fontWeight: 500,
        fontFamily: 'Poppins'
    },

    contact_heading_1: {
        fontSize: '20px',
        color: '#2D2E2F',
        fontWeight: 700,
        fontFamily: 'Poppins'
    },

    contact_outlinedInput: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
    },
    focused: {},
    contact_notchedOutline: {
        borderWidth: '1px',
    },
    contact_placeholder: {
        fontWeight: 400,
        fontSize: "14px",
        fontFamily: "Poppins",
        borderRadius: '8px',
    },

    contact_inputText: {
        fontWeight: 400,
        fontSize: "16px",
        fontFamily: "Poppins",
        color: '#000000',
        borderRadius: '8px',
    },

    declineBtn_8_SupplierContact: {
        fontFamily: "Poppins",
        padding: "2px 81px",
        fontWeight: 400,
        color: "#B300ED",
        textAlign: "center",
        lineHeight: "50.956px",
        borderRadius: "6px",
        textTransform: "initial",
        border: "1px solid #B300ED",
        fontSize: "18px",
        background: "#FFF",
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },
    acceptBtn_8_SupplierContact: {
        padding: "15.5px 71px",
        background: "#B300ED",
        gap: "8px",
        color: "#FFF",
        borderRadius: "8px",
        alignItems: "center",
        justifyContent: "center",
        height: "55px",
        lineHeight: "24px",
        fontWeight: 400,
        fontFamily: "Poppins",
        fontSize: "16px",
        textTransform: "initial",
        marginLeft: "15px",
        textWrap: "nowrap",
        width: "241px",


        '&:hover': {
            backgroundColor: "#B300ED",
        },

        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
            lineHeight: "50.956px",

        },
    },
    successboxSupplierContact: {
        width: "529px",
        background: "#FFF",
        boxShadow: "px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
        height: "329px",
        borderRadius: "8px 8px 32px 8px",
        [theme.breakpoints.down('md')]: {
            width: "90%",
        },
    },

    dialogBoxStyle_3SupplierContact: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        height: "100%",
        paddingTop: '20px',

    },

    rightTickIcon_3SupplierContact: {
        width: '65px',
        height: '65px',
    },

    buttonStyle_3SupplierContact: {
        display: "flex",
        width: "367px",
        height: "56px",
        alignItems: "center",
        gap: "8px",
        borderRadius: "8px",
        background: "#B300ED",
        textTransform: "initial",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "18px",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
    },

    welcomeFontStyle_3SupplierContact: {
        color: "#0F172A",
        textAlign: "center",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "32px",
        letterSpacing: "-0.12px",
        margin: "20px 0 51px"
    },
});

export default withStyles(userStyles)(SupplierContactPage)
// Customizable Area End