import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
import { ChangeEvent } from "react";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  value: any;
  filter: string;
  viewMode: boolean;
  country: string;
  countryShow: boolean;
  region: string;
  regionShow: boolean;
  category: boolean;
  feature: string;
  featureShow: boolean;
  view: number;
  categoryList: any;
  categoryShow: boolean;
  selectedCategoryList: any;
  catalogueList: any;
  countryList: any;
  countryId: any;
  regionList: any;
  regionId: number;
  selectedSubCategoryList?: any;
  isLoading: boolean;
  anchorEl: any;
  start_point: any;
  end_point: any;
  current_page: any;
  recordCount: number;
  total_pages: any;
  total_count: any,
  userdetails: any,
  sort_by: string,
  selectedSupplierType: any,
  subCategoriesShow: boolean,
  selectedIntegrations: any;
  selectedPlatforms: any;
  supports: any,
  trainings: any,
  selectedTrainings: any,
  selectedGoodCompany: any,
  selectedDataOwner: any,
  selectedSupports: any,
  selectedLanguages: any,
  showSoftwareFilter: boolean,
  data_ownership: any,
  languages: any,
  in_good_companies: any,
  search: string,
  selectedMonth: any,
  date_range: any,
  selectedEventsType: any,
  integrations: any,
  platform: any,
  start_date: any,
  end_date: any,
  showMonthCalendar: boolean,
  countryAnchor: any,
  monthAnchor: any,
  dateAnchor: any,
  defaultDate: string,
  regionAnchor: any,
  searchIntegrations:string,
  loadMore:boolean,
  showSearchResult:boolean,
  categoriesResult:any,
  productGroupResult:any,
  listingResult:any,
  productGroup_count:number,
  listing_count:number,
  categories_count:number,
  showDirectoryPopup:boolean,
  searchTermProp:string,
  searchDirectoryData:any,
  searchProp:string,
  searchSubCategories:string
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  // Customizable Area Start
  getCatalogueApiCallId: any;
  getCategoryApiCallId: any;
  getCountryApiCallId: any;
  getRegionApiCallId: any;
  getFilterListApiCallId: any;
  advancedsearchDataApiCallId:string="";
  getSearchResultDataReqId:string="";
  getCategoriesListApiId:string="";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      value: "",
      filter: '',
      viewMode: true,
      country: 'Region',
      countryList: [],
      countryShow: false,
      region: 'Country',
      regionList: [],
      regionShow: false,
      category: false,
      categoryShow: false,
      categoryList: [],
      selectedCategoryList: [],
      feature: 'Recent',
      catalogueList: [],
      subCategoriesShow: false,
      featureShow: false,
      view: 1,
      countryId: '',
      regionId: 0,
      selectedSubCategoryList: [],
      isLoading: false,
      anchorEl: null,
      start_point: 0,
      end_point: 0,
      current_page: 1,
      recordCount: 10,
      total_pages: 5,
      total_count: 0,
      userdetails: {},
      sort_by: "",
      selectedSupplierType: [],
      selectedIntegrations: [],
      selectedPlatforms: [],
      supports: [],
      trainings: [],
      selectedTrainings: [],
      selectedGoodCompany: [],
      selectedDataOwner: [],
      selectedSupports: [],
      selectedLanguages: [],
      showSoftwareFilter: false,
      data_ownership: [],
      in_good_companies: [],
      languages: [],
      search: "",
      selectedMonth: "Select Month",
      date_range: "",
      selectedEventsType: [],
      integrations: [],
      platform: [],
      start_date: "",
      end_date: "",
      showMonthCalendar: false,
      countryAnchor: null,
      monthAnchor: null,
      dateAnchor: null,
      defaultDate: "Select Date Range",
      regionAnchor: null,
      searchIntegrations:"",
      loadMore:false,
      showSearchResult:false,
      categoriesResult:[],
      productGroupResult:[{id: 153, name: "Food Festival", directory: "Events", sub_categories: null, count: 1}],
      listingResult:[{
        "id": 5109,
        "name": "Abacus Business Computer LLC",
        "short_description": "Use ABCPOS cloud system to provide you with an unforgettable dining management experience.",
        "directory": "Suppliers",
        "payment_status": "success",
        "logo": null
    },
    {
        "id": 5110,
        "name": "Abena North America, Inc.",
        "short_description": "The ABENA Group manufactures and sells high quality incontinence products for the health care sector as well as disposable products for other industries.",
        "directory": "Suppliers",
        "payment_status": "success",
        "logo": "https://restaurantresourcefinal-367637-ruby.b367637.dev.eastus.az.svc.builder.cafe/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBaG9EIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--75de99301bb9badf406e1ac6a29c46db8cc52471/abena-north-america-inc-logo-6-3-1.png"
    }],
    productGroup_count:0,
    listing_count:0,
   categories_count:0,
   showDirectoryPopup:false,
   searchTermProp:"",
   searchDirectoryData:[],
   searchProp:"",
   searchSubCategories:""

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start 


    this.getCountryList();
    this.getFilterList();
    this.updateSearchDirectory();
    const value=this.getDirectoryType();
    const token = await getStorageData("loginToken")
    const storedData = await getStorageData("userdetails");
    const accountDetails = JSON.parse(storedData);
    this.setState({ value, token, userdetails: accountDetails })

    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived == > ", message);
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const propData = message.getData(
          getName(MessageEnum.SessionResponseData)
      );
      const directory = propData?.directory;
      const searchText=propData?.searchText;
      
      if (directory !== undefined) {
          this.setState({ value: directory,searchTermProp: searchText })
          
      }
  }
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueApiCallId != null &&
      this.getCatalogueApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.error) {
        const { total_count, total_pages, start_point, end_point, current_page } = responseJson.pagination_catalogues
       
        this.setState({
          catalogueList: responseJson.catalogues,
          total_count,
          total_pages,
          start_point,
          end_point,
          isLoading: false,
          current_page,
          loadMore:false
        });       
      } else {
        this.setState({isLoading:false})
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCountryApiCallId != null &&
      this.getCountryApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.error) {
        this.setState({ countryList: responseJson.location });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }



    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getFilterListApiCallId != null &&
      this.getFilterListApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson && !responseJson.error) {
        const { supports, trainings, data_ownership, languages, in_good_companies, integrations, platform } = responseJson
        this.setState({ supports, trainings, data_ownership, languages, in_good_companies, integrations, platform });
      } else {
        const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getRegionApiCallId != null &&
      this.getRegionApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.error) {
        this.setState({ regionList: responseJson.location });
      } else {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.advancedsearchDataApiCallId != null &&
      this.advancedsearchDataApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.error) {
          this.handleAdvancedSearchResponse(responseJson)
      } else {
        this.setState({isLoading:false})
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getSearchResultDataReqId != null &&
      this.getSearchResultDataReqId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.handleDirectorySearch(responseJson)
      this.setState({isLoading:false})
    }

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCategoriesListApiId != null &&
      this.getCategoriesListApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      
      this.setState({categoryList: responseJson.categories})
      this.handleAdvanceSearch(responseJson);
    }


    
    // Customizable Area End
  }

  // Customizable Area Start

  handleLoadMore = () => {

    setTimeout(() => {
      this.setState({
        recordCount: this.state.recordCount + 10,
        loadMore:true,
      }, () => {
        this.getCatalogueList(this.state.value)
      })
    }, 500)
  }
  async componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState.view !== this.state.view || prevState.value !== this.state.value || prevState.regionId !== this.state.regionId || prevState.countryId !== this.state.countryId || prevState.selectedCategoryList != this.state.selectedCategoryList || prevState.selectedSubCategoryList != this.state.selectedSubCategoryList || prevState.feature != this.state.feature) {

      this.getCatalogueList(this.state.value);
      this.getCategoriesList();
    }
    this.checkSearch(prevState);
  }
  checkSearch = (prevState: any) => {
    if (prevState.search !== this.state.search) {
      this.getAdvancedSearchData();
    }
  }


  getCatalogueList = (tabId: any) => {
    this.setState({ isLoading: true })

    const { date_range, selectedSupplierType, selectedEventsType, countryId, selectedCategoryList, regionId, selectedSubCategoryList,searchTermProp} = this.state;
    const header = { "Content-Type": configJSON.productApiContentType };

    let endPoints = `${configJSON.catalogueUpdatedEndPoint}${configJSON.catalogueAPiFilter}${tabId}`;
     endPoints+= this.addSoftwareFilter();

    if (selectedCategoryList.length) {
      const categoryFilter = "&category_ids[]=" + selectedCategoryList.map((item: any) => item.id).join("&category_ids[]=")
      endPoints += categoryFilter
    }
    if (countryId) {
      endPoints += `&region_id=${countryId}`
    }
    if (regionId) {
      endPoints += `&location_id=${regionId}`
    }
    if (selectedSubCategoryList.length) {
      const subcategoryFilter = "&sub_category_ids[]=" + selectedSubCategoryList.map((item: any) => item.id).join("&sub_category_ids[]=")
      endPoints += subcategoryFilter
    }
    if (date_range != "Select Date Range" && date_range[0] && date_range[1]) {

      let start_date = moment(date_range[0]).format('DD-MM-YYYY');
      let end_date = moment(date_range[1]).format('DD-MM-YYYY');
      endPoints += `&start_date=${start_date}&end_date=${end_date}`
    }


    if (selectedSupplierType.length) {
      endPoints += `&is_it_brand=${selectedSupplierType.includes("Brands")}&is_it_distributor=${selectedSupplierType.includes("Distributors")}`
    }

    if (selectedEventsType.length) {
      const eventFilter = "&event_type[]=" + selectedEventsType.join("&event_type[]=")

      endPoints += eventFilter
    }

    if(searchTermProp){
      endPoints +=`&query=${searchTermProp}`
    }



    endPoints += `&sort_by=${this.state.sort_by}&page=${this.state.current_page}&per_page=${this.state.recordCount}`
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCatalogueApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCountryList = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCountryApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.countryAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getCategoriesList = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCategoriesListApiId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.categoriesUpdatedEndpoint}?directory_name=${this.state.value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  getRegionList = (id: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType
    };

    const endPoints = `${configJSON.regionAPiEndPoint}region_id=${id}`;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getRegionApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  addSoftwareFilter = () => {
    let software = "";
    const { selectedIntegrations, selectedTrainings, selectedLanguages, selectedSupports, selectedPlatforms, selectedGoodCompany, selectedDataOwner } = this.state
    if (selectedIntegrations.length) {
      const integrationsFilter = "&catalogue_ids[]=" + selectedIntegrations.join("&catalogue_ids[]=")
      software += integrationsFilter
    }

    if (selectedPlatforms.length) {
      const selectedPlatformsFilter = "&platform_ids[]=" + selectedPlatforms.join("&platform_ids[]=")
      software += selectedPlatformsFilter
    }
    if (selectedGoodCompany.length) {
      const selectedGoodCompanyFilter = "&good_company_ids[]=" + selectedGoodCompany.join("&good_company_ids[]=")
      software += selectedGoodCompanyFilter
    }

    if (selectedDataOwner.length) {
      const selecteddata_ownership_ids = "&data_ownership_ids[]=" + selectedDataOwner.join("&data_ownership_ids[]=")
      software += selecteddata_ownership_ids
    }


    if (selectedSupports.length) {
      const selectedSupport = "&support_ids[]=" + selectedSupports.join("&support_ids[]=")
      software += selectedSupport
    }

    if (selectedLanguages.length) {
      const langaugeFilter = "&language_ids[]=" + selectedLanguages.join("&language_ids[]=")
      software += langaugeFilter
    }

    if (selectedTrainings.length) {
      const trainingsFilter = "&training_ids[]=" + selectedTrainings.join("&training_ids[]=")
      software += trainingsFilter
    }
    return software;
  }



  handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    this.setState({ categoryShow: false, search: "",searchIntegrations:"", value: newValue, selectedCategoryList: [], selectedSubCategoryList: [], region: "Country", country: "Region", regionId: 0, countryId: 0, current_page: 1, date_range: "", selectedSupplierType: [], selectedIntegrations: [], selectedPlatforms: [], selectedGoodCompany: [], selectedDataOwner: [], selectedSupports: [], selectedTrainings: [], selectedLanguages: [], selectedEventsType: [] });
  };
  handleEventType = (item: any) => {
    this.setState((prevState) => {
      const events = prevState.selectedEventsType.some((type: any) => type === item.type);
      if (events) {
        return {
          selectedEventsType: prevState.selectedEventsType.filter((type: any) => type !== item.type),
        };
      } else {
        return {
          selectedEventsType: [...prevState.selectedEventsType, item.type],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };




  themeDetect = () => {
    const { value } = this.state;
    if (value == "Software") {
      return '#f0f7f8'
    } else if (value == "Services") {
      return '#ffecf5'
    } else if (value == "Events") {
      return '#f5f1fb'
    } else {
      return '#fff5dc'
    }
  };


  themeColorDetect = () => {
    const { value } = this.state;
    if (value == "Software") {
      return '#F62B8D'
    } else if (value == "Services") {
      return '#6BCAD3'
    } else if (value == "Events") {
      return '#7A4BD3'
    } else {
      return '#FFBB13'
    }
  };

  tabColorDetect = (val: any) => {
    const { value } = this.state;
    if (value == val) {
      return this.themeColorDetect()
    } else {
      return '#000'
    }
  }

  viewDetect = (val: number) => {
    const { view } = this.state;
    if (view == val) {
      return this.themeColorDetect()
    } else {
      return '#CED3D9'
    }
  }

  handleCategory = (item: any) => {
    this.setState((prevState) => {
      const categoryExists = prevState.selectedCategoryList.some((category: any) => category.id === item.id);
      if (categoryExists) {
        return {
          selectedCategoryList: prevState.selectedCategoryList.filter((category: any) => category.id !== item.id),
          selectedSubCategoryList: prevState.selectedSubCategoryList.filter((sub: any) => sub.category_id != item.id)
        };
      } else {

        return {
          selectedCategoryList: [...prevState.selectedCategoryList, item],
        };
      }
    });
  };

  checkCategory = (item: any) => {
    const { selectedCategoryList } = this.state;

    const categoryIndex = selectedCategoryList.findIndex((data: any) => data.id === item.id);

    return categoryIndex !== -1;
  };

  checkSupplierType = (item: any) => {
    const { selectedSupplierType } = this.state;

    const categoryIndex = selectedSupplierType.findIndex((supplier: any) => supplier === item.type);

    return categoryIndex !== -1;
  };

  checkEventType = (item: any) => {
    const { selectedEventsType } = this.state;

    const categoryIndex = selectedEventsType.findIndex((event: any) => event === item.type);

    return categoryIndex !== -1;
  };

  handleTrainings = (item: any) => {
    this.setState((prevState) => {
      const categoryExists = prevState.selectedTrainings.some((training: any) => training === item.id);
      if (categoryExists) {
        return {
          selectedTrainings: prevState.selectedTrainings.filter((training: any) => training !== item.id),
        };
      } else {
        return {
          selectedTrainings: [...prevState.selectedTrainings, item.id],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };

  handleCountry = (event: any) => {
    this.setState({ countryShow: !this.state.countryShow, countryAnchor: event && event.currentTarget });
  };

  handleMonthPicker = (event: any) => {
    this.setState({ monthAnchor: event && event.currentTarget });
  };
  handleDatePicker = (event: any) => {
    this.setState({ dateAnchor: event && event.currentTarget });
  };


  handleCountryClose = () => {
    this.setState({ countryShow: false, countryAnchor: null });
  };
  clearCountry = () => {
    this.setState({ countryId: 0, regionId: 0, region: "Country", country: "Region", regionList: [] });
  }

  checkIntegrationsType = (item: any) => {
    const { selectedIntegrations } = this.state;

    const index = this.getRecordIndex(selectedIntegrations, item)
    return index !== -1;
  };

  checkSupport = (item: any) => {
    const { selectedSupports } = this.state;

    const supportsIndex = this.getRecordIndex(selectedSupports, item)
    return supportsIndex !== -1;
  };


  getRecordIndex = (data: any, item: any) => {
    return data.findIndex((record: any) => record === item.id);

  }


  checkLanguages = (item: any) => {
    const { selectedLanguages } = this.state;

    const languageIndex = this.getRecordIndex(selectedLanguages, item)
    return languageIndex !== -1;
  };

  handleLanguages = (item: any) => {
    this.setState((prevState) => {
      const languageExist = prevState.selectedLanguages.some((language: any) => language === item.id);
      if (languageExist) {
        return {
          selectedLanguages: prevState.selectedLanguages.filter((language: any) => language !== item.id),
        };
      } else {
        return {
          selectedLanguages: [...prevState.selectedLanguages, item.id],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };

  handleDateClear = () => {
    this.setState({ defaultDate: "Select Date Range", date_range: null })
  }
  handleMonthClear = () => {
    this.setState({ selectedMonth: "Select Month" })
  }






  clearRegion = () => {
    this.setState({ regionId: 0, region: "Country", regionAnchor: null });
  }

  checkTrainings = (item: any) => {
    const { selectedTrainings } = this.state;

    const trainingIndex = this.getRecordIndex(selectedTrainings, item)
    return trainingIndex !== -1;
  };


  checkPlatform = (item: any) => {
    const { selectedPlatforms } = this.state;

    const platformIndex = this.getRecordIndex(selectedPlatforms, item)

    return platformIndex !== -1;
  };



  onCountryListClick = (item: any) => {
    this.setState({ country: item.name, countryId: item.id });
    this.getCatalogueList(this.state.value)
    this.getRegionList(item.id);
    this.handleCountryClose()
  };

  handleRegion = (event: any) => {
    this.setState({ regionShow: !this.state.regionShow, regionAnchor: event && event.currentTarget });
  };

  handleRegionClose = () => {
    this.setState({ regionShow: false, regionAnchor: null });
  };


  checkGoodCompany = (item: any) => {
    const { selectedGoodCompany } = this.state;

    const trainingIndex = this.getRecordIndex(selectedGoodCompany, item)
    return trainingIndex !== -1;
  };

  onRegionListClick = (item: any) => {
    this.setState({ region: item.name, regionId: item.id });
    this.handleRegionClose()

  };
  checkDataOwner = (item: any) => {
    const { selectedDataOwner } = this.state;

    const index = this.getRecordIndex(selectedDataOwner, item)
    return index !== -1;
  };

  toggleSeeBtn = () => {
    this.setState({ categoryShow: !this.state.categoryShow });
  };

  toggleFeature = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ featureShow: !this.state.featureShow, anchorEl: event && event.currentTarget });


  };

  handleView = (item: number) => {
    this.setState({ view: item });
  };

  checkSubCategory = (item: any) => {
    const { selectedSubCategoryList } = this.state;

    const categoryIndex = selectedSubCategoryList.findIndex((category: any) => category.id === item.id);

    return categoryIndex !== -1;
  };

  handleSubCategory = (item: any) => {
    this.setState((prevState) => {
      const categoryExists = prevState.selectedSubCategoryList.some((category: any) => category.id === item.id);
      if (categoryExists) {
        return {
          selectedSubCategoryList: prevState.selectedSubCategoryList.filter((category: any) => category.id !== item.id),
        };
      } else {
        return {
          selectedSubCategoryList: [...prevState.selectedSubCategoryList, item],
        };
      }
    });
  };
  getListingUrl = (type: any, id: any, payment_status: string) => {
    if (payment_status == "pending") {
      if (type == "Suppliers") {
        return "/basiclistingsupplier/" + id
      }
      else if (type == "Software") {
        return "/basiclisting/" + id
      }
      else if (type == "Services") {
        return "/basiclistingservices/" + id
      }
      else if (type == "Events") {
        return "/premiumlistingevents/" + id
      }

    } else {


      if (type == "Suppliers") {
        return "/premiumlistingsupplier/" + id
      }
      else if (type == "Software") {
        return "/premiumlisting/" + id
      }
      else if (type == "Services") {
        return "/premiumlistingservices/" + id
      }
      else if (type == "Events") {
        return "/premiumlistingevents/" + id
      }
    }


  }



  handleSupplierType = (item: any) => {
    this.setState((prevState) => {
      const categoryExists = prevState.selectedSupplierType.some((suppliers: any) => suppliers === item.type);
      if (categoryExists) {
        return {
          selectedSupplierType: prevState.selectedSupplierType.filter((suppliers: any) => suppliers !== item.type),
        };
      } else {
        return {
          selectedSupplierType: [...prevState.selectedSupplierType, item.type],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };

  handleDataOwnerShip = (item: any) => {
    this.setState((prevState) => {
      const ownerExist = prevState.selectedDataOwner.some((record: any) => record === item.id);
      if (ownerExist) {
        return {
          selectedDataOwner: prevState.selectedDataOwner.filter((record: any) => record !== item.id),
        };
      } else {
        return {
          selectedDataOwner: [...prevState.selectedDataOwner, item.id],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };

  handleSupport = (item: any) => {
    this.setState((prevState) => {
      const supportExist = prevState.selectedSupports.some((support: any) => support === item.id);
      if (supportExist) {
        return {
          selectedSupports: prevState.selectedSupports.filter((support: any) => support !== item.id),
        };
      } else {
        return {
          selectedSupports: [...prevState.selectedSupports, item.id],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };


  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  handleMonthClose = () => {
    this.setState({ monthAnchor: null });
  };

  handleDateClose = () => {
    this.setState({ dateAnchor: null });
  };

  handlePlatforms = (item: any) => {
    this.setState((prevState) => {
      const platformExists = prevState.selectedPlatforms.some((platform: any) => platform === item.id);
      if (platformExists) {
        return {
          selectedPlatforms: prevState.selectedPlatforms.filter((platform: any) => platform !== item.id),
        };
      } else {
        return {
          selectedPlatforms: [...prevState.selectedPlatforms, item.id],
        };
      }
    }, () => this.getCatalogueList(this.state.value));

  };



  handleMonth = (e: any) => {
    const formattedDate = moment(e.value).format("MMM/YYYY");
    this.setState({ selectedMonth: formattedDate, date_range: e.value }, () => this.handleMonthClose())
  }
  handleDateRange = (e: any) => {
    this.setState({ date_range: e.value, defaultDate: "" }, () => {
      if (e.value[0] && e.value[1]) {
        this.getCatalogueList(this.state.value);
        this.handleDateClose();
      }
    })
  }
  

  goToLoginPage = () => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountLoginBlock");
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }

  handleGoodCompany = (item: any) => {
    this.setState((prevState) => {
      const companyExist = prevState.selectedGoodCompany.some((data: any) => data === item.id);
      if (companyExist) {
        return {
          selectedGoodCompany: prevState.selectedGoodCompany.filter((data: any) => data !== item.id),
        };
      } else {
        return {
          selectedGoodCompany: [...prevState.selectedGoodCompany, item.id],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };

  navigateToAccount = (usertype: any) => {
    let accountType = usertype.toLowerCase()
    if (accountType == "vendor") {
      this.handleNavigation("VendorAccountDetails")
    } else if (accountType == "buyer") {
      this.handleNavigation("BuyerAccountDetails")
    }
  }
  getDirectoryType = () => {
    const currentPath = window.location.pathname;
    const pathParts = currentPath.split('/');
    const directory_name = pathParts[pathParts.length - 1];
    return directory_name!="Catalogue"?directory_name:"All"
  }

  handleIntegrations = (item: any) => {
    this.setState((prevState) => {
      const categoryExists = prevState.selectedIntegrations.some((integration: any) => integration === item.id);
      if (categoryExists) {
        return {
          selectedIntegrations: prevState.selectedIntegrations.filter((integration: any) => integration !== item.id),
        };
      } else {
        return {
          selectedIntegrations: [...prevState.selectedIntegrations, item.id],
        };
      }
    }, () => this.getCatalogueList(this.state.value));
  };
  handleMoreSoftware = () => {
    this.setState({ showSoftwareFilter: !this.state.showSoftwareFilter })
  }

  handleFeatureClick = (item: any) => {
    this.setState({ feature: item.label, featureShow: false, anchorEl: null, sort_by: item.value })
  }
  handleSignoutClick = async () => {
    await removeStorageData("loginToken")
    this.handleNavigation("Home")
  }

  handleNavigation = (component: any) => {
    const message = new Message(getName(MessageEnum.NavigationMessage));
    message.addData(getName(MessageEnum.NavigationTargetMessage), component);
    message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(message)
  }
  handleSearchCatalogue = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({ search: event.target?.value, current_page: 1 ,showSearchResult:true})
    if(!event.target?.value) this.setState({showSearchResult:false})
  }



  getFilterList = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType
    };

    let endPoints = `${configJSON.filterListEndpoint}`;
    if(this.state.searchIntegrations){
      endPoints +=`?query=${this.state.searchIntegrations}`
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getFilterListApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoints
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  

  handleSearchIntegration=(event:ChangeEvent<HTMLInputElement>)=>{
    this.setState({searchIntegrations:event.target?.value},()=>this.getFilterList())
  }
  handleAdvanceSearch= async (responseJson:any)=>{
    let  currentUrl = window.location.href;
    let  url = new URL(currentUrl);
    let categoryId = url.searchParams.get("adv_category_id");
    let categoryType=await getStorageData("categoryType")
    let sub_category_id = url.searchParams.get("adv_sub_category_id");

    if(categoryId && categoryType=="advanced"){
        this.handleCategoryCheck(responseJson,categoryId,sub_category_id)
      }
    

  }


  handleCategoryCheck=async(responseJson:any,categoryId:any,sub_category_id:any)=>{
    this.setState({showSearchResult:false})
  let category=responseJson &&  responseJson.categories.find((item:any)=>item.id==categoryId)
  this.handleCategory(category)
  if(sub_category_id){
    let sub_category=category.sub_categories.find((item:any)=>item.id==sub_category_id)
    this.handleSubCategory(sub_category)
  }

  await removeStorageData("categoryType")
  }

 

  getAdvancedSearchData= () => {
    this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.productApiContentType,
  };
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  this.advancedsearchDataApiCallId = requestMessage.messageId;

  
  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `${configJSON.AdvancedSearchApiEndPoint}?query=${this.state.search}&directory_name=${this.state.value}`
  );
  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.apiMethodTypeGet
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

handleAdvancedSearchResponse=(responseJson:any)=>{
  if (responseJson && !responseJson.errors) {
   const {catalogues,categories,sub_categories,count}=responseJson?.data
   
    this.setState({isLoading:false,listingResult:catalogues,categoriesResult:categories,productGroupResult:sub_categories,listing_count:count.catalogue_count,categories_count:count.categories_count,productGroup_count:count.sub_categories_count})
  }
}

handleSubmit=(event:any)=>{
  event.preventDefault();
  this.handleAdvancedSearch("All")
}
handleAdvancedSearch = (directory:string) => {
  this.setState({showDirectoryPopup:false})
  const msg: Message = new Message(
    getName(MessageEnum.NavigateToAdvancedSearch)
  );
  msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
  msg.addData(getName(MessageEnum.AdvancedSearchTermData), {directory,searchText:this.state.searchTermProp})
  this.send(msg);

}
handleDirectoryPopupClose=()=>{
  this.setState({showDirectoryPopup:false})
}
handleSearchInput = (event:any) =>{  
  this.setState({searchTermProp: event.target.value,showDirectoryPopup:true},()=>this.getSearchResultDirectory())
  if(!event.target.value){
    this.handleDirectoryPopupClose();
    this.getCatalogueList(this.state.value)
  }
}

getSearchResultDirectory = () => {
  this.setState({isLoading:true})
  const header = {
    "Content-Type": configJSON.productApiContentType,
  };

const searchdataRequestMessage = new Message(
  getName(MessageEnum.RestAPIRequestMessage)
);

  this.getSearchResultDataReqId = searchdataRequestMessage.messageId;
    searchdataRequestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header)
    );
    searchdataRequestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`${configJSON.getSearchResultEndPoint}?query=${this.state.searchTermProp}`);

searchdataRequestMessage.addData(
getName(MessageEnum.RestAPIRequestMethodMessage),
configJSON.apiMethodTypeGet
);

runEngine.sendMessage(searchdataRequestMessage.id, searchdataRequestMessage);


}


handleDirectorySearch=(responseJson:any)=>{
  if(responseJson && responseJson.response){
    this.setState({isLoading:false,searchDirectoryData:responseJson.response})
  }
}

updateSearchDirectory=()=>{
  let  currentUrl = window.location.href;
  let  url = new URL(currentUrl);
  let search = url.searchParams.get("search");
  if(search) this.setState({searchTermProp:search})
}




handlefilteredData = (subcategory:any)=>{
  const data= subcategory && subcategory.filter((item:any) =>
  item.name.toLowerCase().includes(this.state.searchSubCategories.toLowerCase())
);

return data
}
 
  




  // Customizable Area End
}
