import React from "react";

import {
    Typography,
    Box,
    Divider,
    // Customizable Area Start
    withStyles,
    Theme,
    Switch,
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start




export const styles: any = (theme: Theme) => ({
    notifysetting: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "14px",
        margin: "26px 0 43px",
        [theme.breakpoints.down('sm')]: {
            paddingLeft:"10px",
        },
    },
    notifyContainer: {
        borderRadius: "16px",
        backgroundColor: "#FFF",
        [theme.breakpoints.down('sm')]: {
            backgroundColor: '#FFFFFF',
            margin:"10px",
        },
    },
    email: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: "22px",
        fontWeight: 500,
        lineHeight: "14px",
        padding: "42px 42px 33px 52px",
        [theme.breakpoints.down('sm')]: {
            padding: "30px",
        },
    },
    box: {
        padding: "45px 44px 45px 48px",
        height: "681px",
        overflow: "scroll",
        "&::-webkit-scrollbar": {
            display: "none",
            width: "0",
        },
        [theme.breakpoints.down('sm')]: {
            padding: "30px",
        },
    },

    switch: {
        width: 48,
        height: 28,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(20px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color: theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 23,
            height: 23.5,
        },
        '& .MuiSwitch-track': {
            borderRadius: 28 / 2,
            backgroundColor: '#E2E8F0',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    },
    updateContainer: {
        marginBottom: "52px",
    },
    updates: {
        color: "#3B3B3B",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "14px",
        marginBottom: "16px",
    },
    updateContent: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "start"
    },
    desc: {
        maxWidth: "80%",
        flexWrap: "wrap",
        color: "#707070",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "19px",
        [theme.breakpoints.down('sm')]: {
            fontSize: "16px",
        },
    }


})

// Customizable Area End

import NotificationController, {
    Props
} from "./NotificationController.web";

class Notification extends NotificationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <Typography className={classes.notifysetting}>Notifications Settings</Typography>
                <Box className={classes.notifyContainer}>
                    <Typography className={classes.email}>Email Notifications</Typography>
                    <Divider />
                    <Box className={classes.box}>
                        <Box className={classes.updateContainer}>
                            <Typography className={classes.updates}>Weekly Updates</Typography>
                            <Box className={classes.updateContent}>
                                <Typography className={classes.desc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                                <Switch className={classes.switch} defaultChecked />

                            </Box>
                        </Box>
                        <Box className={classes.updateContainer}>
                            <Typography className={classes.updates}>Account Updates</Typography>
                            <Box className={classes.updateContent}>
                                <Typography className={classes.desc}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</Typography>
                                <Switch className={classes.switch} />

                            </Box>
                        </Box>
                    </Box>
                </Box>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

export { Notification }
export default withStyles(styles)(Notification)

// Customizable Area End
