import React from "react";

// Customizable Area Start
import { Grid, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, Box, Divider } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import CallIcon from '@material-ui/icons/Call';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
// Customizable Area End

// Customizable Area Start
import Skeleton from '@material-ui/lab/Skeleton';
import TablePagination from '@material-ui/core/TablePagination';
import CustomisableusersubscriptionsController, {
	Props, configJSON,
} from "./CustomisableusersubscriptionsController";
// Customizable Area End

// Customizable Area Start
// For testing purpose 
const paymentData = [
	{
		orderId: "#1234",
		payDate: "23-Aug-23",
		payStatus: "Completed",
		payTotal: "$49.00/1 item"
	},
	{
		orderId: "#1235",
		payDate: "23-Aug-23",
		payStatus: "Completed",
		payTotal: "$49.00/1 item"
	},
	{
		orderId: "#1236",
		payDate: "23-Aug-23",
		payStatus: "Pending Payment",
		payTotal: "$49.00/1 item"
	},
]
// Customizable Area End

export class PaymentHistory extends CustomisableusersubscriptionsController {
	constructor(props: Props) {
		super(props);
		// Customizable Area Start
		// Customizable Area End
	}
	// Customizable Area Start
	// Customizable Area End
	render() {
		// Customizable Area Start
		const { classes }: any = this.props;
		const cardData=this.state.currentData?.payment_method_details?.card?this.state.currentData?.payment_method_details?.card.brand:"N/A"
		// Customizable Area End
		return (
			// Customizable Area Start
			<>

				<Grid item xs={12} className={classes.payContainer}>
					{this.state.isViewMain && <Typography className={classes.titleStyle}>{configJSON.paymentHistoryTitle}</Typography>}
					{!this.state.isViewMain && <>
						<Box className="" style={{ display: "flex", alignItems: "center", justifyContent: "left", margin: "0 0 15px" }}>
							<Typography
								data-testid={"mockpath"}
								onClick={this.goToMockPath}
								className={classes.paths}
								style={{ color: "#475569", margin: "0 10px",  fontFamily: "Poppins, Helvetica, Arial, sans-serif", }}>{configJSON.paymentHistoryTitle}</Typography>
							<Typography className={classes.paths} style={{ color: "#475569" }}>{">"} </Typography>
							{this.state.isViewDetail && <Typography className={classes.paths} style={{ margin: "0 10px",fontFamily: "Poppins, Helvetica, Arial, sans-serif" }}>{"Order Details"} </Typography>}
							{this.state.isViewPayment && <Typography className={classes.paths} style={{ margin: "0 10px" }}>{"Subscription Details"} </Typography>}
						</Box>
					</>}
					{this.state.isViewMain &&
						<>
								<TableContainer className={classes.payTableContainer}>
								<Table className={classes.tableStyle} aria-label="responsive table">
									<TableHead>
										<TableRow className={classes.tableHeader} data-testid="tableHeader">
											<TableCell align="center" className={classes.tabelHeaderCell}>Order</TableCell>
											<TableCell align="center" className={classes.tabelHeaderCell}><span style={{marginLeft:"-2rem"}}>Date</span></TableCell>
											<TableCell align="left"  className={classes.tabelHeaderCell}>Status</TableCell>
											<TableCell align="left" className={classes.tabelHeaderCell}>Total</TableCell>
											<TableCell className={classes.tabelHeaderCell} align="center">Actions</TableCell>
			
										</TableRow>
									</TableHead>
									<TableBody className={classes.tableBody}>
										{this.state.paymentData && this.state.paymentData.map((row: any) => (
											<TableRow key={row.orderId}>
												<TableCell align="center" className={classes.payTableCell}>#{row.attributes.order}</TableCell>
												<TableCell align="center" className={classes.payTableCell}>{row.attributes.order_date}</TableCell>
												<TableCell  className={`${row.payStatus === "Completed" ? classes.payCompletedCell : classes.payPendingCell}`}>{row.attributes.status}</TableCell>
												<TableCell className={classes.payTableCell}>{row.attributes.order_total}</TableCell>
												<TableCell align="center" className={classes.payTableCell}>
													<Button data-test-id="data" variant="outlined" color="primary" className={classes.payTableCellBtn} onClick={() => this.handlePayment(row.attributes)}>
														View
													</Button>
												</TableCell>
					
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
							<TablePagination
								rowsPerPageOptions={[5]}
								component="div"
								count={this.state.counts}
								rowsPerPage={5}
								datatest-id="payment-pagination"
								page={this.state.page}
								onPageChange={this.handleChangePage}
								onRowsPerPageChange={this.handleChangeRowsPerPage}
							/>
						</>
					}
					{(this.state.isViewDetail) &&
						<Grid item xs={12} className={classes.viewPaymentContainer}>
							<Grid item className={classes.viewPaymentMain}>
								<Grid className={classes.headerTitle}>
									<Box className={classes.divBlocks}>
										<Typography className={classes.titleText}>Order #</Typography>
										<Typography className={classes.titleDescription}>
											{this.state.currentData.order}
										</Typography>
									</Box>
									<Divider orientation="vertical" flexItem />
									<Box className={classes.divBlocks}>
										<Typography className={classes.titleText}>Order Date</Typography>
										<Typography className={classes.titleDescription}>{this.state.currentData.order_date}</Typography>
									</Box>
									<Divider orientation="vertical" flexItem />
									<Box className={classes.divBlocks}>
										<Typography className={classes.titleText}>Order Total</Typography>
										<Typography className={classes.titleTextCenter}>$ {this.state.currentData.order_total}</Typography>
									</Box>
									<Divider orientation="vertical" flexItem />
									<Box className={classes.divBlocks}>
										<Typography className={classes.titleText}></Typography>
										<Typography className={classes.titleDescription}></Typography>
									</Box>
									<Divider orientation="vertical" flexItem />
									<Box className={classes.divBlocks}>
										<Typography className={classes.titleText}>COMPLETED</Typography>
										<Box className={classes.SkeletonDiv}>
											<Skeleton className={classes.SkeletonTab} animation={false} />
											<Skeleton className={classes.SkeletonTab} animation={false} />
											<Skeleton className={classes.SkeletonTab} animation={false} />
											<Skeleton className={classes.SkeletonTab} animation={false} />
											<Skeleton className={classes.SkeletonTab} animation={false} />
										</Box>
									</Box>
								</Grid>
								<Divider />
								<Grid container className={classes.viewDescritionSection}>
									<Grid item className={classes.viewSection}>
										<Box className={classes.viewLeft}>
											<Typography className={classes.viewRightTitle}>ITEM</Typography>
											<Divider />
											<Box className={classes.viewCommonStatus} style={{ alignItems: "center" }}>
												<Box className={classes.viewImageLeft}><img src={this.state.currentData.logo} style={{ width: "68px", height: "68px" }} /></Box>
												<Box className={classes.viewLeftRight}>
													<Typography className={classes.viewRightTitle}>{this.state.currentData.item_name}</Typography>
													<Typography className={classes.viewStatusPara}>{this.state.currentData.item_description}</Typography>
												</Box>
											</Box>
											<Divider />
										</Box>
									</Grid>
									<Grid item className={classes.viewSection} data-testid={"userleft"}>
										<Box className={classes.viewRight} data-testid={"order"}>
											<Typography className={classes.viewRightTitle}>ORDER TOTAL</Typography>
											<Divider data-testid={"divider"} />
											<Box className={classes.viewCommonStatus} style={{ padding: "5% 0" }}>
												<Box className={classes.viewRightMid}>
													<Typography className={classes.viewRightPara}>Subtotal</Typography>
													<Typography className={classes.viewRightPara}>Tax</Typography>
												</Box>
												<Box className={classes.viewRightMid} data-testid={"1"}>
													<Typography className={classes.viewRightPara}>$ {this.state.currentData.order_total}</Typography>
													<Typography className={classes.viewRightPara}>$ {0}</Typography>
												</Box>
											</Box>
											<Divider />
											<Box className={classes.viewCommonStatus} data-testid={"4"}>
												<Box className={classes.viewRightMid}>
													<Typography className={classes.viewRightTitle}>Total</Typography>
												</Box>
												<Box className={classes.viewRightMid} data-testid={"2"}>
													<Typography className={classes.viewRightTitle}>$ {this.state.currentData.order_total}</Typography>
												</Box>
											</Box>
										</Box>
									</Grid>
								</Grid>
								<Grid container className={classes.viewDescritionSection}>
									<Grid item className={classes.viewSection}>
										<Box className={classes.viewLeft}>
											<Typography className={classes.viewRightTitle}>BILLING ADDRESS</Typography>
											<Divider />
											<Box className={classes.viewCommonStatus} style={{marginTop:"9px"}}>
												<Box className={classes.viewLeftRight}>
													<Typography className={classes.viewStatusPara}>{this.state.currentData?.payment_method_details?.billing_details?.billing_details && "A-320 New Street. F Block, Stark Aveneue,London -232432"}</Typography>
													<Typography className={classes.viewWithicons}><CallIcon className={classes.icon} />{'\u00A0'.repeat(2)}+44-24234235</Typography>
													<Typography className={classes.viewWithiconsGmail}><AlternateEmailIcon className={classes.icon} />{'\u00A0'.repeat(2)}peter03@gmail.com</Typography>
												</Box>
											</Box>
										</Box>
									</Grid>
									<Grid item className={classes.viewSection}>
										<Box className={classes.viewRight}>
											<Typography className={classes.viewRightTitle}>PAYMENT METHOD</Typography>
											<Divider />
											<Box className={classes.viewCommonStatus} style={{marginTop:"9px"}}>
												<Box className={classes.viewRightMid}>
													<Typography className={classes.viewRightPara} style={{ alignItems: "unset" }}>{cardData}</Typography>
												</Box>
											</Box>
										</Box>
									</Grid>
								</Grid>
								<Grid container className={classes.viewDescritionSection}>
									<Grid item className={classes.viewFooterSection}>
										<Box className={classes.viewFooterMain}>
											<Typography className={classes.viewFooterTitle} style={{marginTop:'2rem'}}>RELATED SUBSCRIPTIONS</Typography>
											<Divider style={{marginTop:"-21px"}}/>
											<Grid className={classes.headerTitle}>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Subscription</Typography>
													<Typography className={classes.titleLightText} style={{ textTransform: "capitalize" }}>
														{this.state.currentData.subscription && this.state.currentData.subscription.toLowerCase()}
													</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Status</Typography>
													<Typography className={classes.titleLightText}>{this.state.currentData.status == "completed" ? "Active" : "Close"}</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Next Payment</Typography>
													<Typography className={classes.titleLightText}>{this.state.currentData.next_payment}</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Total</Typography>
													<Typography className={classes.titleLightText}>$ {this.state.currentData.order_total}</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Button data-test-id="data" variant="outlined" color="primary" className={classes.payTableCellBtn} onClick={this.handleViewDetailMain} style={{width:"87px",height:'35px'}}>View</Button>
												</Box>
											</Grid>
											<p style={{marginTop:"-12px",color:"#ffffff"}}>grid</p>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					}
					{this.state.isViewPayment &&
						<Grid item xs={12} className={classes.viewPaymentContainer}>
							<Grid item className={classes.viewPaymentMain}>
								<Grid className={classes.headerTitle} style={{ width: "100%" }}>
									<Box className={classes.divBlocksSub}>
										<Typography className={classes.titleText}>Subscription</Typography>
										<Typography className={classes.titleDescription} style={{ textTransform: "capitalize" }}>
											{this.state.currentData?.subscription.toLocaleLowerCase()}
										</Typography>
									</Box>
									<Box className={classes.divBlocksSub}>
										<Typography className={classes.titleText}>Start Date</Typography>
										<Typography className={classes.titleDescription}>{this.state.currentData.order_date}</Typography>
									</Box>
									<Box className={classes.divBlocksSub}>
										<Typography className={classes.titleText}>Last Order Date</Typography>
										<Typography className={classes.titleDescription}>{this.state.currentData.next_payment}</Typography>
									</Box>
									<Box className={classes.divBlocksWithActive}>
										<Typography className={classes.titleText} style={{ margin: 0 }}>Status</Typography>
										<Typography className={classes.titleDescriptionTypography}>{this.state.currentData.status == "completed" ? "Active" : "Close"}</Typography>
									</Box>
								</Grid>
								<Grid className={classes.headerTitle} style={{ justifyContent: "unset", width: "100%" }}>
									<Box className={classes.divBlocks2}>
										<Typography className={classes.titleText}>Next Payment Date</Typography>
										<Typography className={classes.titleDescription}>{this.state.currentData.next_payment}</Typography>
									</Box>
									<Box className={classes.divBlocks2}>
										<Typography className={classes.titleText}>Payment</Typography>
										<Typography className={classes.titleDescription}>Manual Renewal</Typography>
									</Box>
								</Grid>
								<Divider />
								<Grid container className={classes.viewDescritionSection}>
									<Grid item className={classes.viewSection} >
										<Box className={classes.viewLeft}>
											<Typography className={classes.viewRightTitle}>SUBSCRIPTION TOTALS</Typography>
											<Divider />
											<Box className={classes.viewCommonStatus}>
												<Box className={classes.viewLeftRight}>
													<Typography className={classes.titleSubscriptionText}>Subscription-Premium</Typography>
													<Typography className={classes.viewStatusPara}>Lorem Ipsum</Typography>
												</Box>
											</Box>
											<Divider />
										</Box>
									</Grid>
									<Grid item className={classes.viewSection} data-testid={"6"}>
										<Box className={classes.viewRight} data-testid={"7"}>
											<Typography className={classes.viewRightTitle}>ORDER TOTAL</Typography>
											<Divider />
											<Box className={classes.viewCommonStatus} data-testid={"8"} >
												<Box className={classes.viewRightMid} data-testid={"9"}>
													<Typography className={classes.viewRightPara}>Subtotal</Typography>
													<Typography className={classes.viewRightPara}>Tax</Typography>
												</Box>
												<Box className={classes.viewRightMid} data-testid={"10"} >
													<Typography className={classes.viewRightPara}>$ {this.state.currentData.order_total}</Typography>
													<Typography className={classes.viewRightPara}>$ {0}</Typography>
												</Box>
											</Box>
											<Divider />
											<Box className={classes.viewCommonStatus}>
												<Box className={classes.viewRightMid}>
													<Typography className={classes.viewRightParaText} data-testid={"1"}>Total</Typography>
												</Box>
												<Box className={classes.viewRightMid}>
													<Typography className={classes.viewRightParaText}>$ {this.state.currentData.order_total}</Typography>
												</Box>
											</Box>
										</Box>
									</Grid>
								</Grid>
								<Grid container className={classes.viewDescritionSection}>
									<Grid item className={classes.viewFooterSection}>
										<Box className={classes.viewFooterMain}>
											<Typography className={classes.viewFooterTitle}>RELATED ORDERS</Typography>
											<Divider />
											<Grid className={classes.headerTitle}>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Order</Typography>
													<Typography className={classes.titleLightText}>
														#{this.state.currentData.order}
													</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Date</Typography>
													<Typography className={classes.titleLightText}>{this.state.currentData.order_date}</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Status</Typography>
													<Typography className={classes.titleLightText} style={{ color: "#01983D", textTransform: "capitalize" }}>{this.state.currentData.status}</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Typography className={classes.titleDescription}>Total</Typography>
													<Typography className={classes.titleLightText} >$ {this.state.currentData.order_total}</Typography>
												</Box>
												<Box className={classes.viewFooterBlocks}>
													<Button data-test-id="data" variant="outlined" color="primary" className={classes.payTableCellBtn} onClick={this.handleViewDetailPayment} >View</Button>
												</Box>
											</Grid>
										</Box>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					}
				</Grid>
			</>
			// Customizable Area End
		);
	}
}


// Customizable Area Start
export const userStyles = () => ({
	payCompletedCell: {
		color: "#B08800",
		fontSize: "16px",
		fontWeight: 500,
		textTransform: "capitalize" as const,
	},
	payPendingCell: {
		color: "#00874D",
		fontSize: "16px",
		fontWeight: 500,
		textTransform: "capitalize" as const,
	},
	payTableCell: {
		color: "#3B3B3B",
		fontSize: "16px",
		fontWeight: 500,
	},
	payTableCell1: {
		borderBottom: 0,
		width: 10,
		padding: "16px 0"
	},
	payTableCellBtn: {
		borderRadius: "8px",
		border: "1px solid #6200EA",
		background: "#FFF",
		textTransform: "capitalize" as const,
		color: "var(--primary-purple-600, #6200EA)",
		fontSize: "14px",
		fontWeight: 400,
		fontFamily:"Poppins",
		width:"87px",
		height:"35px"
	},
	
	payTableContainer: {
		margin: "10px 0",
		width: "auto",boxShadow:"none",
	},
	titleStyle: {
		color: "#262424",
		fontSize: "24px",
		fontWeight: 500,
		margin: "0 0 15px",
		fontFamily: "Poppins",
		marginBottom:"2rem",
		marginLeft:"3rem"
	},
	tableHeader: {
		"& th:first-child": {
			borderTopLeftRadius: "16px",
			borderBottomLeftRadius: "16px",
		},
		"& th:last-child": {
			borderTopRightRadius: "16px",
			borderBottomRightRadius: "16px",
		},
		"& th": {
			backgroundColor: "#B300ED",
			color: "#fff",
			fontSize: "18px",
			fontWeight: 500,
		},
	},
	tabelHeaderCell:{
		borderBottom:"none",
	},
	tableStyle: {
		minWidth: 650,
		background: "#fff",
		borderRadius: "16px",
		margin: "0 auto",
	},
	viewPaymentContainer: {
		background: "#F8F2FF",
		padding: 0,
		width: "100%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	titleOrderView: {
		color: "#262424",
		fontSize: "18px",
		fontWeight: 400,
	},
	paths: {
		color: "#262424",
		fontSize: "14px",
		fontWeight: 400,
	},
	viewPaymentMain: {
		background: "#FFF",
		border: "1px solid #FFF",
		borderRadius: "16px",
		padding: 0,
		width: "100%"
	},
	headerTitle: {
		padding: 0,
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		"@media (max-width: 1130px )": {
			width: "100%",
		},
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	divBlocks: {
		width: "19%",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column" as "column",
		padding: "5% 3%",
		"@media (max-width: 1130px )": {
			padding: "4% 2%",
			width: "19%",
		},
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	divBlocksSub: {
		width: "24%",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column" as "column",
		padding: "5% 2.5%",
		"@media (max-width: 1130px )": {
			padding: "4% 2%",
			width: "19%",
		},
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	divBlocks2: {
		width: "25%",
		display: "flex",
		justifyContent: "center",
		flexDirection: "column" as "column",
		padding: "5% 3%",
		"@media (max-width: 1130px )": {
			padding: "4% 2%",
		},
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	SkeletonDiv: {
		width: "70%",
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center" as const,
		"@media (max-width: 1130px )": {
			width: "90%",
		},
		"@media (max-width: 900px )": {
			width: "90%",

		},
		"@media (max-width: 600px )": {
			width: "90%",

		},
		"@media (max-width: 480px )": {
			width: "90%",

		}
	},
	SkeletonTab: {
		width: "19%",
		height: "12px",
		background: "#4A4A4A",
		borderRadius: "2px",
		"@media (max-width: 1130px )": {
			width: "18%",
		},
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	divBlocksWithActive: {
		width: "25%",
		display: "flex",
		padding: "5% 2.5%",
		alignItems: "center" as const,
		"@media (max-width: 1130px )": {
			padding: "5% 2%",
			width: "18%",
		},
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewDescritionSection: {
		display: "flex",
		"@media (max-width: 1130px )": {
			width: "100%",
		},
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewSection: {
		width: "50%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewLeft: {
		width: "85%",
		padding: " 10% 0 0 5%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewRight: {
		// width: "100%",
		width: "85%",
		padding: " 10% 0 0 5%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewTitle: {
		color: "#000",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 500,
		padding: "3% 0",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewLeftSection: {
		display: "flex",
		alignItems: "center",
		width: "100%",
		padding: "3% 0",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewImageLeft: {
		width: "25%",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewLeftRight: {
		width: "75%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewCommonStatus: {
		display: "flex",
		width: "100%",
		padding: "3% 0",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewStatusTitle: {
		color: "#000",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 500,
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewStatusPara: {
		color: "#707070",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewWithicons: {
		display: "flex",
		color: "#707070",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		alignItems: "center",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewWithiconsGmail: {
		display: "flex",
		color: "#707070",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		alignItems: "center",
		marginTop:'5px',
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	icon: {
		width: "20px",
		height: "20px",
		color: "black"
	},
	viewStatusParaText: {
		color: "#000",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 400,
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewRightSection: {
		width: "85%",
		padding: "10% 0 0 5%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewRightTitle: {
		color: "#000",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 500,
		padding: "3% 0",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewRightMid: {
		width: "50%",
		display: "flex",
		alignItems: "center",
		flexDirection: "column" as "column",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewRightPara: {
		color: "#707070",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewFooterSection: {
		width: "100%",
	},
	viewFooterMain: {
		width: "100%",
		padding: 0,
	},
	viewFooterTitle: {
		color: "#000",
		padding: "3% 0",
		fontSize: "18px",
		fontStyle: "normal",
		fontFamily: "Poppins",
		fontWeight: 500,
		paddingLeft: "2.5%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	viewFooterBlocks: {
		width: "20%",
		display: "flex",
		//alignItems: "center",
		justifyContent: "center",
		flexDirection: "column" as "column",
		padding: "2.5%",
		"@media (max-width: 900px )": {
		},
		"@media (max-width: 600px )": {
		},
		"@media (max-width: 480px )": {
		}
	},
	titleText: {
		color: "#707070",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "18px",
		marginBottom: "10px",
		"@media (max-width: 1130px )": {
			fontSize: "16px",
		},
		"@media (max-width: 900px )": {
			fontSize: "16px",
		},
		"@media (max-width: 600px )": {
			fontSize: "16px",
		},
		"@media (max-width: 480px )": {
			fontSize: "16px",
		}
	},
	titleSubscriptionText: {
		color: "#000000",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "18px",
		marginBottom: "10px",
		"@media (max-width: 1130px )": {
			fontSize: "16px",
		},
		"@media (max-width: 900px )": {
			fontSize: "16px",
		},
		"@media (max-width: 600px )": {
			fontSize: "16px",
		},
		"@media (max-width: 480px )": {
			fontSize: "16px",
		}
	},
	titleDescription: {
		color: "#000",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "18px",
		"@media (max-width: 1130px )": {
			fontSize: "16px",
		},
		"@media (max-width: 900px )": {
			fontSize: "16px",
		},
		"@media (max-width: 600px )": {
			fontSize: "16px",
		},
		"@media (max-width: 480px )": {
			fontSize: "16px",
		}
	},
	titleTextCenter: {
		color: "#000",
		fontFamily: "Poppins",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "18px",
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		width: "70%",
		"@media (max-width: 1130px )": {
			fontSize: "16px",
		},
		"@media (max-width: 900px )": {
			fontSize: "16px",
		},
		"@media (max-width: 600px )": {
			fontSize: "16px",
		},
		"@media (max-width: 480px )": {
			fontSize: "16px",
		}
	},
	titleDescriptionTypography: {
		border: "1px solid #01983D",
		fontStyle: "normal",
		fontFamily: "Poppins",
		lineHeight: "18px",
		color: "#FFF",
		background: "#01983D",
		borderRadius: "8px",
		fontSize: "18px",
		display: "flex",
		fontWeight: 500,
		alignItems: "center" as const,
		//padding: "10px",
		width:'92px',
		height:'37px',
		justifyContent:'center',
		marginLeft: "10px",
		"@media (max-width: 1130px )": {
			fontSize: "16px",
		},
		"@media (max-width: 900px )": {
			fontSize: "16px",
		},
		"@media (max-width: 600px )": {
			fontSize: "16px",
		},
		"@media (max-width: 480px )": {
			fontSize: "16px",
		}
	},
	titleLightText: {
		color: "#000",
		fontFamily: "Poppins",
		fontSize: "16px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "16px",
		marginTop: "25px",
		"@media (max-width: 1130px )": {
			fontSize: "16px",
		},
		"@media (max-width: 900px )": {
			fontSize: "16px",
		},
		"@media (max-width: 600px )": {
			fontSize: "16px",
		},
		"@media (max-width: 480px )": {
			fontSize: "16px",
		}
	}
});
// Customizable Area End
export default withStyles(userStyles)(PaymentHistory);