import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { setStorageData } from "../../../framework/src/Utilities";

export const tabsList = [
  {
    key: 0,
    value: "terms_conditions",
    label: "Terms & Conditions"
  },
  {
    key: 1,
    value: "privacy_policy",
    label: "Privacy Policy"
  }
];
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  otpAuthToken: string;
  reTypePassword: string;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  fnameError: boolean;
  fnameErrorText: string;
  emailError: boolean;
  emailErrorText: string;
  pswdError: boolean;
  pswdErrorText: string;
  userType: string;
  isAccountType: boolean;
  confirmPassErr: boolean,
  confirmPassSucess: boolean;
  confirmPassSucessText: string;
  confirmPassTextErr: string,
  confirmPassword: string;
  isTermsConditions: boolean;
  openModal: boolean;
  tabValue: number;
  toastErrMsg: string;
  termsCondition: string;
  privacyPolicy: string;
  buyerAccount: boolean;
  vendorAccount: boolean;
  openSelectAccount: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  arrayholder: any[];
  passwordReg: RegExp;
  passwordRegex: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";

  imgPasswordVisible: any;
  imgPasswordInVisible: any;

  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;

  currentCountryCode: any;

  nameReg: RegExp;
  emailValidateReg: RegExp;
  pswdspaceReg: RegExp;
  passwordRegNew: RegExp;
  SignUpFormApiIntegrationCallId: string = "";
  termsConditionAPICallId: string = "";
  privacyPolicyAPICallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      reTypePassword: "",
      otpAuthToken: "",
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      phone: "",

      fnameError: false,
      fnameErrorText: "",
      emailError: false,
      emailErrorText: "",
      pswdError: false,
      pswdErrorText: "",
      userType: "Buyer",
      isAccountType: false,
      confirmPassErr: false,
      confirmPassTextErr: "",
      confirmPassword: "",
      confirmPassSucess: false,
      confirmPassSucessText: "",
      isTermsConditions: false,
      openModal: true,
      tabValue: 0,
      toastErrMsg: "",
      termsCondition: "",
      privacyPolicy: "",
      buyerAccount: true,
      vendorAccount: false,
      openSelectAccount: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.passwordRegex = new RegExp(/^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/);
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;

    this.nameReg = /^[a-zA-Z ]*$/;
    this.emailValidateReg = /^[\w.-]+@[a-z\d.-]+\.[a-z]{2,}$/i;
    this.pswdspaceReg = /^\S$|^\S[\s\S]*\S$/;
    this.passwordRegNew = /^(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,}$/;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.validationApiCallId:
            this.handleValidationApiResponse(responseJson);
            break;
          case this.createAccountApiCallId:
            this.handleCreateAccountApiResponse(responseJson, errorReponse);
            break;
          case this.SignUpFormApiIntegrationCallId:
            this.handleSignUpFormApiResponse(responseJson);
            break;
          case this.termsConditionAPICallId:
            this.handleTermsConditionApiResponse(responseJson);
            break;
          case this.privacyPolicyAPICallId:
            this.handlePrivacyPolicyApiResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number: "+" + this.state.countryCodeSelected + this.state.phone
    };

    const data = {
      type: "email_account",
      attributes: attrs
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }

  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnConfirmPasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField
      });
      this.txtInputConfirmPasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputConfirmPasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    }
  };

  btnSignUpProps = {
    onPress: () => this.createAccount()
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy()
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition()
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    }
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address"
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    }
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad"
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    }
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    }
  };

  txtInputConfirmPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputConfirmPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true
  };

  // Web Events and API integration

  // Navigations
  handleCloseLoginModal = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleSelectBuyerAccount = () => {
    this.setState({ buyerAccount: true, vendorAccount: false, userType: 'Buyer' })
  }

  handleSelectVendorAccount = () => {
    this.setState({ vendorAccount: true, buyerAccount: false, userType: 'vendor' })
  }

  handleOpenSelectAccount = () => {
    this.setState({ openSelectAccount: true, openModal: false })
  }

  goToSurvey = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigateToSurvey)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  goToLogin = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleCloseRegisterModal = () => {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  handleChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      this.termsConditionAPI();
    } else if (newValue === 1) {
      this.privacyPolicyAPI();
    }
    this.setState({ tabValue: newValue });
  };

  handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === "" || this.nameReg.test(event.target.value)) {
      this.setState({
        firstName: event.target.value,
        fnameError: false,
        fnameErrorText: ""
      });
    }
  };

  handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      email: event.target.value.trim(),
      emailError: false,
      emailErrorText: ""
    });
  };

  handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value.trim();
    if (value.length > 0) {
      if (!this.passwordRegex.test(value.replace(" ", "@"))) {
        this.setState({
          pswdError: true,
          pswdErrorText: configJSON.passformate,
        });
      }
      else {
        this.setState({ pswdError: false, pswdErrorText: "" });
      }
    }
    this.setState({ password: event.target.value.trim() });
  };

  handleChangeConfirmPass = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === this.state.password) {
      this.setState({
        confirmPassword: event.target.value,
        confirmPassSucess: true,
        confirmPassErr: false,
        confirmPassSucessText: configJSON.passmatch
      })
    } else {
      this.setState({
        confirmPassword: event.target.value,
        confirmPassErr: true,
        confirmPassSucess: false,
        confirmPassSucessText: configJSON.passnotmatch
      })
    }
  };

  validateFirstName = (firstName: string) => {
    if (firstName === null || firstName.length === 0) {
      this.setState({
        fnameError: true,
        fnameErrorText: configJSON.entername,
      });
      return false;
    }
    return true;
  };

  validateEmail = (email: string) => {
    if (email === null || email.length === 0) {
      this.setState({
        emailError: true,
        emailErrorText: configJSON.emailRequire,
      });
      return false;
    } else if (!this.emailValidateReg.test(email)) {
      this.setState({
        emailError: true,
        emailErrorText: configJSON.enteremail,
      });
      return false;
    }
    return true;
  };

  validatePassword = (password: string) => {
    if (password === null || password.length === 0) {
      this.setState({
        pswdError: true,
        pswdErrorText: configJSON.passRequire,
      });
      return false;
    }
    return true;
  };

  SignUpFormValidation = (e: any) => {
    e.preventDefault();
    const { firstName, email, password } = this.state;
    let firstNameValidation = this.validateFirstName(firstName);
    let emailValidation = this.validateEmail(email);
    let passwordValidation = this.validatePassword(password);

    const isValid =
      firstNameValidation &&
      emailValidation &&
      passwordValidation
    isValid && this.handleOpenSelectAccount()
  };

  // Web API Integration
  SignUpFormApiIntegration = async () => {
    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };
    const attrs = {
      email: this.state.email,
      password: this.state.password,
      password_confirmation: this.state.confirmPassword,
      full_name: this.state.firstName.trim(),
      user_type: this.state.userType
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };
    
    const httpBody = {
      data: data,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.SignUpFormApiIntegrationCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.signupAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  termsConditionAPI = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.termsConditionAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.termsCondAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  privacyPolicyAPI = () => {
    const header = {
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.privacyPolicyAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.privacyAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // API Response
  handleValidationApiResponse(responseJson: any) {
    if (this.arrayholder && this.arrayholder.length !== 0) {
      let regexData = this.arrayholder[0];

      if (regexData.password_validation_regexp) {
        this.passwordReg = new RegExp(regexData.password_validation_regexp);
      }

      if (regexData.password_validation_rules) {
        this.setState({
          passwordHelperText: regexData.password_validation_rules,
        });
      }
      if (regexData.email_validation_regexp) {
        this.emailReg = new RegExp(regexData.email_validation_regexp);
      }
    }
  }

  handleCreateAccountApiResponse(responseJson: any, errorResponse: any) {
    if (!responseJson.errors) {
      const msg: Message = new Message(
        getName(MessageEnum.AccoutResgistrationSuccess)
      );

      msg.addData(
        getName(MessageEnum.NavigationPropsMessage),
        this.props
      );

      this.send(msg);
    } else {
      this.parseApiErrorResponse(responseJson);
    }

    this.parseApiCatchErrorResponse(errorResponse);
  }

  handleSignUpFormApiResponse(responseJson: any) {
    const errors = responseJson?.errors;
    if (!errors) {
      const token = responseJson?.meta?.token;
      if (token) {
        setStorageData("registerToken", responseJson?.meta?.token);
        this.goToSurvey();
      }
    } else {
      let key = Object.keys(responseJson?.errors[0])[0];
      this.setState({ toastErrMsg: responseJson?.errors[0][key] });
    }
  }

  handleTermsConditionApiResponse(responseJson: any) {
    const termsApiResponse = responseJson?.data;
    if (termsApiResponse) {
      this.setState({
        isTermsConditions: true,
        openModal: false,
        termsCondition: termsApiResponse,
      });
    }
  }

  handlePrivacyPolicyApiResponse(responseJson: any) {
    const privacyApiResponse = responseJson?.data[0]?.description;
    if (privacyApiResponse) {
      this.setState({
        tabValue: 1,
        isTermsConditions: true,
        openModal: false,
        privacyPolicy: privacyApiResponse,
      });
    }
  }
  // Customizable Area End
}
