import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Button,
    FormControl,
    MenuItem,
    Select,
    OutlinedInput,
    Grid,
    Theme,
    Backdrop,
    CircularProgress,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
// Customizable Area End

// Customizable Area Start
import VideosPageController, {
    Props,
    configJSON,
} from "./VideosPageController";
import { addIcon, CloseICon, deleteIcon } from "./assets";
import CustomFormFeedback from "../../../components/src/CustomFormFeedback.web";
import CustomToast from "../../../components/src/CustomToast.web";

// Customizable Area End

export class VideosPage extends VideosPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            // Required for all blocks
            <Box className={classes.boxWrapperStyle}>
                <Backdrop className={classes.backdropStyle1} open={this.state.isLoadVideoData}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Typography className={classes.fontStyle_1}>{configJSON.videos}</Typography>

                <Box className={classes.textFeildBoxStyle}>
                    <Box className={classes.boxWidth}>
                        <Box className={classes.contentBoxStyle}>
                            <Typography className={classes.fontStyle1}>{configJSON.video}</Typography>
                            <Typography className={classes.fontStyle2}>{configJSON.videoUrl}</Typography>

                            <FormControl variant="outlined" className={classes.formcontrol}>
                                <Grid container spacing={4} style={{ alignItems: 'end' }}>
                                    {this.state.videos.map((item, index) => {
                                    
                                        
                                        return (
                                            <>

                                                <Grid item xs={12} sm={12} md={6} key={index}>
                                                    <Select
                                                        labelId="demo-simple-select-outlined-label"
                                                        id="demo-simple-select-outlined"
                                                        data-test-id={`selectCategory-${index + 1}`}
                                                        value={item.video_type}
                                                        onChange={(e) => this.handleSelectDynamic(e, index)}
                                                        fullWidth
                                                        className={classes.select}
                                                        displayEmpty
                                                    >
                                                        <MenuItem value="youtube" >
                                                            <Typography className={classes.placeholder}>{configJSON.youtube}</Typography>
                                                        </MenuItem>
                                                        <MenuItem value="vimeo">
                                                            <Typography className={classes.placeholder}>{configJSON.vimeo}</Typography>
                                                        </MenuItem>
                                                    </Select>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6}>
                                                    <Box style={{ display: "flex", justifyContent: "end" }}>
                                                        <Button disableRipple className={classes.categoryRemoveBtn} onClick={() => this.removeItem(index,item)} data-test-id={`removeItem-${index + 1}`}><img src={deleteIcon} alt="deleteicon" /></Button>
                                                    </Box>


                                                    <OutlinedInput
                                                        id="outlined-adornment-weight"
                                                        inputProps={{
                                                            'aria-label': 'address',
                                                            placeholder: configJSON.videoId,
                                                            className: classes.placeholder,
                                                        }}
                                                        classes={{
                                                            root: classes.outlinedInput,
                                                            focused: classes.focused,
                                                            notchedOutline: classes.notchedOutline,
                                                            input: classes.inputText
                                                        }}
                                                        className={classes.nameTextfeild}
                                                        data-test-id={`url-${index + 1}`}
                                                        value={item.url}
                                                        onChange={(e) => this.handleChangeUrlDynamic(e, index)}
                                                    />
                                                </Grid>
                                            </>)
                                    })}
                                </Grid>

                            </FormControl >

                        </Box>
                    </Box>
                </Box>

                <Box className={classes.boxWidth_3}>
                    <Button
                        variant="contained"
                        fullWidth
                        className={classes.addMoreBtn}
                        onClick={this.addVideo}
                        disableRipple
                        data-test-id={"add-video"}
                    >
                        <img src={addIcon} className={classes.addIconStyle} />
                        <Typography variant="button" className={classes.BtnTextStyle}>{configJSON.addVideo}</Typography>
                    </Button>
                </Box>

                <Box className={classes.saveBtnWrapper}>
                    <Box className={classes.buttonBox}>
                        <Typography className={classes.fontStyle5}>{configJSON.needHelp}</Typography>
                        <Typography className={classes.fontStyle4}>{configJSON.no}</Typography>
                        <Typography className={classes.fontStyle4} onClick={this.handleOpenFeedbackModal} data-test-id={'openFeedbackModal'}>{configJSON.yes}</Typography>
                    </Box>

                    <Box className={classes.buttonBoxStyle}>
                        <Button
                            variant="outlined"
                            className={classes.declineBtn}
                            onClick={this.goToVendorAccount}
                            data-testid={"navigatetoaccount"}
                        >
                            {configJSON.backBtn}
                        </Button>

                        <Button
                            variant="contained"
                            className={classes.acceptBtn}
                            data-test-id={"saveBtn"}
                            onClick={this.handleSubmit}
                        >
                            {configJSON.saveBtn}
                        </Button>
                    </Box>
                </Box>
                <CustomToast open={this.state.updatedSuccessDialog} onClose={this.handleCloseSuccessDialog} />

                <CustomFormFeedback data-test-id="closefeedback" isFeedback={this.state.isFeedback} handleCloseFeedbackModal={this.handleCloseFeedbackModal} handleSubmitFeedback={this.handleSubmitFeedbackVideo} handleFeedbackEmailChange={this.handleFeedbackEmailChange} handleFeedbackMessageChange={this.handleFeedbackMessageChange} closeIcon={CloseICon} isValidEmail={this.state.isValidEmail} />

            </Box>
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles: any = (theme: Theme) => ({
    boxWrapperStyle: {
        margin: '33px 46px 50px 45px',
        border: '2px solid #C8C9CA',
        borderRadius: '6px',
        padding: '40px 5% 40px 5%',

        [theme.breakpoints.down('md')]: {
            margin: "0",
        },

    },
    fontStyle_1: {
        color: '#2D2E2F',
        fontSize: '20px',
        fontWeight: 700,
        fontFamily: 'Poppins'
    },

    backdropStyle1: {
        zIndex: 1200,
        color: '#fff',
    },

    textFeildBoxStyle: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '3%',
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: 'unset',
            gap: "30px",
        },
    },

    boxWidth: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
    },
    contentBoxStyle: {
        display: 'flex',
        flexDirection: 'column' as const,
        justifyContent: 'center',
        gap: '7px'
    },

    textFeildBox: {
        display: 'flex',
        alignItems: 'center',
        gap: '40px'
    },

    fontStyle1: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    fontStyle2: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#737373',
        fontFamily: 'Poppins'
    },

    nameTextfeild: {
        width: "100%",
    },

    formcontrol: {
        width: "100%",
        borderRadius: '3px',
        '&.Mui-focused': {
            borderColor: '#DE76FF',
            outline: 'none'
        },
        '&.MuiOutlinedInput-notchedOutline': {
            borderColor: 'DDDDDD',
        },
    },

    select: {
        backgroundColor: '#FAF5FF',
    },

    boxWidth_3: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '7%'
    },

    BtnTextStyle: {
        textTransform: 'initial' as const,
        fontSize: "14px",
        fontWeight: 400,
        fontFamily: "Poppins",
        color: "#FFFFFF",
        marginLeft: "8px",
    },

    saveBtnWrapper: {
        marginTop: "59px",
        display: 'flex',
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down('md')]: {
            flexDirection: "column",
            alignItems: "unset",
            gap: "20px",

        },
    },
    buttonBox: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        width: '45%',
        [theme.breakpoints.down('md')]: {
            width: "100%",
        },
    },
    fontStyle4: {
        fontSize: '14px',
        fontWeight: 400,
        color: '#6200EA',
        fontFamily: 'Poppins',
        cursor: "pointer",
    },
    fontStyle5: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#262424',
        fontFamily: 'Poppins'
    },
    addMoreBtn: {
        all: "unset",
        cursor: "pointer",
        backgroundColor: "#B300ED",
        width: '10%',
        borderRadius: '10px',
        padding: "10px 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('md')]: {
            width: "10%",
        },
    },

    addIconStyle: {
        width: '20px',
        height: '20px',
        cursor: 'pointer'
    },
    buttonBoxStyle: {
        display: 'flex',
        gap: '20px',
        justifyContent: 'flex-end',
    },
    acceptBtn: {
        borderRadius: "8px",
        background: "#B300ED",
        height: "55px",
        width:"241px",
        padding: "16px 71px",
        justifyContent: "center",
        alignItems: "center",
        gap: "8px",
        color: "#FFF",
        fontFamily: "Poppins",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
        textTransform: "initial",
        textWrap:"nowrap",

        '&:hover': {
            backgroundColor: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    declineBtn: {
        padding: "2px 81px",
        color: "#B300ED",
        textAlign: "center",
        fontFamily: "Poppins",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "50.956px",
        textTransform: "initial",
        borderRadius: "6px",
        border: "1px solid #B300ED",
        background: "#FFF",
        '&:hover': {
            backgroundColor: "#FFFFFF",
            color: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "100%",
            padding: 'unset',
        },
    },

    outlinedInput: {
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        borderRadius: '8px',
        backgroundColor: '#FAF5FF',
    },
    focused: {},
    notchedOutline: {
        borderWidth: '1px',
    },
    placeholder: {
        fontWeight: 400,
        borderRadius: '8px',
        fontSize: "14px",
        fontFamily: "Poppins",
    },

    inputText: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        color: '#000000',
        fontFamily: "Poppins",
    },
    successMediaVideo: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: "20px",
        alignItems: "center",
    },
    detailSuccessTextVideo: {
        fontSize: "24px",
        textAlign: "center",
        fontWeight: 700,
        color: "#0F172A",
        lineHeight: "32px",
        margin: "20px 0 51px",
        letterSpacing: "-0.12px",
    },
    backToAccountButtonVideo: {
        display: "flex",
        height: "56px",
        width: "367px",
        gap: "8px",
        alignItems: "center",
        textTransform: "initial",
        color: "#FFF",
        borderRadius: "8px",
        fontFamily: "Poppins",
        fontSize: "18px",
        background: "#B300ED",
        lineHeight: "80.956px",
        '&:hover': {
            background: "#B300ED",
        },
        [theme.breakpoints.down('sm')]: {
            width: "unset",
        },
    },
    categoryRemoveBtn: {
        all: "unset",
        cursor: "pointer",
        marginBottom: "12px",
    },
});

export default withStyles(userStyles)(VideosPage)
// Customizable Area End