import React from "react";

// Customizable Area Start
import {
    Typography,
    OutlinedInput,
    Grid
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomSaveButton from '../../../components/src/CustomSaveButton.web';
import CustomBackButton from '../../../components/src/CustomBackButton.web';
// Customizable Area End

// Customizable Area Start
import ServicesContactPageController, {
    Props,
    configJSON,
} from "./ServicesContactPageController";
import CustomSucessDialogBox from "../../../components/src/CustomSucessDialogBox.web";
import { rightTickIcon } from "./assets";
import FeedbackForm from "./FeedbackForm.web";
// Customizable Area End

export class ServicesContactPage extends ServicesContactPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { classes }: any = this.props
        // Customizable Area End
        return (
            // Customizable Area Start
            <Grid container className={classes.mainContainer}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography className={classes.fontStyle_1} data-testid={'contacts'}>{configJSON.contacts}</Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.gridItem}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={'changeaddress'}
                                onChange={this.handleChangeAddress}
                                value={this.state.ServiceUpdatedContactData.address}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.address,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={"change_fburl"}
                                onChange={this.handleChangeFbUrl}
                                value={this.state.ServiceUpdatedContactData.fburl}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.facebookUrl,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={"change_instaurl"}
                                onChange={this.handleChangeInstaUrl}
                                value={this.state.ServiceUpdatedContactData.instaurl}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.instausername,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={"change_lindlnurl"}
                                onChange={this.handleChangeLinkdlnUrl}
                                value={this.state.ServiceUpdatedContactData.linkdlnurl}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.linkdlinLink,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} className={classes.gridItem}>
                            <OutlinedInput
                                id="outlined-adornment-weight"
                                type="text"
                                data-testid={"change-phno"}
                                onChange={this.handleChangePhoneNumber}
                                value={this.state.ServiceUpdatedContactData.phonenumber}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.phoneNumber,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={"change_twturl"}
                                onChange={this.handleChangeTwitterUrl}
                                value={this.state.ServiceUpdatedContactData.twitterurl}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.twitterUsername,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={"change_contacturl"}
                                onChange={this.handleChangeContactUs}
                                value={this.state.ServiceUpdatedContactData.contactus}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.contacturl,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />

                            <OutlinedInput
                                id="outlined-adornment-weight"
                                data-testid={"change_utuburl"}
                                onChange={this.handleChangeYouTubeUrl}
                                value={this.state.ServiceUpdatedContactData.youtubeurl}
                                inputProps={{
                                    'aria-label': 'address',
                                    placeholder: configJSON.youtubeUsername,
                                    className: classes.placeholder_1,
                                }}
                                classes={{
                                    root: classes.outlinedInput_2,
                                    focused: classes.focused,
                                    notchedOutline: classes.notchedOutline_1,
                                    input: classes.inputText_1
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Grid item container >
                        <Grid item xs={12} sm={12} md={7} lg={7} className={classes.feedText}>
                            <Typography className={classes.fontStyle2}>{configJSON.needHelp}</Typography>
                            <Typography className={classes.font_5}>{configJSON.no}</Typography>
                            <Typography className={classes.font_5} data-testid={"openFeedback"} onClick={this.handleOpenFeedbackFrom}>{configJSON.yes}</Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={5} lg={5} className={classes.feedText}>
                            <CustomBackButton label={configJSON.backBtn} data-testid="backBtn"  handleClick={this.goBack}/>
                            <CustomSaveButton label={configJSON.saveBtn} handleClick={this.updateServiceContactListData} data-testid={"save_button"} />
                        </Grid>
                    </Grid>
                </Grid>

                <CustomSucessDialogBox
                    icon={rightTickIcon}
                    opemModal={this.state.isSucessModalOpen}
                    goToVendorAccount={this.goVendorAccount}
                    onClose={this.handleCloseSuccess}
                    buttonLabel={configJSON.backtoaccount}
                    data-testid={"gotoaccount"}
                />

                <FeedbackForm
                    data-testid={"feedback_modal"}
                    openFeedback={this.state.isFeedbackFormOpen}
                    navigation={this.props.navigation} id={""}
                    closeFeedback={this.handleCloseFeedbackForm}
                />
            </Grid >
        );
        // Customizable Area End
    }
}
// Customizable Area Start

export const userStyles = () => ({
    mainContainer: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '40px',
        marginTop: '2%',
        marginBottom: '2%'
    },

    gridItem: {
        display: 'flex',
        flexDirection: 'column' as const,
        gap: '25px'
    },

    font_5: {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Poppins',
        color: '#6200EA',
        cursor:"pointer"
    },

    feedText: {
        display: 'flex',
        gap: '10px',
        alignItems: 'center',
        marginTop: '15%',
        marginBottom: '15%'
    },

    fontStyle2: {
        fontSize: '14px',
        fontWeight: 500,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    fontStyle_1: {
        fontSize: '20px',
        fontWeight: 700,
        color: '#2D2E2F',
        fontFamily: 'Poppins'
    },

    outlinedInput_2: {
        '&$focused $notchedOutline': {
            borderColor: '#DE76FF',
        },
        '&:hover $notchedOutline': {
            borderColor: '#DE76FF',
        },
        backgroundColor: '#FAF5FF',
        borderRadius: '8px',
    },
    focused: {},
    notchedOutline_1: {
        borderWidth: '1px',
    },
    placeholder_1: {
        fontWeight: 400,
        fontSize: "14px",
        borderRadius: '8px',
        fontFamily: "Poppins",
    },

    inputText_1: {
        fontWeight: 400,
        fontSize: "16px",
        borderRadius: '8px',
        fontFamily: "Poppins",
        color: '#000000'
    },
});

export default withStyles(userStyles)(ServicesContactPage)
// Customizable Area End